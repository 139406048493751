import React, { useEffect } from 'react';

import { getAssetClaimByAssetClaimId } from '@actions/assetsClaimEvent';
import { changeStateByNestedKey } from '@actions/fugaActions';
import { getStatusHistoryByCmoRegIdAPI } from '@api/cmoController';
import InfoIconSmallSvg from '@images/svgComponents/InfoIconSmallSvg';
import WarningBlackSvg from '@images/svgComponents/WarningBlackSvg';
import { ArrowForward, ErrorOutline } from '@mui/icons-material';
import { Button, Paper, Popover, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import moment from 'moment';
import { useDispatch } from 'react-redux';

const useClasses = makeStyles({
    container: {
        maxHeight: '488px',
        paddingBottom: '8px',
    },
    statusRow: {
        width: '444px',
        padding: '16px 0',
    },
    rowItem: {
        padding: '4px 16px',
        display: 'flex',
        alignItems: 'center',
    },
    displayFlex: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
    },
    logDate: {
        marginLeft: '8px',
        color: 'rgba(0, 0, 0, 0.87)',
    },
    arrowFwd: {
        width: '20px',
        height: '20px',
        margin: '0 8px',
        color: 'rgba(0, 0, 0, 0.60)',
    },
    stateLabel: {
        '&:hover': {
            cursor: 'unset',
            background: 'unset',
        },
        padding: 0,
        minWidth: 'unset',
        color: 'rgba(0, 0, 0, 0.60)',
    },
    title: {
        padding: '8px 16px',
        backgroundColor: '#FFFFFF',
        position: 'sticky',
        top: 0,
        width: '444px',
        zIndex: '100',
    },
    rowsContainer: {
        paddingBottom: '8px',
    },
    metaDataIcons: {
        marginRight: '8px',
        width: '18px',
        height: '18px',

        '& svg': {
            width: '18px',
            height: '18px',
            fill: 'rgba(0, 0, 0, 0.60)',
        },
    },
    iconColor: {
        '& svg': {
            fill: 'rgba(0, 0, 0, 0.60)',
        },
    },
});

export default function StatusHistoryModal({
    anchorEl,
    onClose,
    isOpen,
    selectedRowDataItem,
    storeName,
    contentData = [],
    isAsset = false,
}) {
    const classes = useClasses();
    const dispatch = useDispatch();

    const handleOpen = async () => {
        if (isOpen) {
            if (isAsset) {
                dispatch(getAssetClaimByAssetClaimId(selectedRowDataItem.assetClaimId, storeName));
            } else {
                const resp = await getStatusHistoryByCmoRegIdAPI(selectedRowDataItem.cmoRegId);
                dispatch(
                    changeStateByNestedKey(storeName, 'registrationHistory', resp?.data || []),
                );
            }
        }
    };

    useEffect(() => {
        handleOpen();
    }, [isOpen]);

    const getBeforeValue = (data) => {
        if (data.name) {
            return data.oldValue;
        } else if (data.category) {
            return data.status.split('_').join(' ');
        } else {
            return data.previousStatus.split('_').join(' ');
        }
    };

    const getAfterValue = (data) => {
        if (data.name) return data.newValue;
        else if (data.category) return data.status.split('_').join(' ');
        else return data.currentStatus.split('_').join(' ');
    };

    const dealTypesMapping = [
        {
            beKey: 'END_DATE_UPDATED',
            textToShow: 'End date',
        },
        {
            beKey: 'CLIENT_DEAL_END_DATE_UPDATED',
            textToShow: 'Deal end date',
        },
        {
            beKey: 'CLIENT_DEAL_TERRITORY_UPDATED',
            textToShow: 'a country of residence',
        },
    ];

    const getStatusActionMsg = (data) => {
        if (data.category) return '';
        const beActionName = data.name;
        return dealTypesMapping.find((el) => el.beKey === beActionName)?.textToShow || 'the status';
    };

    const categoriesList = [
        {
            title: 'Internal action alert',
            beValue: 'INTERNAL',
            icon: (
                <ErrorOutline
                    style={{ width: '18px', height: '18px', fill: 'rgba(0, 0, 0, 0.60)' }}
                />
            ),
        },
        {
            title: 'Client action alert',
            beValue: 'CLIENT',
            icon: <WarningBlackSvg styles={classes.iconColor} />,
        },
        {
            title: 'CMO action alert',
            beValue: 'CMO',
            icon: <InfoIconSmallSvg styles={classes.iconColor} />,
        },
        {
            title: 'Dispute action alert',
            beValue: 'DISPUTE',
            icon: <ErrorOutline styles={classes.iconColor} />,
            color: '#F44139',
        },
        {
            title: 'Evidence action alert',
            beValue: 'EVIDENCE_REQUIRED',
            icon: <WarningBlackSvg styles={classes.iconColor} />,
            color: '#FF8800',
        },
        {
            title: 'Action alert',
            beValue: 'ACTION_REQUIRED',
            icon: <InfoIconSmallSvg styles={classes.iconColor} />,
            color: '#45A2DD',
        },
        {
            title: 'CMO Validation',
            beValue: 'CMO_VALIDATION',
            icon: <WarningBlackSvg styles={classes.iconColor} />,
            color: '#FF8800',
        },
    ];

    return (
        <div>
            <Popover
                open={isOpen}
                anchorEl={anchorEl}
                onClose={onClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                className={classes.container}
            >
                <Typography variant="h6" className={classes.title}>
                    Status history
                </Typography>
                <Paper className={classes.rowsContainer}>
                    {contentData?.map((data) => (
                        <div key={`${data.date}`} className={classes.statusRow}>
                            <div className={classes.rowItem}>
                                <Typography variant="body2">
                                    <b>{data.user}</b>
                                    {' changed '}
                                    <b>{getStatusActionMsg(data)}</b>
                                </Typography>
                                <Typography variant="caption" className={classes.logDate}>
                                    {`${moment(data.date).format('D/M/YYYY HH:mm')} UTC`}
                                </Typography>
                            </div>
                            <div
                                className={`${classes.rowItem} ${
                                    data.category ? classes.displayFlex : ''
                                }`}
                            >
                                <div style={{ display: 'flex' }}>
                                    <Button
                                        variant="text"
                                        color="primary"
                                        disableElevation
                                        disableRipple
                                        className={classes.stateLabel}
                                    >
                                        {getBeforeValue(data)}
                                    </Button>
                                    <ArrowForward className={classes.arrowFwd} />
                                    {/* Commented part below needs confirmation of visual in NR-1157 */}
                                    {/* <Button
                                        variant="text"
                                        color="primary"
                                        disableElevation
                                        disableRipple
                                        className={classes.stateLabel}
                                    >
                                        {getAfterValue(data)}
                                    </Button> */}
                                    {data.category ? (
                                        <div>
                                            <div style={{ display: 'flex' }}>
                                                <div className={classes.metaDataIcons}>
                                                    {categoriesList.find(
                                                        (el) => el.beValue === data.category,
                                                    )?.icon || <></>}
                                                </div>
                                                <Typography
                                                    variant="subtitle2"
                                                    style={{ color: 'rgba(0, 0, 0, 0.60)' }}
                                                >
                                                    {`${
                                                        categoriesList.find(
                                                            (el) => el.beValue === data.category,
                                                        )?.title || ''
                                                    } ${
                                                        data.state === 'RAISED'
                                                            ? 'added'
                                                            : 'resolved'
                                                    }`}
                                                </Typography>
                                            </div>
                                        </div>
                                    ) : (
                                        <Button
                                            variant="text"
                                            color="primary"
                                            disableElevation
                                            disableRipple
                                            className={classes.stateLabel}
                                        >
                                            {getAfterValue(data)}
                                        </Button>
                                    )}
                                </div>
                                {data.category ? (
                                    <Typography
                                        style={{ marginTop: '9px', color: 'rgba(0, 0, 0, 0.60)' }}
                                        variant="subtitle2"
                                    >
                                        {data.message}
                                    </Typography>
                                ) : (
                                    <></>
                                )}
                            </div>
                            {/* Comments section to be added later (probably as part of 1148)*/}
                            {/* <div className={classes.rowItem}></div> */}
                        </div>
                    ))}
                </Paper>
            </Popover>
        </div>
    );
}
