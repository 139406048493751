import React from 'react';

export default function PendingIconSvg(props) {
    return (
        <div className={props.styles}>
            <svg
                width="18"
                height="18"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M10 2.51172C5.85156 2.51172 2.51172 5.85156 2.51172 10C2.51172 14.1484 5.85156 17.4883 10 17.4883C14.1484 17.4883 17.4883 14.1484 17.4883 10C17.4883 5.85156 14.1484 2.51172 10 2.51172ZM10 16.0117C6.69531 16.0117 3.98828 13.3047 3.98828 10C3.98828 6.69531 6.69531 3.98828 10 3.98828C13.3047 3.98828 16.0117 6.69531 16.0117 10C16.0117 13.3047 13.3047 16.0117 10 16.0117ZM5.11328 10C5.11328 9.36719 5.64062 8.875 6.23828 8.875C6.87109 8.875 7.36328 9.36719 7.36328 10C7.36328 10.6328 6.87109 11.125 6.23828 11.125C5.64062 11.125 5.11328 10.6328 5.11328 10ZM8.875 10C8.875 9.36719 9.36719 8.875 10 8.875C10.6328 8.875 11.125 9.36719 11.125 10C11.125 10.6328 10.6328 11.125 10 11.125C9.36719 11.125 8.875 10.6328 8.875 10ZM12.6367 10C12.6367 9.36719 13.1289 8.875 13.7617 8.875C14.3594 8.875 14.8867 9.36719 14.8867 10C14.8867 10.6328 14.3594 11.125 13.7617 11.125C13.1289 11.125 12.6367 10.6328 12.6367 10Z"
                    fill="#FF8800"
                />
            </svg>
        </div>
    );
}
