import React from 'react';

export default function DownloadIconSvg(props) {
    return (
        <div className={props.styles}>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                height="20"
                viewBox="0 0 24 24"
                width="20"
                fill="#000000"
            >
                <path d="M0 0h24v24H0V0z" fill="none" />
                <path d="M19 9h-4V3H9v6H5l7 7 7-7zm-8 2V5h2v6h1.17L12 13.17 9.83 11H11zm-6 7h14v2H5z" />
            </svg>
        </div>
    );
}
