import React, { useEffect, useRef, useState } from 'react';

import { Autocomplete, Chip, MenuItem, TextField } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Typography } from 'antd';
import Autosuggest from 'react-autosuggest';

const useStyles = makeStyles(() => ({
    suggestionContainer: {
        '& ul': {
            padding: 0,
            margin: 0,
        },
        zIndex: 9999,
        overflowY: 'scroll',
        borderRadius: '4px',
        background: '#FFFFFF',
        width: '395px',
        maxHeight: '250px',
        boxShadow: '0px 6px 6px rgba(0, 0, 0, 0.26), 0px 10px 20px rgba(0, 0, 0, 0.19)',
    },
    suggestion: {
        listStyleType: 'none',
        height: '48px',
        '&:hover, &:focus': {
            cursor: 'pointer',
        },
    },
    chipInInput: {
        '& .MuiChip-root': {
            margin: '0 8px 4px 0',
        },
        '& .MuiChip-label': {
            overflow: 'unset',
        },
    },
}));

export default function SuggestionInput({
    suggestionsWithoutSelectedFilter = { itemsList: [], isFromBE: false },
    idFieldName,
    contentFieldName,
    selectedValues = [],
    setSelectedValues,
    title,
}) {
    const styles = useStyles();
    const [value, setValue] = useState('');
    const [initialSuggestionsList, setInitialSuggestionsList] = useState([]);
    const [suggestionsList, setSuggestionsList] = useState([]);
    const suggestInputRef = useRef(null);

    useEffect(() => {
        let formattedList = [];
        if (!suggestionsWithoutSelectedFilter.isFromBE) {
            formattedList = suggestionsWithoutSelectedFilter.list.map((item) => ({
                id: item[idFieldName],
                name: item[contentFieldName],
            }));
        } else {
            formattedList = suggestionsWithoutSelectedFilter.list;
        }

        setSuggestionsList(formattedList);
        setInitialSuggestionsList(formattedList);
    }, [suggestionsWithoutSelectedFilter.list]);

    const onInputChange = (e) => {
        setValue(e.target.value);
    };

    const onSuggestionSelected = (event, { suggestion = null }) => {
        const newSelectedValue = [...selectedValues, suggestion];
        setSelectedValues(newSelectedValue);
        setValue('');
    };

    const onSuggestionsFetchRequested = ({ value }) => {
        const filteredSuggestions = initialSuggestionsList.filter((suggestion) =>
            suggestion.name.toLowerCase().includes(value.toLowerCase()),
        );

        const filterOutSelected = filteredSuggestions.filter((item) =>
            selectedValues.every((el) => el.name !== item.name),
        );

        setSuggestionsList(filterOutSelected);
    };

    const removeFilter = (targetToRemove) => {
        setSelectedValues((prevSelected) =>
            prevSelected.filter((item) => item.id !== targetToRemove.id),
        );
    };

    const renderSuggestion = (suggestion) => (
        <MenuItem style={{ height: '100%' }} className={styles.suggestion}>
            <Typography variant="body1">{suggestion.name}</Typography>
        </MenuItem>
    );

    return (
        <div style={{ marginBottom: '32px', maxWidth: '552px' }} ref={suggestInputRef}>
            <Autosuggest
                suggestions={suggestionsList}
                inputProps={{
                    value,
                    onChange: onInputChange,
                    placeholder: title,
                }}
                getSuggestionValue={(suggestion) => suggestion.name}
                renderSuggestion={renderSuggestion}
                onSuggestionSelected={onSuggestionSelected}
                onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                theme={{
                    suggestionsContainerOpen: styles.suggestionContainer,
                }}
                renderInputComponent={(inputProps) => (
                    <Autocomplete
                        multiple
                        freeSolo
                        options={suggestionsList}
                        value={selectedValues}
                        getOptionLabel={(option) =>
                            typeof option === 'string' ? option : option.name || ''
                        }
                        onChange={(event, newValue) => setSelectedValues(newValue)}
                        renderTags={(selected, getTagProps) =>
                            selected.map((option, index) => (
                                <Chip
                                    key={option.id}
                                    label={option.name}
                                    className={styles.chipInInput}
                                    onDelete={() => removeFilter(option)}
                                    {...getTagProps({ index })}
                                />
                            ))
                        }
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                value={inputProps.value}
                                onChange={inputProps.onChange}
                                placeholder={inputProps.placeholder}
                                label={title}
                                variant="standard"
                            />
                        )}
                    />
                )}
            />
        </div>
    );
}
