import React, { useEffect, useState } from 'react';

import { changeStateByKey, changeStateByNestedKey } from '@actions/fugaActions';
import FilterChip from '@common/Filters/FilterChip';
import FilterMenuDialogWithInput from '@common/Filters/FilterMenuDialogWithInput';
import { Tabs, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { FilterMenuDialogWithDatepicker } from '@pages/AssetsCmoPage/FilterMenuDialogWithDatepicker';
import { useDispatch, useSelector } from 'react-redux';

import { AssetsCmoFilterMenuDialogWithCheckboxes } from './AssetsCmoFilterMenuDialogWithCheckboxes';
import { FilterMenuTypes } from './common/enum';
import { FilterMenuDialogWithAutocompleteChips } from './FilterMenuDialogWithAutocompleteChips';

const filterMenuComponents = {
    [FilterMenuTypes.INPUT]: FilterMenuDialogWithInput,
    [FilterMenuTypes.AUTOCOMPLETE]: FilterMenuDialogWithAutocompleteChips,
    [FilterMenuTypes.DEFAULT]: AssetsCmoFilterMenuDialogWithCheckboxes,
    [FilterMenuTypes.DATEPICKER]: FilterMenuDialogWithDatepicker,
};

const useStyles = makeStyles(() => ({
    titile: {
        padding: '19px 16px',
    },
    content: {
        padding: '0 8px',
        width: '91%',
        height: 'auto',
    },
    flexHor: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
    },
    labels: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
    },
    clearAll: {
        '&:hover': {
            cursor: 'pointer',
        },
    },
    rootTabs: {
        '&.MuiTabs-root': {
            position: 'relative',
            justifyContent: 'space-between',
            alignItems: 'center',
        },
        '& .MuiTabScrollButton-root': {
            opacity: 'unset',
        },
        '& .MuiTabs-scrollButtons': {
            position: 'absolute',
            width: '32px',
            height: '32px',
            background: '#ffffff',
            borderRadius: '50%',
            boxShadow: '0px 3px 5px -1px',
        },
        '& .MuiTabs-scrollButtons:first-child': {
            left: '8px',
            zIndex: '1',
        },
        '& .MuiTabs-scrollButtons:nth-child(4)': {
            right: '8px',
        },
    },
}));

export function AssetsCmoTableFilters({ tableReloadCb, menuItems, clearAllFilters }) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [value, setValue] = useState(0);
    const [isFilterMenuOpen, setIsFilterMenuOpen] = useState(false);
    const [anchor, setAnchor] = useState(null);
    const [dataItemForMenu, setDataItemForMenu] = useState({});
    const filtersModel = useSelector((state) => state.fugaReducers.filters);

    useEffect(() => {
        dispatch(changeStateByKey('filters', {}));
    }, []);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleFilterClick = (event, dataItem) => {
        setAnchor(event.currentTarget);
        setDataItemForMenu(dataItem);
        setIsFilterMenuOpen(true);
    };

    const handleFilterCancel = (dataItem) => {
        setDataItemForMenu({});
        delete filtersModel[dataItem.filterNameInRequest];
        dispatch(changeStateByKey('filters', filtersModel));
        tableReloadCb();
        setIsFilterMenuOpen(false);
    };

    const onFilterConfirm = (value) => {
        let filterValue;
        if (value.valuesToShow) {
            filterValue = value;
        } else {
            filterValue = {
                valuesToShow: value,
                valuesToSend: value,
            };
        }
        dispatch(
            changeStateByNestedKey('filters', dataItemForMenu.filterNameInRequest, filterValue),
        );
        dispatch(changeStateByNestedKey('queryParams', 'page', 0));
        tableReloadCb();
    };

    const handleClearAll = () => {
        dispatch(changeStateByKey('filters', {}));
        clearAllFilters();
    };

    const FilterMenuComponent =
        filterMenuComponents[dataItemForMenu.type] || filterMenuComponents.default;

    // NOTE: the data format should be followed in order to set it in redux store correctly
    return (
        <div className={`${classes.content} ${classes.flexHor}`}>
            <div className={classes.flexHor}>
                <Tabs
                    className={classes.rootTabs}
                    value={value}
                    onChange={handleChange}
                    variant="scrollable"
                    TabIndicatorProps={{
                        style: {
                            display: 'none',
                        },
                    }}
                >
                    {menuItems.map((filterItem) => {
                        return (
                            <FilterChip
                                key={`filter-item-${filterItem.filterField}`}
                                onClick={(e) => handleFilterClick(e, filterItem)}
                                dataItem={filterItem}
                                handleFilterCancelCb={() => handleFilterCancel(filterItem)}
                            />
                        );
                    })}
                </Tabs>
                <Typography
                    color="primary"
                    variant="body2"
                    className={`${classes.labels} ${classes.clearAll}`}
                    onClick={handleClearAll}
                >
                    Clear all
                </Typography>
            </div>
            <FilterMenuComponent
                isOpen={isFilterMenuOpen}
                closeCb={setIsFilterMenuOpen}
                anchorEl={anchor}
                dataItem={dataItemForMenu}
                onConfirm={onFilterConfirm}
                itemsList={dataItemForMenu.chackboxItemsList}
                itemFieldName="name"
                filtersModel={filtersModel}
            />
        </div>
    );
}
