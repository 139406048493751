import React, { useState } from 'react';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
    Checkbox,
    FormControlLabel,
    IconButton,
    List,
    ListItem,
    ListItemText,
    Typography,
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import { makeStyles } from '@mui/styles';
import { useFormContext } from 'react-hook-form';

const useStyles = makeStyles((theme) => ({
    collapsed: {
        transform: 'rotate(180deg)',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expanded: {
        transform: 'rotate(0deg)',
    },
    listItem: {
        borderBottom: '1px solid #DEDEE0',
    },
    checkboxes: {
        padding: '0 8px 0 0',
    },
}));

const RegionItem = ({ territorySelection, region }) => {
    const classes = useStyles();
    const { getValues, setValue, watch } = useFormContext();

    const [collapsed, setCollapsed] = useState(true);
    const selectedTerritories = watch('territories') || [];

    const handleUpdateTerritories = (territoryCode) => {
        const currentTerritories = getValues('territories') || [];
        if (currentTerritories.includes(territoryCode)) {
            // Remove territory
            const updatedTerritories = currentTerritories.filter((code) => code !== territoryCode);
            setValue('territories', updatedTerritories);
        } else {
            // Add territory
            setValue('territories', [...currentTerritories, territoryCode]);
        }
    };

    const selectAllTerritories = (event) => {
        const territoryIds = territorySelection.map((territory) => territory.id);
        const currentTerritories = getValues('territories') || [];
        if (event.target.checked) {
            // Add all territories in this region
            const updatedTerritories = [...new Set([...currentTerritories, ...territoryIds])];
            setValue('territories', updatedTerritories);
        } else {
            // Remove all territories in this region
            const updatedTerritories = currentTerritories.filter(
                (code) => !territoryIds.includes(code),
            );
            setValue('territories', updatedTerritories);
        }
    };

    const allTerritoriesSelected = territorySelection.every((territory) =>
        selectedTerritories.includes(territory.id),
    );

    const someTerritoriesSelected =
        territorySelection.some((territory) => selectedTerritories.includes(territory.id)) &&
        !allTerritoriesSelected;

    const countSelected = territorySelection.filter((territory) =>
        selectedTerritories.includes(territory.id),
    ).length;

    return (
        <List key={region.id} component="div" disablePadding>
            <ListItem disableGutters className={classes.listItem}>
                <Checkbox
                    className={classes.checkboxes}
                    color="primary"
                    checked={allTerritoriesSelected}
                    indeterminate={someTerritoriesSelected}
                    onChange={selectAllTerritories}
                />
                <ListItemText
                    primary={
                        <Typography variant="subtitle2">
                            {`${region.name} (${territorySelection.length} items, ${countSelected} selected)`}
                        </Typography>
                    }
                />

                <IconButton
                    onClick={() => setCollapsed(!collapsed)}
                    className={collapsed ? classes.expanded : classes.collapsed}
                    aria-expanded={!collapsed}
                    size="large"
                >
                    <ExpandMoreIcon />
                </IconButton>
            </ListItem>
            <Grid container>
                {!collapsed &&
                    territorySelection.map((territory) => {
                        const isChecked = selectedTerritories.includes(territory.id);
                        return (
                            <Grid
                                key={territory.id}
                                size={{
                                    xs: 4,
                                    lg: 3,
                                }}
                            >
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={isChecked}
                                            onChange={() => handleUpdateTerritories(territory.id)}
                                            name={`territory-${territory.id}`}
                                            color="primary"
                                        />
                                    }
                                    label={territory.name}
                                />
                            </Grid>
                        );
                    })}
            </Grid>
        </List>
    );
};

export { RegionItem };
