/* eslint-disable no-console */
import { deleteClientDealPerfOrRHAPI } from '@api/clientDealController';
import { getAllCmosAPI, getCmoByIdAPI } from '@api/cmoController';
import userData from '@data/userData';
import fugaState from '@state/fugaState';
import Fetch from '@utils/Fetch';

import { getPerformerById } from './performers';
import { getRhClientInfoById } from './rightHolders';

export function changeStateByNestedKey(key1, key2, val) {
    return {
        type: 'changeStateByNestedKey',
        key1,
        key2,
        val,
    };
}

export function updateStateByNestedKey(key1, key2, val) {
    return {
        type: 'updateStateByNestedKey',
        key1,
        key2,
        val,
    };
}

export function forceUpdateByNestedKey(key1, key2) {
    return {
        type: 'forceUpdateByNestedKey',
        key1,
        key2,
    };
}

export function appendToStateByNestedKey(key1, key2, val) {
    return {
        type: 'appendToStateByNestedKey',
        key1,
        key2,
        val,
    };
}

export function changeStateByKey(key, val) {
    return {
        type: 'changeStateByKey',
        key,
        val,
    };
}

export function setError(msg) {
    return {
        type: 'setError',
        msg,
    };
}

export function deleteByIndex(key1, key2, index) {
    return {
        type: 'deleteByIndex',
        key1,
        key2,
        index,
    };
}

export function loadCmoInfo(clientId, isPerformer = false) {
    return async function (dispatch) {
        try {
            const result = await getCmoByIdAPI(clientId);
            const key = isPerformer ? 'performerCmoPage' : 'cmoPage';
            dispatch(changeStateByNestedKey(key, 'cmoInfo', result.data));
        } catch (error) {
            console.error(error);
        }
    };
}

export function loadAllCmos(queryParams = { isPerformer: false, isRH: false }) {
    return async function (dispatch) {
        dispatch(changeStateByNestedKey('cmos', 'tableIsLoading', true));

        const resp = await getAllCmosAPI(queryParams);

        const sortedData = resp.data.sort((el1, el2) => el1.cmoName.localeCompare(el2.cmoName));

        dispatch(changeStateByNestedKey('cmos', 'dataFromBE', sortedData));
        dispatch(changeStateByNestedKey('cmos', 'tableIsLoading', false));
    };
}

export function deleteClientDealPerfOrRH(dealOptions, isPerformer = true) {
    return async function (dispatch) {
        await deleteClientDealPerfOrRHAPI(dealOptions, isPerformer);

        if (isPerformer) {
            dispatch(getPerformerById(dealOptions.clientId));
        } else {
            dispatch(getRhClientInfoById(dealOptions.clientId));
        }
    };
}

export function getCmoValidationMsgByAssetId(cmoId, assetId, storeName = 'performerCmoPage') {
    return function (dispatch) {
        const url = `${userData.api.baseAssetUrl()}/state/${cmoId}?assetId=${assetId}`;
        Fetch.fetchWrapper(url)
            .then((res) => {
                if (res.status === 200 || res.status === 201) {
                    res.json().then((result) => {
                        dispatch(
                            changeStateByNestedKey(
                                storeName,
                                'cmoValidationDialogContent',
                                result.data,
                            ),
                        );
                    });
                } else {
                    res.json().then((result) => {
                        dispatch(
                            changeStateByKey('infoMessage', {
                                isVisible: true,
                                message: result.error || result.data.error,
                                severity: 'error',
                            }),
                        );
                    });
                }
            })
            .catch((error) => {
                console.log('Failed to send request via url: ' + url, `error msg: ${error}`);
                dispatch(
                    changeStateByKey('infoMessage', {
                        isVisible: true,
                        message: 'An error has occured',
                        severity: 'error',
                    }),
                );
            });
    };
}

export function getAllUsers(queryParams = {}) {
    return async function (dispatch) {
        dispatch(changeStateByNestedKey('users', 'tableIsLoading', true));

        const url = new URL(`${userData.api.baseUsersUrl()}/all`);

        url.search = new URLSearchParams({
            ...(queryParams.page || queryParams.page === 0 ? { page: queryParams.page } : {}),
            ...(queryParams.size ? { size: queryParams.size } : {}),
            ...(queryParams.sort ? { sort: queryParams.sort } : {}),
        });

        const resp = await Fetch.fetchWithErrorToast({ url, dispatch });

        dispatch(
            changeStateByKey('users', {
                ...fugaState.users,
                dataFromBE: resp.data,
                tableIsLoading: false,
            }),
        );
    };
}
