import React, { useCallback, useMemo } from 'react';

import { changeStateByNestedKey } from '@actions/fugaActions';
import { getPerformersListAPI } from '@api/performerController';
import { useDispatch, useSelector } from 'react-redux';

import Lookup from './Lookup';

const PerformerLookup = () => {
    const dispatch = useDispatch();

    const open = useSelector((state) => state.fugaReducers.performers.isNewClientModalOpen);
    const allPerformers = useSelector((state) => state.fugaReducers.performers.allPerformers);

    const suggestionSelector = (state) => state.fugaReducers.performers.suggestedClients;
    const dataSelector = (state) => state.fugaReducers.performers;

    const onClose = () =>
        dispatch(changeStateByNestedKey('performers', 'isNewClientModalOpen', false));

    const searchAction = useCallback(
        async (searchTerm) => {
            const foundClients = allPerformers
                .filter(
                    (el) =>
                        el.fullname.includes(searchTerm || null) ||
                        el.fullname.toLocaleLowerCase().includes(searchTerm || null),
                )
                .sort((a, b) => a.fullname.localeCompare(b.fullname));
            dispatch(changeStateByNestedKey('performers', 'suggestedClients', foundClients));
        },
        [allPerformers],
    );

    const memoizedSearchAction = useMemo(() => searchAction, [searchAction]);

    const loadData = useCallback(async () => {
        const resp = await getPerformersListAPI();

        dispatch(changeStateByNestedKey('performers', 'allPerformers', resp.data));
    }, []);

    const navigateTo = '/performer-page/-1';
    const newItemData = {
        firstName: 'New',
        lastName: 'Client',
        clientDeals: [{ client_deal_id: '' }],
    };

    const setIsInSaveNewModeAction = (value) =>
        changeStateByNestedKey('performers', 'isInSaveNewMode', value);
    const setSelectedIndexAction = (index) =>
        changeStateByNestedKey('performers', 'selectedIndex', index);
    const setDataAction = (data) =>
        changeStateByNestedKey('performers', 'byClietnId', {
            ...data,
            firstName: data.firstname || '',
            lastName: data.lastname || '',
            clientDeals: [{ client_deal_id: '' }],
        });

    return (
        <Lookup
            open={open}
            onClose={onClose}
            searchAction={memoizedSearchAction}
            staticSearch={loadData}
            suggestionSelector={suggestionSelector}
            dataSelector={dataSelector}
            navigateTo={navigateTo}
            newItemData={newItemData}
            itemName="fullname"
            setIsInSaveNewModeAction={setIsInSaveNewModeAction}
            setSelectedIndexAction={setSelectedIndexAction}
            setDataAction={setDataAction}
            anchorPosition={{ top: 255, left: window.innerWidth - 200 }}
        />
    );
};

export default PerformerLookup;
