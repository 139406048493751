const findDataIndex = (attribute, dataArray, item, fullRow) => {
    if (fullRow) {
        if (Array.isArray(attribute)) {
            //multi attributes check
            return dataArray.findIndex((dataItem) => {
                return attribute.every(
                    (attributeName) => dataItem[attributeName] === item[attributeName],
                );
            });
        } else {
            return dataArray.findIndex((dataItem) => dataItem[attribute] === item[attribute]);
        }
    } else {
        return dataArray.indexOf(item[attribute]);
    }
};

export { findDataIndex };
