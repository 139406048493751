import React from 'react';

import { makeStyles } from '@mui/styles';

import BulkActionsDropDownButton from './BulkActionsDropDownButton';
import BulkActionsDropDownExportButton from './BulkActionsDropDownExportButton';

const useStyles = makeStyles((theme) => ({
    buttonContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        width: 'fit-content',
    },
}));

export default function BulkActionsDropDownButtons(props) {
    const classes = useStyles();

    return (
        <div className={classes.buttonContainer}>
            <BulkActionsDropDownButton {...props} />
            <BulkActionsDropDownExportButton {...props} />
        </div>
    );
}
