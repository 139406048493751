import React, { useCallback, useEffect, useState } from 'react';

import { getAllRightsHolders } from '@actions/clientList';
import { changeStateByNestedKey } from '@actions/fugaActions';
import { exportAllRepertoireRhAndPerformerAPI } from '@api/export';
import { BreadcrumbNavigation } from '@common/BreadcrumbNavigation';
import CommonConfirmationDialog from '@common/CommonConfirmationDialog';
import CommonTableGrid from '@common/CommonTable/CommonTableGrid';
import ClientLookup from '@common/Lookup/ClientLookup';
import OverflowTip from '@common/OverflowTip';
import RegStatusBarChart from '@common/RegStatusBarChart';
import RegStatusNumbersPopUp from '@common/RegStatusNumbersPopUp';
import StatusSummaryFilters from '@common/StatusSummaryFilters';
import { items } from '@data/constants';
import { DEFAULT_TAB_CMOS } from '@data/globalConstants';
import AddIcon from '@mui/icons-material/Add';
import { Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { APP_ROUTES, BREADCRUMB_TEXTS } from '@utils/navigation';
import utils from '@utils/utils';
import { connect, useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    plusIcon: {
        margin: '0 7px 0 0',
        width: '18px',
        height: '18px',
    },
    createBtn: {
        marginBottom: '16px',
        marginLeft: '16px',
    },
    mainComponent: {
        position: 'relative',
        paddingTop: theme.spacing(2),
    },
}));

function ClientsList() {
    const dispatch = useDispatch();
    const clientsModel = useSelector((state) => state.fugaReducers.clients);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [anchor, setAnchor] = useState(true);
    const [currentDataItem, setCurrentDataItem] = useState({});
    const [isRegStatNumPopupOpen, setIsRegStatNumPopupOpen] = useState(false);
    const classes = useStyles();
    const history = useHistory();

    useEffect(() => {
        dispatch(getAllRightsHolders());
        dispatch(changeStateByNestedKey('rhData', 'selectedTab', ''));
    }, []);

    const headers = [
        {
            title: 'Client Name',
            dataIndex: 'clientName',
        },
        ...(utils.checkItemRolesAndPermissions(items.tableColumns.tier)
            ? [
                  {
                      title: 'Tier',
                      dataIndex: 'tier',
                      hasNumericData: true,
                  },
              ]
            : []),
        ...(utils.checkItemRolesAndPermissions(items.tableColumns.dealStartDate)
            ? [
                  {
                      title: 'Deal start date',
                      dataIndex: 'dealStartDate',
                      hasNumericData: true,
                  },
              ]
            : []),
        ...(utils.checkItemRolesAndPermissions(items.tableColumns.clientRegistrations)
            ? [
                  {
                      title: 'Client registrations',
                      dataIndex: '',
                      fixedWidth: {
                          isFixed: true,
                          value: '130px',
                      },
                  },
              ]
            : []),
    ];

    const openLookup = () => {
        dispatch(changeStateByNestedKey('clients', 'isNewClientModalOpen', true));
    };
    const handleExportAllRepertoireRightsHolder = async () => {
        await exportAllRepertoireRhAndPerformerAPI(false);
    };

    const onRowClickCb = (id, dataItem) => {
        if (dataItem?.permissions?.length === 0) {
            setIsDialogOpen(true);
        } else {
            history.push(`/client-page/${id}`);
        }
    };

    const handleRegistrationsColumnClick = () => {
        dispatch(changeStateByNestedKey('rhData', 'selectedTab', DEFAULT_TAB_CMOS));
    };

    const getSpecificData = useCallback((headerItem, dataItem) => {
        const coulumnTitle = headerItem.title.toLowerCase();
        if (coulumnTitle.includes('client registrations')) {
            const handleHover = (evt) => {
                setAnchor(evt.currentTarget);
                setIsRegStatNumPopupOpen(true);
                setCurrentDataItem(dataItem);
            };

            const handleHOverOut = () => {
                setIsRegStatNumPopupOpen(false);
            };
            return (
                <div onClick={handleRegistrationsColumnClick}>
                    <RegStatusBarChart
                        dataItem={dataItem}
                        onHoverActions={{
                            onMouseEnterCb: handleHover,
                            onMouseLeaveCb: handleHOverOut,
                        }}
                    />
                </div>
            );
        } else {
            return <OverflowTip>{dataItem[headerItem.dataIndex] || ''}</OverflowTip>;
        }
    });

    return (
        <div className={classes.mainComponent}>
            <BreadcrumbNavigation
                items={[
                    { href: APP_ROUTES.CLIENTS, text: BREADCRUMB_TEXTS.CLIENTS, disabled: true },
                    { href: '', text: BREADCRUMB_TEXTS.RIGHTS_HOLDERS },
                ]}
                pageName={'Rights Holders'}
            />
            <div className="flex-right">
                {utils.checkItemRolesAndPermissions(items.btns.rhExportAllRepertoire) && (
                    <Button
                        variant="outlined"
                        color="primary"
                        onClick={handleExportAllRepertoireRightsHolder}
                        className={classes.createBtn}
                        disableElevation
                    >
                        EXPORT ALL REPERTOIRE
                    </Button>
                )}
                {utils.checkItemRolesAndPermissions(items.btns.createRh) && (
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={openLookup}
                        className={classes.createBtn}
                        disableElevation
                    >
                        <AddIcon className={classes.plusIcon} />
                        CREATE RIGHTS HOLDER
                    </Button>
                )}
            </div>
            <ClientLookup />
            {utils.checkItemRolesAndPermissions(items.btns.statusSummaryCmoFilter) && (
                <StatusSummaryFilters
                    isPerformer={{ isRH: true }}
                    onTableReloadCb={getAllRightsHolders}
                    onClearAllCb={getAllRightsHolders}
                    customFilterFieldName="rightsHolderListFilters"
                />
            )}
            <CommonTableGrid
                dataList={clientsModel.dataFromBE}
                headerList={headers}
                loading={clientsModel.tableIsLoading}
                cellDataCb={getSpecificData}
                preferences={{
                    loadDataByPageCb: {
                        dispatchFn: dispatch,
                        callbackFn: getAllRightsHolders,
                        argumentsList: [],
                    },
                    onRowClick: {
                        onRowClickCb: onRowClickCb,
                        rowDataField: 'clientId',
                    },
                }}
                paginationBE={true}
                selection={{
                    isSelectable: false,
                }}
            />
            <RegStatusNumbersPopUp
                isOpen={isRegStatNumPopupOpen}
                closeCb={setIsRegStatNumPopupOpen}
                anchorEl={anchor}
                dataItem={currentDataItem}
            />
            <CommonConfirmationDialog
                isOpen={isDialogOpen}
                closeCb={setIsDialogOpen}
                title="Access restricted"
                description="You do not have rights to open this page. Please contact your administrator."
                noConfirm={true}
            />
        </div>
    );
}

export default connect((r) => r)(ClientsList);
