import React, { useState, useEffect } from 'react';

import AddIcon from '@mui/icons-material/Add';
import { Button, Popover, ClickAwayListener, Box, Typography } from '@mui/material';
import TextFieldWrapper from '@MuiWrappers/TextFieldWrapper';

function CommonLookupPopupWithSuggestionList({
    isOpen,
    closeCb,
    onConfirmCb,
    anchorEl,
    itemsList = [], // structure should be [{ nameToBeSent: '...', displayName: '...', subName: ''}, { nameToBeSent: ....]
    setAnchorCb,
    displayOptions,
    selectedItemOptions,
    searchPlaceholder,
}) {
    const [open, setOpen] = useState(isOpen);
    const [delayId, setDelayId] = useState('');
    const [localItemsList, setLocalItemsList] = useState([]);
    const [searchInputContent, setSearchInputContent] = useState('');

    useEffect(() => {
        setOpen(isOpen);
        if (isOpen && displayOptions?.initialSuggestionsOn) {
            setLocalItemsList(itemsList);
        }
    }, [isOpen, displayOptions?.initialSuggestionsOn, itemsList]);

    useEffect(() => {
        if (selectedItemOptions?.selectedDataItem) {
            setSearchInputContent(selectedItemOptions?.selectedDataItem?.instrumentsAndRoles || '');
        }
    }, [selectedItemOptions?.selectedDataItem]);

    const handleClose = () => {
        setOpen(false);
        closeCb(false);
        setAnchorCb(null);
        if (selectedItemOptions?.setSelectedDataItem) {
            selectedItemOptions.setSelectedDataItem(null);
        }
    };

    const handleConfirm = () => {
        if (onConfirmCb) {
            onConfirmCb(
                {
                    nameToBeSent: searchInputContent,
                    displayName: searchInputContent,
                    addedAsNew: true,
                },
                {
                    isEditing: !!selectedItemOptions?.selectedDataItem,
                    selectedRowValue: selectedItemOptions?.selectedDataItem,
                },
            );
        }
        handleClose();
    };

    const searchItem = (value) => {
        if (value === '') {
            setLocalItemsList([]);
        } else {
            const searchResults = itemsList.filter((item) => item.nameToBeSent.includes(value));
            setLocalItemsList(searchResults);
        }
    };

    const handleSearchInputChange = ({ currentTarget }) => {
        const value = currentTarget.value;
        clearTimeout(delayId);
        setDelayId(
            setTimeout(() => {
                searchItem(value);
            }, 300),
        );
        setSearchInputContent(value);
    };

    const handleItemClick = (value) => {
        onConfirmCb(value, {
            isEditing: !!selectedItemOptions?.selectedDataItem,
            selectedRowValue: selectedItemOptions?.selectedDataItem,
        });
        handleClose();
    };

    return (
        <Popover
            open={open}
            anchorEl={anchorEl}
            anchorOrigin={{
                vertical: displayOptions?.openUp ? 'top' : 'bottom',
                horizontal: displayOptions?.openUp ? 'center' : 'right',
            }}
            transformOrigin={{
                vertical: displayOptions?.openUp ? 'bottom' : 'top',
                horizontal: displayOptions?.openUp ? 'center' : 'right',
            }}
            sx={{
                '& .MuiPopover-paper': { padding: 0 },
            }}
        >
            <ClickAwayListener onClickAway={handleClose}>
                <Box
                    sx={{
                        padding: '16px',
                        width: '334px',
                        minHeight: '147px',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                        position: 'relative',
                    }}
                >
                    <TextFieldWrapper
                        placeholder={searchPlaceholder || 'Search Rights holders'}
                        fullWidth
                        value={searchInputContent}
                        onChange={handleSearchInputChange}
                        sx={{ padding: '22px 0 16px 0' }}
                    />
                    <Box
                        sx={{
                            maxHeight: '463px',
                            minHeight: '155px',
                            overflow: 'auto',
                            width: '318px',
                            paddingBottom: '39px',
                        }}
                    >
                        {localItemsList.map((el, index) => (
                            <Box
                                key={el.nameToBeSent + index}
                                onClick={() => handleItemClick(el)}
                                sx={{
                                    padding: '13px 14px 14px 16px',
                                    '&:hover': {
                                        backgroundColor: 'rgba(0, 0, 0, 0.04)',
                                        cursor: 'pointer',
                                    },
                                }}
                            >
                                <Typography variant="body1">{el.nameToBeSent}</Typography>
                                {!displayOptions?.noSubName && (
                                    <Typography variant="body2">{el.subName}</Typography>
                                )}
                            </Box>
                        ))}
                    </Box>
                    {!displayOptions?.noOptionBtns && (
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                                position: 'absolute',
                                bottom: 0,
                                left: 0,
                                background: '#FFF',
                                width: '100%',
                                paddingBottom: '16px',
                                paddingTop: '16px',
                                '& > *': { marginLeft: '24px' },
                            }}
                        >
                            <Button
                                onClick={handleClose}
                                variant="text"
                                color="primary"
                                disableElevation
                                sx={{ height: '26px' }}
                            >
                                Cancel
                            </Button>
                            <Button
                                variant="contained"
                                color="primary"
                                disableElevation
                                onClick={handleConfirm}
                                disabled={localItemsList.length > 0 ? true : !searchInputContent}
                                sx={{ height: '26px', marginRight: '8px' }}
                            >
                                <AddIcon
                                    sx={{ margin: '0 7px 0 0', width: '18px', height: '18px' }}
                                />
                                Add as new
                            </Button>
                        </Box>
                    )}
                </Box>
            </ClickAwayListener>
        </Popover>
    );
}

export default CommonLookupPopupWithSuggestionList;
