import React, { useCallback } from 'react';

import { changeStateByNestedKey } from '@actions/fugaActions';
import { getRightHoldersList } from '@actions/rightHolders';
import { useSelector, useDispatch } from 'react-redux';

import Lookup from './Lookup';

const ClientLookup = () => {
    const dispatch = useDispatch();
    const open = useSelector((state) => state.fugaReducers.clients.isNewClientModalOpen);
    const onClose = () =>
        dispatch(changeStateByNestedKey('clients', 'isNewClientModalOpen', false));

    const searchAction = useCallback((term) => dispatch(getRightHoldersList(term)), [dispatch]);
    const suggestionSelector = (state) => state.fugaReducers.clients.suggestedClients;
    const dataSelector = (state) => state.fugaReducers.clientPage;

    const navigateTo = '/client-page/-1';
    const newItemData = { legalCompanyName: 'New Client' };

    const setIsInSaveNewModeAction = (value) =>
        changeStateByNestedKey('clientPage', 'isInSaveNewMode', value);
    const setSelectedIndexAction = (index) =>
        changeStateByNestedKey('clientPage', 'selectedIndex', index);
    const setDataAction = (data) =>
        changeStateByNestedKey('rhData', 'detailsData', {
            legalCompanyName: data.rightsHolderName,
        });

    return (
        <Lookup
            open={open}
            onClose={onClose}
            searchAction={searchAction}
            suggestionSelector={suggestionSelector}
            dataSelector={dataSelector}
            navigateTo={navigateTo}
            newItemData={newItemData}
            itemName="rightsHolderName"
            setIsInSaveNewModeAction={setIsInSaveNewModeAction}
            setSelectedIndexAction={setSelectedIndexAction}
            setDataAction={setDataAction}
            anchorPosition={{ top: 260, left: window.innerWidth - 200 }}
        />
    );
};

export default ClientLookup;
