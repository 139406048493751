import React from 'react';

import Grid from '@mui/material/Grid2';
import { DatePicker } from '@mui/x-date-pickers';
import TextFieldWrapper from '@MuiWrappers/TextFieldWrapper';
import { ASSET_METADATA_FIELDS } from '@utils/constants/AssetMetadataEditModal';
import { format, parse } from 'date-fns';
import { Controller, useFormContext } from 'react-hook-form';

export function AssetMetaDataCopyrightSection() {
    const {
        control,
        formState: { errors },
        trigger,
    } = useFormContext();

    return (
        <Grid spacing={3} container size={12}>
            {/* Recording Year */}
            <Grid
                size={{
                    xs: 12,
                    sm: 3,
                }}
            >
                <Controller
                    name={ASSET_METADATA_FIELDS.pyear}
                    control={control}
                    render={({ field }) => (
                        <DatePicker
                            {...field}
                            views={['year']}
                            label="℗ Recording copyright year (PYear)"
                            slotProps={{
                                textField: {
                                    variant: 'standard',
                                    fullWidth: true,
                                    required: true,
                                    error: !!errors.pyear,
                                    helperText: errors.pyear ? errors.pyear.message : '',
                                },
                            }}
                            value={field.value ? parse(field.value, 'yyyy', new Date()) : null}
                            onChange={(date) => {
                                field.onChange(date ? format(date, 'yyyy') : '');
                                trigger([
                                    ASSET_METADATA_FIELDS.recordingYear,
                                    ASSET_METADATA_FIELDS.pyear,
                                    ASSET_METADATA_FIELDS.recordingDate,
                                ]);
                            }}
                        />
                    )}
                />
            </Grid>
            {/* ℗ Recording copyright text (PName) */}
            <Grid
                size={{
                    xs: 12,
                    sm: 9,
                }}
            >
                <Controller
                    name={ASSET_METADATA_FIELDS.pname}
                    control={control}
                    render={({ field }) => (
                        <TextFieldWrapper
                            {...field}
                            label="℗ Recording copyright text (PName)"
                            fullWidth
                            required
                            error={!!errors.pname}
                            helperText={errors.pname ? errors.pname.message : ''}
                        />
                    )}
                />
            </Grid>
        </Grid>
    );
}
