import React, { useCallback, useEffect, useState } from 'react';

import { validateAssetMetadataById } from '@actions/apiActions';
import { changeStateByKey, changeStateByNestedKey } from '@actions/fugaActions';
import { getAssetByPerformerId } from '@actions/performers';
import { uploadSpotifyDataFileAPI } from '@api/clientController';
import { exportSpecificRepertoireAPI } from '@api/export';
import AssetMetaDataEditModal from '@common/AssetMetaDataEdit/AssetMetaDataEditModal';
import CommonFiltersContainer from '@common/CommonFiltersContainer';
import CommonMoreDropDownButton from '@common/CommonMoreDropDownButton';
import CommonSearchInput from '@common/CommonSearchInput';
import CommonTableGrid from '@common/CommonTable/CommonTableGrid';
import DropDownButton from '@common/DropDownButton';
import DropDownButtonKebabMenu from '@common/DropDownButtonKebabMenu';
import LoadingScreen from '@common/LoadingScreen';
import OverflowTip from '@common/OverflowTip';
import TableFilters from '@common/TableFilters';
import { items } from '@data/constants';
import useAssetData from '@hooks/useAssetData';
import { EditOutlined } from '@mui/icons-material';
import { Button, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { makeStyles } from '@mui/styles';
import helper from '@utils/helper';
import utils from '@utils/utils';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';

const headers = [
    {
        title: 'Artist(s)',
        dataIndex: 'artistList',
    },
    {
        title: 'Track / Version',
        dataIndex: ['trackTitle', 'versionTitle'],
    },
    {
        title: 'ISRC',
        dataIndex: 'isrcCode',
    },
    {
        title: 'Contr. Cat.',
        dataIndex: 'contributionCategory',
    },
    {
        title: 'Instruments',
        dataIndex: 'contributionInstruments',
    },
    {
        title: 'Product',
        dataIndex: 'albumTitle',
    },
    {
        title: 'Release Date',
        dataIndex: 'releaseDate',
        hasNumericData: true,
    },
    ...(utils.checkItemRolesAndPermissions(items.tableColumns.lastModified)
        ? [
              {
                  title: 'Last modified',
                  dataIndex: 'lastModified',
              },
          ]
        : []),
    ...(utils.checkItemRolesAndPermissions(items.tableColumns.ingested)
        ? [
              {
                  title: 'Ingested',
                  dataIndex: 'createdDate',
              },
          ]
        : []),
    {
        title: '',
        dataIndex: '',
    },
];

const useStyle = makeStyles((theme) => ({
    optionsRow: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: theme.spacing(2),
    },
    optionsRowActions: {
        display: 'flex',
        alignItems: 'center',
    },
}));

function PerformerRepertoires(props) {
    const dispatch = useDispatch();
    const classes = useStyle();

    const [openAssetMetadataModal, setOpenAssetMetadataModal] = useState(false);
    const [clickedDataItem, setClickedDataItem] = useState({});

    const pageModelName = 'performersRepertoire';
    const fetchAssetsFunction = getAssetByPerformerId;

    const {
        getAssetData,
        handlePaginationAndSearch,
        handleSearch,
        clearSearch,
        pageModel: performersRepertoireModel,
    } = useAssetData({
        match: props.match,
        fetchAssetsFunction,
        pageModelName,
    });

    const isSpotifyIdsPresentModel = useSelector(
        (state) => state.fugaReducers.performers.byClietnId.isSpotifyIdsPresent,
    );

    const [isExportDisabled, setIsExportDisabled] = useState(false);

    useEffect(() => {
        getAssetData();
    }, []);

    useEffect(() => {
        setIsExportDisabled(isDisabled());
    }, [performersRepertoireModel.dataFromBE]);

    const openModal = () => {
        setOpenAssetMetadataModal(true);
    };

    const onFilterClearAll = () => {
        dispatch(changeStateByKey('filters', {}));
        getAssetData();
    };

    const onFilterTableReload = (additionalFilters) => {
        for (const key in additionalFilters) {
            dispatch(changeStateByNestedKey('filters', key, additionalFilters[key]));
        }
        dispatch(changeStateByNestedKey('queryParams', 'page', 0));

        getAssetData();
    };

    const getClientSpecificData = useCallback((headerItem, dataItem) => {
        const columnTitle = headerItem.title.toLowerCase();
        if (columnTitle.includes('track')) {
            return (
                <>
                    <Typography variant="body2">
                        <OverflowTip>{dataItem[headerItem.dataIndex[0]]}</OverflowTip>
                    </Typography>
                    <Typography variant="caption">
                        <OverflowTip>{dataItem[headerItem.dataIndex[1]]}</OverflowTip>
                    </Typography>
                </>
            );
        } else if (columnTitle === '') {
            const menuItems = [
                {
                    title: 'Track details',
                    onClickClb: () => {
                        setClickedDataItem(dataItem);
                        openModal();
                    },
                    icon: (
                        <EditOutlined
                            style={{ width: '24px', height: '24px', color: '#00000099' }}
                        />
                    ),
                },
            ];
            return (
                <>
                    <CommonMoreDropDownButton menuItems={menuItems} />
                </>
            );
        } else {
            return <OverflowTip>{dataItem[headerItem.dataIndex]}</OverflowTip>;
        }
    });

    const handleExportRepertoire = () => {
        const isPerformer = true;
        helper.exportRepertoire(props.match.params.id, dispatch, isPerformer);
    };

    const handleExportSpecificRepertoire = async () => {
        await exportSpecificRepertoireAPI(props.match.params.id);
    };

    const isDisabled = () => {
        const data = performersRepertoireModel.dataFromBE.content;
        if (data) {
            return data.length === 0;
        } else {
            return true;
        }
    };

    const getSpotify = async () => {
        await uploadSpotifyDataFileAPI(props.match.params.id);
    };

    return (
        <div className="topContainer" style={{ padding: '32px 0' }}>
            <Grid
                container
                className={classes.optionsRow}
                sx={{
                    justifyContent: 'space-between',
                    alignItems: 'center',
                }}
            >
                <Grid>
                    <CommonSearchInput
                        placeHolderText={'Search in repertoire'}
                        onSearch={handleSearch}
                        term={''}
                        onClearCb={clearSearch}
                    />
                </Grid>
                <Grid>
                    <div className={classes.optionsRowActions}>
                        <Button
                            variant="outlined"
                            onClick={handleExportSpecificRepertoire}
                            disabled={isExportDisabled}
                        >
                            EXPORT REPERTOIRE
                        </Button>
                        {utils.checkItemRolesAndPermissions(
                            items.btns.performerRepertoireAddFromSource,
                        ) && <DropDownButton />}
                        {utils.checkItemRolesAndPermissions(items.btns.performerRepertoire) && (
                            <DropDownButtonKebabMenu
                                isSpotifyIdPresent={isSpotifyIdsPresentModel}
                                callbacks={{ getSpotifyCb: getSpotify }}
                                handleExportRepertoire={handleExportRepertoire}
                                disabled={isExportDisabled}
                            />
                        )}
                    </div>
                </Grid>
            </Grid>
            <CommonFiltersContainer
                innerFiltersComponent={
                    <TableFilters
                        tableReloadCb={onFilterTableReload}
                        onClearAll={onFilterClearAll}
                    />
                }
            />
            <CommonTableGrid
                dataList={performersRepertoireModel.dataFromBE}
                headerList={headers}
                cellDataCb={getClientSpecificData}
                loading={performersRepertoireModel.tableIsLoading}
                sortPrefs={{
                    storeName: 'queryParams',
                    storeSubName: 'sortValue',
                    value: performersRepertoireModel.sortValue,
                }}
                preferences={{
                    loadDataByPageCb: {
                        dispatchFn: dispatch,
                        callbackFn: handlePaginationAndSearch,
                        argumentsList: [{}], // passing mock obj instead of updateParams
                        noDispatch: true,
                    },
                }}
                paginationBE
                selection={{
                    isSelectable: false,
                    dispatchFn: dispatch,
                    callbackFn: changeStateByNestedKey,
                }}
            />
            <LoadingScreen />
            {openAssetMetadataModal && (
                <AssetMetaDataEditModal
                    isOpen={openAssetMetadataModal}
                    setOpen={setOpenAssetMetadataModal}
                    dataItem={clickedDataItem}
                    onSuccessCb={validateAssetMetadataById}
                    isPerformer={true}
                />
            )}
        </div>
    );
}

const withUrlPerformerRepertoires = withRouter(PerformerRepertoires);
export default withUrlPerformerRepertoires;
