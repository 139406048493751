import React, { useCallback, useState } from 'react';

import { deleteArtistByIdAPI, editArtistAssetMetadataByIdAPI } from '@api/metadataController';
import CommonConfirmationDialog from '@common/CommonConfirmationDialog';
import CommonTableGrid from '@common/CommonTable/CommonTableGrid';
import { NewArtistModal } from '@common/modals/NewArtistModal';
import OverflowTip from '@common/OverflowTip';
import { DeleteOutline } from '@mui/icons-material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { MenuItem, Select, Button, styled } from '@mui/material';
import Grid from '@mui/material/Grid2';
import TextFieldWrapper from '@MuiWrappers/TextFieldWrapper';
import { Controller, useFormContext } from 'react-hook-form';

const ActionButton = styled(DeleteOutline)({
    width: '18px',
    height: '18px',
    color: '#5F5F60',
    '&:hover': {
        cursor: 'pointer',
    },
});

const StyledButton = styled(Button)({
    padding: '6px 10px',
});

const headers = [
    {
        title: 'Artist',
        dataIndex: 'artistName',
    },
    {
        title: 'Role',
        dataIndex: 'isPrimary',
    },
    {
        title: '',
        dataIndex: '',
        hasIcon: true,
    },
];

export function AssetMetaDataArtistsSection({
    dataItem,
    tableReloadOnSuccess,
    artists,
    setArtists,
    productMode = false,
    displayArtistsFieldName = 'displayArtist',
}) {
    const assetMetadataArtists = artists.content ? artists : { content: artists };
    const {
        control,
        formState: { errors },
    } = useFormContext();

    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
    const [selectedContributorDataItem, setSelectedContributorDataItem] = useState(null);
    const [showAddArtistModal, setShowAddArtistModal] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);

    const openAddArtistModal = (event) => {
        setAnchorEl(event.currentTarget);
        setShowAddArtistModal(true);
    };

    const closeAddArtistModal = () => {
        setAnchorEl(null);
        setShowAddArtistModal(false);
    };

    const handleRoleChange = async (event, item) => {
        const newRole = event.target.value === 'Primary';

        if (productMode) {
            const newArtists = artists.map((artist) =>
                artist.artistId === item.artistId || artist.tempId === item.tempId
                    ? { ...artist, isPrimary: newRole }
                    : artist,
            );
            setArtists(newArtists);
            return;
        }

        try {
            await editArtistAssetMetadataByIdAPI(dataItem.assetId, {
                artistId: item.artistId,
                isPrimary: newRole,
            });
            tableReloadOnSuccess();
        } catch (error) {
            console.error('Error updating artist role:', error);
        }
    };

    const handleDeleteContribution = async () => {
        if (productMode) {
            console.log(
                'selectedContributorDataItem',
                selectedContributorDataItem,
                'artists',
                artists,
            );
            const newArtists = artists.filter((artist) => {
                if (selectedContributorDataItem.artistId) {
                    return artist.artistId !== selectedContributorDataItem.artistId;
                }
                return artist.tempId !== selectedContributorDataItem.tempId;
            });
            setArtists(newArtists);
            return;
        }

        try {
            await deleteArtistByIdAPI(dataItem.assetId, selectedContributorDataItem.artistId);
            tableReloadOnSuccess();
        } catch (error) {
            console.error('Error deleting artist:', error);
        }
    };

    const getSpecificData = useCallback(
        (headerItem, dataItem) => {
            const columnTitle = headerItem.title.toLowerCase();

            if (columnTitle === '') {
                const handleDeleteClick = () => {
                    setSelectedContributorDataItem(dataItem);
                    setIsDeleteDialogOpen(true);
                };
                return <ActionButton onClick={handleDeleteClick} />;
            } else if (columnTitle === 'role') {
                return (
                    <Select
                        value={dataItem.isPrimary ? 'Primary' : 'Featured'}
                        onChange={(event) => handleRoleChange(event, dataItem)}
                        sx={{ width: '92px' }}
                        variant="standard"
                    >
                        <MenuItem value="Primary">Primary</MenuItem>
                        <MenuItem value="Featured">Featured</MenuItem>
                    </Select>
                );
            } else {
                return <OverflowTip>{dataItem[headerItem.dataIndex] || ''}</OverflowTip>;
            }
        },
        [handleRoleChange],
    );

    return (
        <>
            <Grid xs={12}>
                <Controller
                    name={displayArtistsFieldName}
                    control={control}
                    render={({ field }) => (
                        <TextFieldWrapper
                            {...field}
                            label="Display artist(s)"
                            fullWidth
                            required
                            error={!!errors[displayArtistsFieldName]}
                            helperText={errors[displayArtistsFieldName]?.message || ''}
                            sx={{ marginBottom: 4 }}
                        />
                    )}
                />
            </Grid>

            <CommonTableGrid
                dataList={assetMetadataArtists}
                headerList={headers}
                preferences={{ loadDataByPageCb: {} }}
                selection={{ isSelectable: false }}
                cellDataCb={getSpecificData}
                isInSubModal
                rowsPerPageOff
                tablePaginationOff
                footerAddition={{
                    itemComponent: (
                        <StyledButton
                            variant="outlined"
                            color="primary"
                            disableElevation
                            onClick={openAddArtistModal}
                            endIcon={<ArrowDropDownIcon />}
                        >
                            Add Artist
                        </StyledButton>
                    ),
                }}
            />

            {showAddArtistModal && (
                <NewArtistModal
                    anchorEl={anchorEl}
                    open={showAddArtistModal}
                    onClose={closeAddArtistModal}
                    setArtists={setArtists}
                    artists={artists}
                    productMode={productMode}
                    loadArtistsData={tableReloadOnSuccess}
                    assetId={dataItem.assetId}
                />
            )}

            <CommonConfirmationDialog
                isOpen={isDeleteDialogOpen}
                closeCb={setIsDeleteDialogOpen}
                title="Confirm delete artist"
                description={`Would you like to delete '${
                    selectedContributorDataItem?.artistName || ''
                }'?`}
                onConfirmCb={handleDeleteContribution}
                customConfirmText="Delete"
            />
        </>
    );
}
