import {
    createClientDealAPI,
    createClientDealPerfOrRHAPI,
    editClientDealPerfOrRHAPI,
} from '@api/clientDealController';
import { formatTerritoryCodesAPI, getAllTerritoriesAPI } from '@api/territoriesController';
import helper from '@utils/helper';

import { changeStateByNestedKey } from './fugaActions';
import { getPerformerById } from './performers';
import { getRhClientInfoById } from './rightHolders';

function getAllTerritories() {
    return async function (dispatch) {
        const resp = await getAllTerritoriesAPI();

        const groupedTerritories = helper.formatTerritoriesForModal(resp.data);
        dispatch(changeStateByNestedKey('territories', 'groupedTerritories', groupedTerritories));
    };
}

function formatTerritoryCodes(territoryCodes) {
    return async function (dispatch) {
        const resp = await formatTerritoryCodesAPI(territoryCodes);

        const groupedTerritories = helper.formatTerritoriesForModal(resp.data, true);
        dispatch(changeStateByNestedKey('territories', 'checkedTerritories', groupedTerritories));
    };
}

// This method is replacing editClientDeal function as it was deprecated
function editClientDealPerfOrRH(dealOptions, isPerformer = true) {
    return async function (dispatch) {
        await editClientDealPerfOrRHAPI(dealOptions, isPerformer);

        if (isPerformer) {
            dispatch(getPerformerById(dealOptions.clientId));
        } else {
            dispatch(getRhClientInfoById(dealOptions.clientId));
        }
    };
}

function createClientDealPerfOrRH(dealOptions, isPerformer = true) {
    return async function (dispatch) {
        await createClientDealPerfOrRHAPI(dealOptions, isPerformer);

        if (isPerformer) {
            dispatch(getPerformerById(dealOptions.clientId));
        } else {
            dispatch(getRhClientInfoById(dealOptions.clientId));
        }
    };
}

function createClientDeal(dealOptions) {
    return async function (dispatch) {
        await createClientDealAPI(dealOptions);

        dispatch(getRhClientInfoById(dealOptions.clientId));
    };
}

export {
    formatTerritoryCodes,
    getAllTerritories,
    editClientDealPerfOrRH,
    createClientDealPerfOrRH,
    createClientDeal,
};
