import React, { useState } from 'react';

import { createBulkComments } from '@api/assetsRegistrationBulkActions';
import { isSymbolsCountValid } from '@common/modals/common';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormHelperText,
    TextField,
    Tooltip,
    Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { getFiltersToSend } from '@pages/AssetsCmoPage/common/common';
import { useSelector } from 'react-redux';

const useStyles = makeStyles(() => ({
    title: {
        padding: '19px 16px',
    },
    content: {
        padding: '10px 16px',
    },
    textField: {
        width: '552px',
        paddingBottom: 0,
    },
    commentCounter: {
        display: 'flex',
        paddingBottom: '21px',
        justifyContent: 'flex-end',
    },
}));

export function AssetsRegistrationCommentsAddBulkModal({
    isOpen,
    setOpen,
    queryParams,
    getAssetData,
    isPerformer,
}) {
    const classes = useStyles();
    const [commentInput, setCommentInput] = useState('');
    const [commentSymCount, setCommentSymCount] = useState(0);
    const [tooltipOpen, setTooltipOpen] = useState(false);

    const filtersModel = useSelector((state) => state.fugaReducers.filters);

    const handleTooltipClose = () => {
        setTooltipOpen(false);
    };

    const handleTooltipOpen = () => {
        setTooltipOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleSave = async () => {
        const commonQueryParams = {
            cmoId: queryParams.cmoId,
            claimMethod: queryParams.claimMethod,
        };

        const commonBodyParams = {
            ...getFiltersToSend(filtersModel),
            commentMessage: commentInput,
            includeAssetIds: queryParams.assetsList.map(({ assetId, clientId }) => ({
                assetId,
                clientId,
            })),
            excludeAssetIds: queryParams.excludeList.map(({ assetId, clientId }) => ({
                assetId,
                clientId,
            })),
            searchString: filtersModel.searchString,
        };

        await createBulkComments(commonQueryParams, commonBodyParams, isPerformer);

        getAssetData();
        handleClose();
    };

    const handleInputChange = (e) => {
        const value = e.currentTarget.value;
        if (isSymbolsCountValid(value, handleTooltipOpen, handleTooltipClose, setCommentSymCount)) {
            setCommentInput(value);
        }
    };

    return (
        <Dialog onClose={handleClose} open={isOpen} maxWidth="md">
            <DialogTitle onClose={handleClose} className={classes.title}>
                <Typography variant="h6">
                    Add Comment to {queryParams.assetsList.length} assets
                </Typography>
            </DialogTitle>
            <DialogContent className={classes.content}>
                <Tooltip
                    title="Input text should be less than 5000 charactes"
                    disableHoverListener="true"
                    open={tooltipOpen}
                >
                    <TextField
                        label="Comment"
                        onChange={(evt) => handleInputChange(evt)}
                        name="comment"
                        type="text"
                        multiline
                        maxRows={10}
                        value={commentInput || ''}
                        className={classes.textField}
                        onBlur={() => setTooltipOpen(false)}
                    />
                </Tooltip>
                <div className={classes.commentCounter}>
                    <FormHelperText>{commentSymCount}/5000</FormHelperText>
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} variant="outlined" color="primary" disableElevation>
                    cancel
                </Button>
                <Button onClick={handleSave} variant="contained" color="primary" disableElevation>
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
}
