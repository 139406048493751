import React, { useState, useLayoutEffect } from 'react';

import { getReportById } from '@actions/reports';
import WarningDialog from '@common/WarningDialog';
import {
    Notifications as NotificationIcon,
    CloseOutlined as CloseIcon,
    VisibilityOutlined as ViewIcon,
} from '@mui/icons-material';
import {
    Popover,
    Paper,
    Button,
    ClickAwayListener,
    List,
    ListItem,
    ListSubheader,
    Typography,
    Divider,
    IconButton,
} from '@mui/material';
import { makeStyles, withStyles, createStyles } from '@mui/styles';
import { connect, useDispatch, useSelector } from 'react-redux';

const withNotificationStyles = withStyles((theme) =>
    createStyles({
        button: {
            fill: theme.palette.text.secondary,
        },
        list: {
            minHeight: 80,
            maxHeight: 300,
            overflowY: 'auto',
            margin: '8px 0px',
        },
    }),
);

const useStyles = makeStyles({
    flexHorizontal_s_b: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    flexLeftHorizontal: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
    },
    flexHorizontal: {
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'center',
    },
    flexVert: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        // updated some layout as fix for NR-875
        overflowWrap: 'anywhere',
    },
    bellIcon: {
        color: '#555555',
        width: '20px',
        height: '20px',
        marginRight: '13.67px',
    },
    closeIcon: {
        color: 'rgba(0, 0, 0, 0.6)',
        width: '24px',
        height: '22px',
    },
    ntfTitle: {
        color: 'rgba(0, 0, 0, 0.87)',
    },
    title: {
        padding: '14px 0',
    },
    devider: {
        marginTop: '14px',
    },
    viewIcon: {
        marginLeft: '7.74px',
        width: '18px',
        height: '18px',
    },
    listItem: {
        padding: '13px 0 14px 0',
    },
    listRow: {
        width: '100%',
    },
    listSubheader: {
        padding: '0',
        backgroundColor: '#FFFFFF',
    },
    paper: {
        padding: '0 1px 8px 16px',
        overflowY: 'scroll',
    },
    paperContent: {
        display: 'flex',
        flexDirection: 'column',
        width: '360px',
        height: '576px',
    },
    status: {
        marginBottom: '3px',
    },
});

let NotificationContent = ({ onClose, open, contentData }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const repertoiresModel = useSelector((state) => state.fugaReducers.repertoires);
    // TODO will be implemented in scope of NR-388
    // const [isLoading, setIsLoading] = useState(false);

    const isStatusComplete = (item) => {
        return !!item.status.includes('complete');
    };

    const handleViewResults = (id) => {
        onClose();
        dispatch(getReportById(id));
    };

    return (
        <>
            <Popover
                open={open}
                anchorReference="anchorPosition"
                anchorPosition={{ top: 60, left: window.innerWidth - 200 }}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
            >
                <ClickAwayListener onClickAway={onClose}>
                    <Paper className={`${classes.paper}`}>
                        <div className={`${classes.paperContent}`}>
                            <ListSubheader className={classes.listSubheader}>
                                <div className={`${classes.title}`}>
                                    <div className={`${classes.flexHorizontal_s_b}`}>
                                        <div className={`${classes.flexLeftHorizontal}`}>
                                            <NotificationIcon className={`${classes.bellIcon}`} />
                                            <Typography
                                                variant="h6"
                                                className={`${classes.ntfTitle}`}
                                            >
                                                Notifications
                                            </Typography>
                                        </div>
                                        <IconButton onClick={onClose} size="large">
                                            <CloseIcon className={`${classes.closeIcon}`} />
                                        </IconButton>
                                    </div>
                                    <Divider className={`${classes.devider}`} />
                                </div>
                            </ListSubheader>
                            <List>
                                {contentData.map((item, index) => {
                                    return (
                                        <ListItem
                                            key={`${item.status}-${index}`}
                                            value={item.uploadStart}
                                            className={classes.listItem}
                                        >
                                            <div
                                                className={`${classes.flexVert} ${classes.listRow}`}
                                            >
                                                <div>
                                                    <Typography
                                                        className={`${classes.status}`}
                                                        variant="subtitle2"
                                                    >
                                                        {item.status}
                                                    </Typography>
                                                    <Typography variant="body2">
                                                        {item.filename}
                                                    </Typography>
                                                </div>
                                                <div className={`${classes.flexHorizontal_s_b}`}>
                                                    <Typography variant="caption">
                                                        {isStatusComplete(item)
                                                            ? item.uploadEnd
                                                            : item.uploadStart}
                                                    </Typography>
                                                    {
                                                        // TODO temporary solution to see error results as well
                                                        isStatusComplete(item) && (
                                                            <Button
                                                                variant="text"
                                                                color="primary"
                                                                onClick={() =>
                                                                    handleViewResults(item.reportId)
                                                                }
                                                            >
                                                                View Results{' '}
                                                                <ViewIcon
                                                                    className={classes.viewIcon}
                                                                    disableElevation
                                                                />
                                                            </Button>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                        </ListItem>
                                    );
                                })}
                            </List>
                        </div>
                        <div className="flex-horizontal">
                            {/* TODO will be implemented in scope of NR-388 */}
                            {/* {isLoading && <CircularProgress />} */}
                        </div>
                    </Paper>
                </ClickAwayListener>
            </Popover>
            <WarningDialog
                style={{ zIndex: '-1' }}
                isOpen={repertoiresModel.errorDialogIsOpen}
                dialogContent={repertoiresModel.uploadErrors}
            />
        </>
    );
};

NotificationContent = withNotificationStyles(NotificationContent);

let Notification = (props) => {
    const { open: initialOpen, contentData, onCloseProp } = props;

    const [open, setOpen] = useState(false);

    function makeClose() {
        setOpen(false);
        onCloseProp(false);
    }

    useLayoutEffect(() => {
        if (initialOpen === true) {
            setOpen(true);
        } else if (initialOpen === false) {
            setOpen(false);
        }
    }, [initialOpen]);

    return (
        <>
            <NotificationContent onClose={makeClose} open={open} contentData={contentData} />
        </>
    );
};

Notification = withStyles((theme) => ({
    input: {
        width: '100%',
        cursor: 'pointer',
        'caret-color': 'transparent',
    },
    root: {
        width: '360px',
        height: '576px',
        marginBottom: 10,
    },
    required: {
        '& span': {
            color: `${theme.palette.warning.dark} !important`,
        },
    },
}))(Notification);

export default connect((r) => r)(Notification);
