import React, { useCallback, useEffect, useState } from 'react';

import { changeStateByNestedKey } from '@actions/fugaActions';
import { getAllPerformers } from '@actions/performers';
import { exportAllRepertoireRhAndPerformerAPI } from '@api/export';
import { BreadcrumbNavigation } from '@common/BreadcrumbNavigation';
import CommonConfirmationDialog from '@common/CommonConfirmationDialog';
import CommonTableGrid from '@common/CommonTable/CommonTableGrid';
import PerformerLookup from '@common/Lookup/PerformerLookup';
import OverflowTip from '@common/OverflowTip';
import RegStatusBarChart from '@common/RegStatusBarChart';
import RegStatusNumbersPopUp from '@common/RegStatusNumbersPopUp';
import StatusSummaryFilters from '@common/StatusSummaryFilters';
import { items } from '@data/constants';
import { DEFAULT_TAB_CMOS } from '@data/globalConstants';
import AddIcon from '@mui/icons-material/Add';
import { Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { APP_ROUTES, BREADCRUMB_TEXTS } from '@utils/navigation';
import utils from '@utils/utils';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    disabled: {
        pointerEvents: 'none',
        color: '#9B9B9D',
    },
    linkBreadcrumb: {
        color: '#1F1F21',
        '&:hover': {
            color: '#1F1F21',
        },
    },
    plusIcon: {
        margin: '0 7px 0 0',
        width: '18px',
        height: '18px',
    },
    createBtn: {
        marginBottom: '16px',
        marginLeft: '16px',
    },
    breadcrumbs: {
        marginBottom: theme.spacing(1.5),
    },
}));

function PerformersList() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();
    const performersModel = useSelector((state) => state.fugaReducers.performers);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [anchor, setAnchor] = useState(true);
    const [currentDataItem, setCurrentDataItem] = useState({});
    const [isRegStatNumPopupOpen, setIsRegStatNumPopupOpen] = useState(false);

    useEffect(() => {
        dispatch(getAllPerformers());
        dispatch(changeStateByNestedKey('performers', 'selectedTab', ''));
    }, [performersModel]);

    const headers = [
        {
            title: 'Client Name',
            dataIndex: 'clientName',
        },
        {
            title: 'Aliases/Artist Names',
            dataIndex: 'artistName',
        },
        // This part will remove column when it is restricted by role
        ...(utils.checkItemRolesAndPermissions(items.tableColumns.tier)
            ? [
                  {
                      title: 'Tier',
                      dataIndex: 'tier',
                      hasNumericData: true,
                  },
              ]
            : []),
        ...(utils.checkItemRolesAndPermissions(items.tableColumns.dealStartDate)
            ? [
                  {
                      title: 'Deal start date',
                      dataIndex: 'dealStartDate',
                      hasNumericData: true,
                  },
              ]
            : []),
        ...(utils.checkItemRolesAndPermissions(items.tableColumns.clientRegistrations)
            ? [
                  {
                      title: 'Client registrations',
                      dataIndex: '',
                      fixedWidth: {
                          isFixed: true,
                          value: '130px',
                      },
                  },
              ]
            : []),
    ];

    const openLookup = () => {
        dispatch(changeStateByNestedKey('performers', 'isNewClientModalOpen', true));
    };

    const onRowClickCb = (id, dataItem) => {
        if (dataItem?.permissions?.length === 0) {
            setIsDialogOpen(true);
        } else {
            history.push(`/performer-page/${id}`);
        }
    };

    const handleRegistrationsColumnClick = () => {
        dispatch(changeStateByNestedKey('performers', 'selectedTab', DEFAULT_TAB_CMOS));
    };

    const handleExportAllRepertoirePerformer = async () => {
        await exportAllRepertoireRhAndPerformerAPI(true);
    };

    const getSpecificData = useCallback((headerItem, dataItem) => {
        const coulumnTitle = headerItem.title.toLowerCase();
        if (coulumnTitle.includes('client registrations')) {
            const handleHover = (evt) => {
                setAnchor(evt.currentTarget);
                setIsRegStatNumPopupOpen(true);
                setCurrentDataItem(dataItem);
            };

            const handleHOverOut = () => {
                setIsRegStatNumPopupOpen(false);
            };

            return (
                <div onClick={handleRegistrationsColumnClick}>
                    <RegStatusBarChart
                        dataItem={dataItem}
                        onHoverActions={{
                            onMouseEnterCb: handleHover,
                            onMouseLeaveCb: handleHOverOut,
                        }}
                    />
                </div>
            );
        } else {
            return <OverflowTip>{dataItem[headerItem.dataIndex] || ''}</OverflowTip>;
        }
    });

    return (
        <>
            <div className="mainComponent">
                <BreadcrumbNavigation
                    items={[
                        {
                            href: APP_ROUTES.CLIENTS,
                            text: BREADCRUMB_TEXTS.CLIENTS,
                            disabled: true,
                        },
                        { href: '', text: BREADCRUMB_TEXTS.PERFORMERS },
                    ]}
                    pageName="Performers"
                />

                <div className="flex-right">
                    {utils.checkItemRolesAndPermissions(
                        items.btns.performerExportAllRepertoire,
                    ) && (
                        <Button
                            variant="outlined"
                            color="primary"
                            className={classes.createBtn}
                            disableElevation
                            onClick={handleExportAllRepertoirePerformer}
                        >
                            EXPORT ALL REPERTOIRE
                        </Button>
                    )}
                    {utils.checkItemRolesAndPermissions(items.btns.createPerformer) && (
                        <Button
                            variant="contained"
                            color="primary"
                            className={classes.createBtn}
                            disableElevation
                            onClick={openLookup}
                        >
                            <AddIcon className={classes.plusIcon} />
                            CREATE PERFORMER
                        </Button>
                    )}
                </div>
                {utils.checkItemRolesAndPermissions(items.btns.statusSummaryCmoFilter) && (
                    <StatusSummaryFilters
                        isPerformer={{ isPerformer: true }}
                        onTableReloadCb={getAllPerformers}
                        onClearAllCb={getAllPerformers}
                        customFilterFieldName="performerListFilters"
                    />
                )}
                <PerformerLookup />
                <CommonTableGrid
                    dataList={performersModel.dataFromBE}
                    headerList={headers}
                    loading={performersModel.tableIsLoading}
                    cellDataCb={getSpecificData}
                    preferences={{
                        loadDataByPageCb: {
                            dispatchFn: dispatch,
                            callbackFn: getAllPerformers,
                            argumentsList: [],
                        },
                        onRowClick: {
                            onRowClickCb,
                            rowDataField: 'clientId',
                        },
                    }}
                    paginationBE={true}
                    selection={{
                        isSelectable: false,
                    }}
                />
                <RegStatusNumbersPopUp
                    isOpen={isRegStatNumPopupOpen}
                    closeCb={setIsRegStatNumPopupOpen}
                    anchorEl={anchor}
                    dataItem={currentDataItem}
                />
                <CommonConfirmationDialog
                    isOpen={isDialogOpen}
                    closeCb={setIsDialogOpen}
                    title="Access restricted"
                    description="You do not have rights to open this page. Please contact your administrator."
                    noConfirm={true}
                />
            </div>
        </>
    );
}

export default connect((r) => r)(PerformersList);
