import React from 'react';

import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    paragraph: {
        marginBottom: '20px',
    },
}));

export default function PrivacyPolicyModalContent() {
    const classes = useStyles();
    return (
        <div>
            <Typography className={classes.paragraph} variant="subtitle1">
                FUGA Privacy Policy
            </Typography>
            <Typography className={classes.paragraph} variant="body2">
                FUGA cares about your privacy. In this Privacy Policy, we outline how we collect,
                use, store, share and protect your personal data when you visit our website
                (www.fuga.com, https://downtownnr.com, https://fuganr.fugamusic.com and all
                underlying subpages) (collectively, the “Website”), our premises, or contact our
                Support department. This Privacy Policy applies to all online and offline
                collections of personal data within the European Union (“EU”), the Member States of
                the European Free Trade Association (“Member States of the EFTA”) and the United
                Kingdom (“UK”). If you are located in the United States, and specifically the State
                of California, please refer to our U.S. Privacy Policy for further details on how
                FUGA collects, uses, stores, shares, and protects your personal data. We encourage
                you to read this Privacy Policy in order to understand our approach towards the
                collection and use of your personal data. By using any of our services or visiting
                the Website, you agree that you have read, understood and accepted the content of
                this Privacy Policy. If you do not agree to the collection, use and disclosure of
                your personal data in this way, please do not use our services or otherwise provide
                us with your personal data.
            </Typography>

            <Typography className={classes.paragraph} variant="body2">
                About FUGA
            </Typography>
            <Typography className={classes.paragraph} variant="body2">
                FUGA is the tradename of Independent IP B.V., and any reference to FUGA in this
                Privacy Policy includes Independent IP B.V. and its wholly owned subsidiaries.
                However, this Privacy Policy does not apply to the activities carried out by FUGA’s
                subsidiary Songspace LLC, Delaware USA. Please visit https://songspace.com/privacy
                for the most recent version of the Songspace Privacy Policy.
            </Typography>
            <Typography className={classes.paragraph} variant="body2">
                FUGA may be the data controller for personal data collected in connection with
                FUGA’s services in the EU, the Member States of the EFTA, the UK and elsewhere, and
                therefore determines the purpose and means for the processing of such personal data.
                In other instances, FUGA may be the data processor for personal data and in such
                case adheres to the guidance set by the data controller.
            </Typography>

            <Typography className={classes.paragraph} variant="body2">
                Contact Information
            </Typography>
            <Typography className={classes.paragraph} variant="body2">
                FUGA is part of the Downtown Music Publishing Group and is based at:
            </Typography>
            <Typography className={classes.paragraph} variant="body2">
                Prinsengracht 583-IV,
            </Typography>
            <Typography className={classes.paragraph} variant="body2">
                1016 HT Amsterdam, the Netherlands
            </Typography>
            <Typography className={classes.paragraph} variant="body2">
                For all correspondence concerning privacy-related matters, please contact FUGA by
                email at privacy@fuga.com.
            </Typography>

            <Typography className={classes.paragraph} variant="body2">
                The data we collect
            </Typography>
            <Typography className={classes.paragraph} variant="body2">
                The terms “personal data” as used in this privacy policy covers all information
                about an identified or identifiable natural person, in particular for instance a
                name, an online identifier or one or more elements that are characteristic for the
                physical, physiological, genetic, psychological, economic, cultural or social
                identity of that natural person as further defined in the General Data Protection
                Regulation (“GDPR”). FUGA collects various types of personal data including the
                following listed below (if applicable).
            </Typography>
            <ul>
                <li>
                    <Typography variant="body2">
                        Data provided by you. This includes for instance the data you enter on our
                        website or platform or provide to us in any other way, for instance by
                        filling out the contact form on our website, applying for a job through our
                        website, setting up your FUGA account or completing a survey.
                    </Typography>
                </li>
                <li>
                    <Typography variant="body2">
                        Data created during the use of our Services. This includes among others the
                        metadata, being any information including artists’ names and label
                        information relating to an audio or audio-visual asset uploaded by a user of
                        the FUGA platform or company information in addition to the information in
                        your FUGA account.
                    </Typography>
                </li>
                <li>
                    <Typography variant="body2">
                        Data after consent. Only after your express prior written consent and by
                        opting-in to for instance a digital marketing service, FUGA may collect –
                        either via a third party – browser, device information, IP-derived location,
                        email address, information on purchases and music previews and additional
                        data from third party music and social media services.
                    </Typography>
                </li>
                <li>
                    <Typography variant="body2">
                        Automatically collected info. FUGA may collect your IP address when you
                        visit the FUGA website. This collecting is done through cookies (see below).
                    </Typography>
                </li>
                <li>
                    <Typography variant="body2">
                        Data from other sources. Most of the personal data that FUGA collects about
                        you is information that you provided voluntarily, for example through our
                        website. Other sources through which your personal data can be obtained are
                        (i) other companies that form part of the FUGA group, and (ii) third parties
                        (such as law enforcement / regulatory bodies, credit rating agencies etc) or
                        (iii) publicly available source data.
                    </Typography>
                </li>
            </ul>
            <Typography className={classes.paragraph} variant="body2">
                Children’s Privacy
            </Typography>
            <Typography className={classes.paragraph} variant="body2">
                This Website is intended for adults. Please do not use our services if you are under
                the age of sixteen (16). We do not knowingly collect personal data from anyone under
                the age of 16 without parental consent in accordance with applicable law. If you are
                a parent or guardian and you are aware that your child has violated this Privacy
                Policy and provided us with personal data, please contact us at privacy@fuga.com. If
                we become aware that we have collected personal information from children without
                verification of parental consent, we will take steps to remove that information from
                our servers.
            </Typography>
            <Typography className={classes.paragraph} variant="body2">
                Use of the data collected.
            </Typography>
            <Typography className={classes.paragraph} variant="body2">
                Your personal data is collected and processed in accordance with this privacy policy
                and, if applicable, the agreement entered into by and between you and FUGA. Your
                personal data is collected for the purposes listed below.
            </Typography>
            <ul>
                <li>
                    <Typography variant="body2">
                        Providing Services. At times, we may require you to provide certain
                        information, including personal data, in order for you to use certain parts
                        of our Websites, fulfill your requests, respond to your inquiries, and
                        provide our services to you. Examples of the personal data we collect may
                        include, your metadata and contact history, in order to deliver the services
                        to you or to process your question.
                    </Typography>
                </li>
                <li>
                    <Typography variant="body2">
                        Marketing. If you register for newsletters, use the Website, opt-in to third
                        party marketing services, or provide feedback, FUGA may collect your
                        personal data to create a profile necessary to maintain proper information
                        about you and your preferences. Your profile will be created in accordance
                        with your preferences as communicated by you to FUGA.
                    </Typography>
                </li>
                <li>
                    <Typography variant="body2">
                        Re-targeting: websites from FUGA or identified third parties can use
                        techniques for retargeting, with the aim of creating, for example, Facebook
                        Audience or relevant advertisements on partner websites. This will only been
                        done on the basis of your explicit consent.
                    </Typography>
                </li>
                <li>
                    <Typography variant="body2">
                        Advertisements: FUGA may collaborate with external parties that use tracking
                        techniques to allow FUGA customers to provide advertising, contact you by
                        either email or messages, and other communications tailored to your
                        interest. This will only been done on the basis of your explicit consent.
                    </Typography>
                </li>
                <li>
                    <Typography variant="body2">
                        Improvement of our Services. Analytics: The information we collect from you
                        is used to help FUGA understand how users use and move across the Website
                        and services. Your personal data and feedback provided to us either through
                        a survey or otherwise, are processed to create a profile of you with the aim
                        of understanding how you use the services, enabling FUGA to develop better
                        and more relevant services and improve the services and Website(s). Your
                        personal data is furthermore used by FUGA to create informative analytics
                        based on your usage of the platform.
                    </Typography>
                </li>
                <li>
                    <Typography variant="body2">
                        Reaching out to you: If you provide feedback, send a request to FUGA using
                        the contact form on our Website(s), or submit a job application to FUGA in a
                        manner other than through our third party recruitment system such as
                        Recruitee or Greenhouse, FUGA collects and processes the information you
                        provide to respond to your inquiry, feedback, or job inquiry.
                    </Typography>
                </li>
            </ul>

            <Typography className={classes.paragraph} variant="body2">
                Disclosure to Third Parties.
            </Typography>
            <Typography className={classes.paragraph} variant="body2">
                FUGA keeps your personal data confidential and does not share the personal data with
                any third party other than as provided below.
            </Typography>
            <Typography className={classes.paragraph} variant="body2">
                Inter-Company
            </Typography>
            <Typography className={classes.paragraph} variant="body2">
                FUGA may share your personal data, including but not limited to your name, e-mail
                address or metadata, with any other FUGA group entity to provide your services in
                accordance with this privacy policy or the agreement between you and FUGA, if
                applicable. Each FUGA entity involved in the processing of your personal data is
                considered a processor under the relevant legislation.
            </Typography>
            <Typography className={classes.paragraph} variant="body2">
                Suppliers / providers
            </Typography>
            <Typography className={classes.paragraph} variant="body2">
                FUGA may share your personal data with any third-party company providing technical
                services to FUGA in order for FUGA to execute its agreement with you, if applicable,
                or to provide its services. FUGA may also share your personal data, including your
                name and metadata, with digital service providers or performing rights organizations
                for the sole purpose of fulfilling our contractual obligations with you.
            </Typography>
            <Typography className={classes.paragraph} variant="body2">
                Marketing Services – FUGA Clients
            </Typography>
            <Typography className={classes.paragraph} variant="body2">
                Only after opting-in to any third-party marketing services we may offer on behalf of
                our clients, we may disclose your personal data (limited to name, email address and
                contact details) as provided by you to our clients using those services. For
                example, this information may be used to contact you after you enter a competition,
                to send you newsletters to update you about your favorite artists.
            </Typography>
            <Typography className={classes.paragraph} variant="body2">
                Supervising authorities / judicial bodies
            </Typography>
            <Typography className={classes.paragraph} variant="body2">
                Upon a duly request by a supervising authority and as required by law or upon a
                court decision, FUGA will release your personal data to such an authority to the
                extent it is required.
            </Typography>
            <Typography className={classes.paragraph} variant="body2">
                Cookies
            </Typography>
            <Typography className={classes.paragraph} variant="body2">
                We may use cookies and similar tracking technologies to track the activity on our
                Website and services. Cookies are data files with a small amount of data that are
                placed on your computer or device when you visit the Website. Cookies then send
                information back to the originating website on each subsequent visit, or to another
                website that recognizes the same cookie. Cookies may be used to allow the Website
                server to identify your browser whenever you interact with them in order for the
                Website to identify you. FUGA may also use cookies to improve our services and the
                quality of your experience while visiting the Website.{' '}
            </Typography>

            <Typography className={classes.paragraph} variant="body2">
                The Website may use the following types of cookies:
            </Typography>
            <ul>
                <li>
                    <Typography variant="body2">
                        Mandatory Cookies: cookies required to activate the core functionality of
                        the website. These cookies are used to process information that helps us
                        secure the Website and activate its functionality and cannot be disabled;
                    </Typography>
                </li>
                <li>
                    <Typography variant="body2">
                        Functional and Analytical Cookies: cookies used to analyze activities on the
                        Website and services in order to improve and optimize the way our Website
                        and services work. An example of these cookies is cookies that track Website
                        usage;
                    </Typography>
                </li>
            </ul>
            <Typography className={classes.paragraph} variant="body2">
                Advertising and Social Media Cookies: cookies to support marketing efforts through
                advertising or marketing on third-party site. These cookies are only dropped once
                you provide your explicit Cookie consent.
            </Typography>
            <Typography className={classes.paragraph} variant="body2">
                To learn more about the cookies FUGA utilizes, please visit our Cookie List.
            </Typography>

            <Typography className={classes.paragraph} variant="body2">
                Your Cookie Choices. You are in control as to which cookies and other tracking
                technologies are collected by your browser or device. You may set your browser to
                block or refuse all cookies, to indicate when a cookie is being sent, or otherwise
                control what cookie your browser or mobile device accepts via your browser or mobile
                device settings. Additionally, you can choose to modify your settings and delete
                those cookies that are otherwise stored on your device at any time.
            </Typography>

            <Typography className={classes.paragraph} variant="body2">
                Please read the instructions provided by your browser or mobile device manufacturer
                to find out how you can control the placement of and/or remove cookies or other
                technologies. The “help” section in your browser provides more information about how
                to manage your cookie settings. Please note, however, that limiting or disabling the
                use of cookies and other technologies may impact your ability to perform certain
                actions on, use certain functions of, and access certain content on our Websites.
            </Typography>
            <Typography className={classes.paragraph} variant="subtitle2">
                How we secure your data.
            </Typography>
            <Typography className={classes.paragraph} variant="body2">
                We employ commercially reasonable security measures and practices to protect your
                personal data from loss, misuse, unauthorized access, disclosure, alteration, and
                destruction reflective of the type of personal data and the risks associated with
                our processing of the information. We use passwords, security questions,
                multi-factor authentication, and other appropriate security measures to prevent
                unauthorized access to your personal data.
            </Typography>
            <Typography className={classes.paragraph} variant="body2">
                Data processing outside of the EU, Member States of the EFTA and the United Kingdom.
            </Typography>
            <Typography className={classes.paragraph} variant="body2">
                EU/EEA data subjects: Your personal data will be stored and processed within the EU
                unless otherwise stated.{' '}
            </Typography>
            <Typography className={classes.paragraph} variant="body2">
                UK data subjects: your personal data will be stored and processed within the EU
                unless otherwise stated.
            </Typography>
            <Typography className={classes.paragraph} variant="body2">
                Occasionally, your personal data may be transferred to, and maintained on, computers
                located outside the EU, a Member State of EFTA or the UK – for instance if a third
                party or supplier is located in a different jurisdiction. However, FUGA will take
                the necessary steps in such cases to ensure that your personal data is adequately
                protected, such as putting in place a processor agreement or contracting with
                suppliers who conform to the most recent data protection ISO requirements and
                therefore have put in place technical and organizational measures comparable to
                FUGA’s.{' '}
            </Typography>
            <Typography className={classes.paragraph} variant="body2">
                If your personal data is transferred to other countries, we will employ appropriate
                safeguards to ensure the lawfulness and security of the transfer. For example, all
                transfers of personal data to our offices outside of the EU and the EFTA are based
                on the European Commission’s standard contractual clauses, where the transfer is
                made to a third country which has not been granted an “adequacy decision” by the
                European Commission.
            </Typography>

            <Typography className={classes.paragraph} variant="subtitle2">
                Your data protection and privacy rights
            </Typography>
            <Typography className={classes.paragraph} variant="body2">
                FUGA fully complies with the obligations laid down in the relevant data protection
                laws, including the GDPR the UK’s Data Protection Act of 2018, and has implemented
                various means to ensure that you can exercise your rights under these laws.
            </Typography>
            <Typography className={classes.paragraph} variant="body2">
                Regarding your use of the FUGA platform, you can access the personal data submitted
                by you on your FUGA account and any metadata FUGA stores about you through the FUGA
                platform. If you are unable to access your personal data through your FUGA account
                or the platform, please contact FUGA at privacy@fuga.com.
            </Typography>
            <Typography className={classes.paragraph} variant="body2">
                Residents of countries within the European Union (“EU”) / European Economic Area
                (“EEA”) and the United Kingdom (“UK”).{' '}
            </Typography>
            <Typography className={classes.paragraph} variant="body2">
                FUGA takes reasonable steps to allow you to correct, amend, delete or limit the use
                of your personal data, and exercise other rights available under applicable law.
                FUGA therefore informs you that, where applicable under relevant law, you are
                entitled to the following:
            </Typography>
            <ul>
                <li>
                    <Typography variant="body2">
                        Right to Access / Disclosure: The right to have access to your personal data
                        upon simple request – that is, you may receive a copy of such data upon
                        receipt of a verifiable request, along with other information related to the
                        processing.
                    </Typography>
                </li>
                <li>
                    <Typography variant="body2">
                        Right to Correction/Rectification: The right to correct your personal data
                        if you find it is inaccurate, incomplete or obsolete.
                    </Typography>
                </li>
                <li>
                    <Typography variant="body2">
                        Right to Erasure / “Right to be Forgotten”: The right to obtain the deletion
                        of your personal data in the situations set forth by applicable data
                        protection law.
                    </Typography>
                </li>
                <li>
                    <Typography variant="body2">
                        Withdrawal of Consent to Processing: The right to withdraw your consent to
                        the data processing without affecting the lawfulness of processing, where
                        your personal data has been collected and processed based on your consent
                        and not any other basis.
                    </Typography>
                </li>
                <li>
                    <Typography variant="body2">
                        Right to Object: The right to object to the processing of your personal data
                        under certain circumstances, in which case we may ask you to justify your
                        request by explaining to us your particular situation.
                    </Typography>
                </li>
                <li>
                    <Typography variant="body2">
                        Right to Restrict Processing: The right to request limits to the processing
                        of your data, when allowed by and in circumstances set forth under
                        applicable law.
                    </Typography>
                </li>
                <li>
                    <Typography variant="body2">
                        Right to Restrict Automated Individual Decision-Making (Profiling): The
                        right to not be subject to a decision based solely on automated processing,
                        including profiling, that produces legal effects concerning you.
                    </Typography>
                </li>
                <li>
                    <Typography variant="body2">
                        Right to Data Portability: The right to have your personal data directly
                        transferred by us to a third-party processor of your choice (where
                        technically feasible; may be limited to situations when processing is based
                        on your consent).
                    </Typography>
                </li>
            </ul>
            <Typography className={classes.paragraph} variant="body2">
                To exercise any of these rights, please contact FUGA at privacy@fuga.com. Note that
                we may ask you to verify your identity before responding to such requests.
            </Typography>
            <Typography className={classes.paragraph} variant="subtitle2">
                Links to Other Sites.
            </Typography>
            <Typography className={classes.paragraph} variant="body2">
                Our Privacy Policy is designed to advise you about how we collect, use, protect, and
                disclose the information that we collect and/or receive about you. However, our
                Website may contain links to other sites that are not operated by us. Please note
                that this Privacy Policy does not govern the practices of third parties, including
                our partners, third party service providers, and/or advertisers, even when those
                services are branded as, or provided on behalf of, FUGA. Information collected from
                you by others, such as third party websites that you access through links on the
                Websites, are governed by those entities’ privacy policies. If you click a third
                party link, you will be directed to that third party’s site. We strongly advise you
                to review the Privacy Policy of every site you visit.
            </Typography>
            <Typography className={classes.paragraph} variant="body2">
                We have no control over and assume no responsibility for the content, privacy
                policies or practices of any third party sites or services.
            </Typography>
            <Typography className={classes.paragraph} variant="subtitle2">
                Changes to this policy.
            </Typography>
            <Typography className={classes.paragraph} variant="body2">
                FUGA will regularly update this privacy policy to keep it up to date. If any major
                changes are made to this privacy policy, you will be informed via our Website.
            </Typography>
            <Typography className={classes.paragraph} variant="body2">
                If you have any complaints concerning this privacy policy, please contact us via the
                Website, the email address included in the “Contact Information” section above, or
                file a complaint with your supervisory authority. FUGA is located in the Netherlands
                and as such, is subject to the Dutch Data Protection Authority.
            </Typography>

            <Typography className={classes.paragraph} variant="subtitle2">
                Version: November 2022
            </Typography>
        </div>
    );
}
