import React from 'react';

import { resolveAlertMsgByAlertId, updateAlertMessage } from '@actions/assetsClaimEvent';
import { createAlertMsgByAssetClaimEventIdAPI } from '@api/assetsClaimEventController';
import { manualAlertsItemsList } from '@pages/AssetsCmoPage/common/common';
import { useDispatch, useSelector } from 'react-redux';

import AlertsModal from './AlertsModal';

export default function CmoAssetRegistrationManualAlertsModal({
    isOpen,
    setOpen,
    dataItem,
    pageModelName,
    getAssetData,
}) {
    const dispatch = useDispatch();
    const assetRegistrationModel = useSelector((state) => state.fugaReducers[pageModelName]);

    const onClose = () => setOpen(false);

    const onUpdateAlert = (alertData, assetClaimId) => {
        dispatch(updateAlertMessage(alertData, assetClaimId, pageModelName));
    };

    const onResolveAlert = (alertId, assetClaimId) => {
        dispatch(resolveAlertMsgByAlertId(alertId, assetClaimId, pageModelName));
    };

    const onCreateAlert = async (alertData) => {
        await createAlertMsgByAssetClaimEventIdAPI(alertData);
    };

    return (
        <AlertsModal
            isOpen={isOpen}
            onClose={onClose}
            dataItem={dataItem}
            alerts={assetRegistrationModel.alertMsgs}
            onUpdateAlert={onUpdateAlert}
            onResolveAlert={onResolveAlert}
            onCreateAlert={onCreateAlert}
            menuItemsList={manualAlertsItemsList.slice(0, manualAlertsItemsList.length - 1)}
            getAlertsData={getAssetData}
            dataItemIdKey="assetClaimId"
        />
    );
}
