import React, { useState } from 'react';

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Popover, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

const HelpIcon = styled(InfoOutlinedIcon)(({ theme }) => ({
    width: '20px',
    height: '20px',
    display: 'flex',
    marginLeft: theme.spacing(1),
    cursor: 'pointer',
}));

const useClasses = makeStyles(() => ({
    infoPopoverRows: {
        paddingBottom: '8px',
        paddingTop: '2px',
    },
    infoPopoverContainer: {
        '& .MuiPopover-paper': {
            width: '444px',
            padding: '16px 16px',
            boxSizing: 'content-box',
            marginTop: '7px',
        },
    },
}));

export default function ClientExternalUserAccessContent() {
    const [anchorEl, setAnchorEl] = useState(null);
    const openInfo = Boolean(anchorEl);
    const classes = useClasses();
    const handleInfoClick = ({ currentTarget }) => {
        setAnchorEl(currentTarget);
    };
    const handleInfoClose = () => {
        setAnchorEl(null);
    };

    return (
        <div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant="caption">External user access</Typography>
                <div onClick={handleInfoClick}>
                    <HelpIcon />
                </div>
            </div>
            <Popover
                open={openInfo}
                anchorEl={anchorEl}
                onClose={handleInfoClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                className={classes.infoPopoverContainer}
            >
                <Typography variant="body2" className={classes.infoPopoverRows}>
                    Warning: When selected the feature will be available for all external users
                    associated with this client
                </Typography>
            </Popover>
        </div>
    );
}
