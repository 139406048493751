import React, { useEffect, useState } from 'react';

import {
    agreeTermsAndConditions,
    cleanLoginMessages,
    login,
    resetPassword,
    updatePassword,
} from '@actions/authActions';
import { changeStateByKey } from '@actions/fugaActions';
import InfoMessage from '@common/InfoMessage';
import brandedTitle from '@images/branding/brandedtitle.png';
import dwtLoginImage from '@images/branding/dt_nr_login_image.jpg';
import Grid from '@mui/material/Grid2';
import { makeStyles } from '@mui/styles';
import LoginForm from '@pages/LoginPage/LoginForm';
import ResetPasswordForm from '@pages/LoginPage/ResetPasswordForm';
import UpdatePasswordForm from '@pages/LoginPage/UpdatePasswordForm';
import cerberusUtils from '@utils/cerberusUtils';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import MarketingModule from './MarketingModule';
import TermsAndConditions from './TermsAndConditions';

const useBrandedTitleStyles = makeStyles(() => ({
    container: {
        marginBottom: 65,
    },
    logo: {
        width: 250,
        height: 'auto',
        display: 'block',
        marginLeft: 'auto',
        marginRight: 'auto',
    },
}));
/*
Form, Terms, Forget Password action. Making This working according with design real estate
Wire up the functionality
Make use of Typography
*/
export const LoginSteps = {
    login: 'login',
    resetPassword: 'resetPassword',
    updatePassword: 'updatePassword',
};

const BrandedTitle = () => {
    const classes = useBrandedTitleStyles();

    return (
        <div className={classes.container}>
            <img className={classes.logo} src={brandedTitle} />
        </div>
    );
};

const LoginSection = ({ step, onLogin, onResetPassword, onUpdatePassword }) => {
    //which step are we in
    const isLogin = step === LoginSteps.login;
    const isResetPassword = step === LoginSteps.resetPassword;
    const isUpdatePassword = step === LoginSteps.updatePassword;

    return (
        <div style={{ width: '280px' }}>
            <BrandedTitle />
            {isLogin && <LoginForm onLogin={onLogin} />}
            {isResetPassword && <ResetPasswordForm onResetPassword={onResetPassword} />}
            {isUpdatePassword && <UpdatePasswordForm onUpdatePassword={onUpdatePassword} />}
        </div>
    );
};

const LoginLandingPage = ({ step }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const updatedPassword = useSelector((state) => state.fugaReducers.updatedPassword);
    const submitTermsAndConditions = useSelector(
        (state) => state.fugaReducers.submitTermsAndConditions,
    );
    const [termsAndConditionsOpen, setTermsAndCondtionsOpen] = useState(false);

    useEffect(() => {
        dispatch(cleanLoginMessages());
    }, [step]);

    useEffect(() => {
        if (updatedPassword) {
            // Keeping the state intact for the message.
            history.push('/login');
            dispatch(changeStateByKey('updatedPassword', false));
            toast.success('Password successfully updated', {
                autoClose: 5000,
            });
        }
        if (submitTermsAndConditions) {
            setTermsAndCondtionsOpen(true);
        } else {
            setTermsAndCondtionsOpen(false);
        }
    }, [updatedPassword, submitTermsAndConditions]);

    function onLogin(username, password) {
        dispatch(changeStateByKey('loginErrorMsg', ''));
        dispatch(
            login({
                username,
                password,
            }),
        );
    }

    function onResetPassword(username) {
        dispatch(
            resetPassword({
                username,
            }),
        );
    }

    function onUpdatePassword(password, id, key) {
        dispatch(
            updatePassword({
                password,
                id,
                key,
            }),
        );
    }

    function onCancelTermsAndConditions() {
        dispatch(changeStateByKey('submitTermsAndConditions', false));
        cerberusUtils.setUserRole('');
        cerberusUtils.setCerberusToken('');
    }

    function onAcceptOnTermsAndConditions() {
        dispatch(agreeTermsAndConditions());
        dispatch(changeStateByKey('submitTermsAndConditions', false));
    }

    return (
        <>
            <Grid style={{ flexWrap: 'nowrap' }} container>
                <Grid
                    style={{ backgroundColor: '#E1DFD6' }}
                    container
                    size={{
                        md: 4,
                        sm: 6,
                        xs: 12,
                    }}
                    sx={{
                        alignContent: 'center',
                        justifyContent: 'center',
                        alignItems: 'flex-start',
                    }}
                >
                    <LoginSection
                        step={step}
                        onLogin={onLogin}
                        onResetPassword={onResetPassword}
                        onUpdatePassword={onUpdatePassword}
                    />
                </Grid>
                <Grid
                    size={{
                        md: 8,
                        sm: 6,
                        xs: 12,
                    }}
                >
                    <MarketingModule image={dwtLoginImage} />
                </Grid>
            </Grid>
            <InfoMessage />
            {termsAndConditionsOpen && (
                <TermsAndConditions
                    onCancel={onCancelTermsAndConditions}
                    onAccept={onAcceptOnTermsAndConditions}
                />
            )}
        </>
    );
};

export default LoginLandingPage;
