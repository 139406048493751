import React, { useEffect, useState } from 'react';

import {
    createClientDeal,
    createClientDealPerfOrRH,
    editClientDealPerfOrRH,
} from '@actions/territories';
import DatesAndClaimMethods from '@common/modals/Territories/DatesAndClaimMethods';
import RegionItem from '@common/modals/Territories/RegionItem';
import {
    Button,
    Dialog,
    Checkbox,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    Typography,
    Divider,
    Alert,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { format, parseISO, isAfter, isEqual } from 'date-fns';
import { connect, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

const useStyles = makeStyles((theme) => ({
    root: {
        margin: 0,
    },
    collapsed: {
        transform: 'rotate(180deg)',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expanded: {
        transform: 'rotate(0deg)',
    },
    listItem: {
        borderBottom: '1px solid #DEDEE0',
    },
    checkboxes: {
        padding: '0 8px 0 0',
    },
    alertText: {
        '& .MuiAlert-message': {
            textAlign: 'left',
        },
    },
}));

const TerritoriesModal = ({
    entityType,
    clientId,
    showTerritoryModal,
    setShowTerritoryModal,
    territories,
    setTerritories,
    groupedTerritories,
    isEditing,
    territoriesPropModel,
}) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [claimMethod, setClaimMethod] = useState(
        entityType === 'performer' ? 'agent' : 'exclusive_license',
    );
    const [selectedStartDate, handleStartDateChange] = useState(null);
    const [selectedEndDate, handleEndDateChange] = useState(null);
    const [showRenewalUpdate, setShowRenewalUpdate] = useState(false);

    useEffect(() => {
        if (Object.keys(territoriesPropModel.editableDealInfo).length > 0) {
            handleStartDateChange(
                territoriesPropModel.editableDealInfo.startDate
                    ? parseISO(territoriesPropModel.editableDealInfo.startDate)
                    : null,
            );
            handleEndDateChange(
                territoriesPropModel.editableDealInfo.endDate
                    ? parseISO(territoriesPropModel.editableDealInfo.endDate)
                    : null,
            );
            setClaimMethod(
                territoriesPropModel.editableDealInfo.claimMethod.toLowerCase() === 'agent'
                    ? 'agent'
                    : 'exclusive_license',
            );
        }
    }, [
        territoriesPropModel.editableDealInfo.startDate,
        territoriesPropModel.editableDealInfo.endDate,
    ]);

    useEffect(() => {
        if (!isEditing) {
            setTerritories({});
            setClaimMethod(entityType === 'performer' ? 'agent' : 'exclusive_license');
        }
    }, [isEditing, entityType]);

    const handleClose = () => {
        setShowTerritoryModal(false);
        setShowRenewalUpdate(false);
    };

    const handleSave = () => {
        if (
            selectedStartDate &&
            selectedEndDate &&
            (isAfter(selectedStartDate, selectedEndDate) ||
                isEqual(selectedStartDate, selectedEndDate))
        ) {
            toast.error('End date cannot be before or equal to start date');
            return;
        }
        if (!(selectedStartDate && selectedEndDate)) {
            toast.error('Please fill in all the fields');
            return;
        }

        const allTerritoriesCount = groupedTerritories.reduce(
            (sum, group) => sum + group.territories.length,
            0,
        );
        const selectedTerritories = Object.values(territories).flat().join();
        const dealTerritoriesContent =
            selectedTerritories.split(',').length === allTerritoriesCount
                ? 'WW'
                : selectedTerritories;

        const objToSave = {
            ...(!isEditing && { claimMethod: claimMethod.toUpperCase() }),
            ...(!isEditing
                ? { clientId: parseInt(clientId) }
                : { clientDealId: parseInt(territoriesPropModel.editableDealInfo.id) }),
            clientId: parseInt(clientId),
            ...(!isEditing
                ? { dealTerritories: dealTerritoriesContent }
                : { territories: dealTerritoriesContent }),
            ...(!isEditing && {
                startDate: selectedStartDate ? format(selectedStartDate, 'yyyy-MM-dd') : null,
            }),
            endDate: format(selectedEndDate, 'yyyy-MM-dd'),
        };

        handleClose();
        if (isEditing) {
            dispatch(
                editClientDealPerfOrRH(objToSave, entityType === 'performer' ? undefined : false),
            );
        } else {
            if (entityType === 'performer') {
                dispatch(createClientDealPerfOrRH(objToSave));
            } else {
                dispatch(createClientDeal(objToSave));
            }
        }
    };

    const setAllTerritories = () => {
        let newTerritories = {};
        groupedTerritories.forEach((territoryGroup) => {
            newTerritories[territoryGroup.region.id] = territoryGroup.territories.map((t) => t.id);
        });
        setTerritories(newTerritories);
    };

    const toggleWorld = () => {
        if (allSelected()) {
            setTerritories({});
        } else {
            setAllTerritories();
        }
    };

    const noneSelected = () => Object.entries(territories).every((item) => item[1].length === 0);

    const allSelected = () => {
        return groupedTerritories.every((gt) => {
            const matchedTerritory = Object.entries(territories).find(
                ([regionId]) => gt.region.id === regionId,
            );
            return matchedTerritory && matchedTerritory[1].length === gt.territories.length;
        });
    };

    const anySelected = () => {
        return (
            Object.values(territories).length > 0 &&
            Object.values(territories).some((territory) => territory.length > 0)
        );
    };

    return (
        <Dialog onClose={handleClose} open={showTerritoryModal} fullWidth maxWidth="md">
            <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                {`${isEditing ? 'Edit' : 'Add'} Deal terms`}
            </DialogTitle>
            <DialogContent dividers style={{ maxHeight: '50%' }}>
                {isEditing && territoriesPropModel.editableDealInfo.locked && (
                    <Alert
                        variant="outlined"
                        severity="warning"
                        color="warning"
                        style={{ marginBottom: '16px' }}
                    >
                        {`Only End Date can be edited as one or more registration actions have already taken place under this deal${
                            entityType === 'performer' ? '' : '.'
                        }`}
                    </Alert>
                )}
                {showRenewalUpdate && (
                    <Alert
                        className={classes.alertText}
                        variant="outlined"
                        severity="warning"
                        color="warning"
                        style={{ marginBottom: '32px' }}
                    >
                        {
                            "Attention - changes to deal 'End Date'. Extensions: any CMO Registrations with the current end date will also be extended. Reductions: any CMO Registrations currently beyond the new date will be reduced to match."
                        }
                    </Alert>
                )}
                <DatesAndClaimMethods
                    claimMethod={claimMethod}
                    setClaimMethod={setClaimMethod}
                    selectedStartDate={selectedStartDate}
                    handleStartDateChange={handleStartDateChange}
                    selectedEndDate={selectedEndDate}
                    handleEndDateChange={handleEndDateChange}
                    isEditing={isEditing}
                    setShowRenewalUpdate={setShowRenewalUpdate}
                    disableExclusiveLicense={entityType === 'performer'}
                    initialStartDate={entityType === 'client' ? new Date() : null}
                />
                <Divider variant="inset" style={{ margin: '41px 0 29px 0' }} />
                <Typography variant="subtitle1" style={{ marginBottom: '27.17px' }}>
                    Territories
                </Typography>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={allSelected()}
                            onChange={toggleWorld}
                            name="World"
                            color="primary"
                            indeterminate={allSelected() ? false : anySelected()}
                            disabled={isEditing && territoriesPropModel.editableDealInfo.locked}
                        />
                    }
                    label="World"
                />
                {groupedTerritories.map((territoryGroup) => (
                    <RegionItem
                        key={territoryGroup.region.id}
                        territorySelection={territoryGroup.territories}
                        territories={territories}
                        setTerritories={setTerritories}
                        region={territoryGroup.region}
                        classes={classes}
                        isDisabled={isEditing && territoriesPropModel.editableDealInfo.locked}
                    />
                ))}
            </DialogContent>
            <DialogActions>
                <Button variant="text" onClick={handleClose} color="primary">
                    Cancel
                </Button>
                <Button
                    disabled={noneSelected()}
                    variant="contained"
                    onClick={handleSave}
                    color="primary"
                >
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default connect((store) => ({
    territoriesPropModel: store.fugaReducers.territories,
}))(TerritoriesModal);
