import React, { useEffect, useState } from 'react';

import { changeStateByNestedKey, loadAllCmos } from '@actions/fugaActions';
import { DoubleRightOutlined } from '@ant-design/icons';
import Grid from '@mui/material/Grid2';
import { makeStyles } from '@mui/styles';
import { Table } from 'antd';
import classNames from 'classnames';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    cmoSearch: {
        marginBottom: theme.spacing(2),
    },
}));

const Types = ({ values = [] }) => {
    return (
        <>
            {values.map((genre, idx) => (
                <span key={genre + idx} className="badge">
                    {genre}
                    <br />
                </span>
            ))}
        </>
    );
};

function CmoListPage() {
    const dispatch = useDispatch();
    const history = useHistory();
    const cmosModel = useSelector((state) => state.fugaReducers.cmos);
    const [searchResults, setSearchResults] = useState([]);
    const [isSearching, setIsSearching] = useState(false);
    const [filterInput, setFilterInput] = useState('');
    const classes = useStyles();

    useEffect(() => {
        dispatch(loadAllCmos());
    }, []);

    const setCmoName = (cmo) => {
        dispatch(changeStateByNestedKey('cmoPage', 'cmoName', cmo.cmoName));
    };

    function renderRedirect(cmo) {
        return (
            <div
                onClick={() => {
                    history.push(`/cmo-page/${cmo.cmoId}/${cmo.cmoType}`);
                    setCmoName(cmo);
                }}
                key={`redirect-${cmo.cmoId}`}
            >
                <DoubleRightOutlined />
            </div>
        );
    }

    const columns = [
        {
            title: 'CMOS',
            key: 'cmos',
            children: [
                {
                    title: 'CMO name',
                    dataIndex: 'cmoName',
                    key: 'cmoName',
                },
                {
                    title: 'Territory Code',
                    dataIndex: 'homeTerritoryCode',
                    key: 'homeTerritoryCode',
                },
                {
                    title: 'Home Territory',
                    dataIndex: 'hometerritoryFullname',
                    key: 'hometerritoryFullname',
                },
                {
                    title: 'CMO Type',
                    dataIndex: 'cmoType',
                    key: 'cmoType',
                },
                {
                    title: 'Supported Claim Methods',
                    dataIndex: 'claimMethods',
                    key: 'claimMethods',
                    render: (text = [], record) => renderTypes(text, record),
                },
                {
                    title: 'Exploitation Types',
                    dataIndex: 'exploitationTypes',
                    key: 'exploitationTypes',
                    render: (text = [], record) => renderTypes(text, record),
                },
                {
                    title: 'Go to CMO',
                    dataIndex: 'gotocmo',
                    key: 'gotocmo',
                    render: (text, record) => renderRedirect(record),
                },
            ],
        },
    ];

    const handleFilterChange = (e) => {
        const value = e.target.value;
        setFilterInput(value);
        if (value) {
            setIsSearching(true);
            const searchedData = cmosModel.dataFromBE.filter((el) => {
                return el.cmoName.toLowerCase().includes(value) || el.cmoName.includes(value);
            });
            setSearchResults(searchedData);
        } else {
            setIsSearching(false);
        }
    };

    const renderTypes = (text) => {
        return <Types values={text} />;
    };

    return (
        <div className="mainComponent">
            <Grid container className={classNames('topContainer', classes.cmoSearch)}>
                <input
                    value={filterInput}
                    onChange={handleFilterChange}
                    placeholder={'Search by cmo name'}
                />
            </Grid>
            <Table
                columns={columns}
                dataSource={(isSearching ? searchResults : cmosModel.dataFromBE).map(
                    (item, index) => ({
                        ...item,
                        key: item.cmoId || index,
                    }),
                )}
                loading={cmosModel.tableIsLoading}
                pagination={false}
            />
        </div>
    );
}

export default connect((r) => r)(CmoListPage);
