import React, { useState, useEffect } from 'react';

import { changeStateByKey, changeStateByNestedKey } from '@actions/fugaActions';
import { CommonDropDownWithInput } from '@common/CommonDropDownWithInput';
import TerritoriesModal from '@common/modals/Territories/TerritoriesModal';
import TextFieldWrapper from '@common/MuiWrappers/TextFieldWrapper';
import AddIcon from '@mui/icons-material/Add';
import { Button, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { connect, useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';

import PerformerDealsTable from './PerformerDealsTable';

const currencyMenuItems = [
    {
        title: 'EUR',
        value: 'EUR',
    },
    {
        title: 'GBP',
        value: 'GBP',
    },
    {
        title: 'USD',
        value: 'USD',
    },
];

const PerformerDealTermsContent = (props) => {
    const dispatch = useDispatch();
    const [isDisabled, setIsDisabled] = useState(true);
    const performersModel = useSelector((state) => state.fugaReducers.performers);
    const territoriesModel = useSelector((state) => state.fugaReducers.territories);
    const [showTerritoryModal, setShowTerritoryModal] = useState(false);
    const [territories, setTerritories] = useState([]);
    const { byClietnId } = performersModel;

    useEffect(() => {
        if (performersModel.isInEditMode || performersModel.isInSaveNewMode) {
            setIsDisabled(false);
        } else {
            setIsDisabled(true);
        }
    }, [performersModel.isInEditMode, performersModel.isInSaveNewMode]);

    useEffect(() => {
        if (props.match.params.id === '-1') {
            setIsDisabled(false);
        }
    }, []);

    useEffect(() => {
        dispatch(
            changeStateByNestedKey('performers', 'mainDetailsInptValues', {
                ...performersModel.mainDetailsInptValues,
                advance:
                    performersModel.isInSaveNewMode && performersModel.selectedIndex < 0
                        ? ''
                        : byClietnId.advance || '',
                rateAfterRec:
                    byClietnId.rateAfterRec + '' !== 'undefined' ? byClietnId.rateAfterRec : '',
                accounting: byClietnId.accountingFrequency || '',
                rateDuringRec:
                    byClietnId.rateDuringRec + '' !== 'undefined' ? byClietnId.rateDuringRec : '',
                postTerm: byClietnId.postTerm || '',
                currency: byClietnId.currency || '',
            }),
        );
    }, [performersModel.byClietnId]);

    const handleInputChange = (event) => {
        dispatch(
            changeStateByNestedKey('performers', 'mainDetailsInptValues', {
                ...performersModel.mainDetailsInptValues,
                [event.target.name]: event.currentTarget.value,
            }),
        );
    };
    const handleCurrencyValueChange = (value) => {
        dispatch(
            changeStateByNestedKey('performers', 'mainDetailsInptValues', {
                ...performersModel.mainDetailsInptValues,
                currency: value,
            }),
        );
    };

    const handleAddDeal = () => {
        if (props.match.params.id === '-1') {
            dispatch(
                changeStateByKey('infoMessage', {
                    isVisible: true,
                    message: 'Please save this Client before adding their Deal terms',
                    severity: 'warning',
                }),
            );
        } else {
            setShowTerritoryModal(true);
        }
    };

    return (
        <>
            <Grid columnSpacing={4} rowSpacing={1} container>
                <Grid size={6}>
                    <TextFieldWrapper
                        label="Advance"
                        onChange={(evt) => handleInputChange(evt)}
                        name="advance"
                        type="text"
                        fullWidth
                        InputProps={{
                            readOnly: isDisabled,
                        }}
                        value={
                            performersModel.mainDetailsInptValues.advance
                                ? performersModel.mainDetailsInptValues.advance
                                : ''
                        }
                        inputProps={{ tabIndex: '16' }}
                    />
                </Grid>
                <Grid size={6}>
                    <TextFieldWrapper
                        inputProps={{ tabIndex: '17' }}
                        label="Rate (during recoupment)"
                        onChange={(evt) => handleInputChange(evt)}
                        name="rateDuringRec"
                        type="text"
                        fullWidth
                        InputProps={{
                            readOnly: isDisabled,
                        }}
                        value={
                            performersModel.mainDetailsInptValues.rateDuringRec
                                ? performersModel.mainDetailsInptValues.rateDuringRec
                                : ''
                        }
                    />
                </Grid>
                <Grid size={6}>
                    <TextFieldWrapper
                        inputProps={{ tabIndex: '18' }}
                        label="Rate (after recoupment)"
                        onChange={(evt) => handleInputChange(evt)}
                        name="rateAfterRec"
                        type="text"
                        InputProps={{
                            readOnly: isDisabled,
                        }}
                        fullWidth
                        value={
                            performersModel.mainDetailsInptValues.rateAfterRec
                                ? performersModel.mainDetailsInptValues.rateAfterRec
                                : ''
                        }
                    />
                </Grid>
                <Grid size={6}>
                    <TextFieldWrapper
                        inputProps={{ tabIndex: '19' }}
                        label="Post Term"
                        onChange={(evt) => handleInputChange(evt)}
                        name="postTerm"
                        fullWidth
                        type="text"
                        InputProps={{
                            readOnly: isDisabled,
                        }}
                        value={
                            performersModel.mainDetailsInptValues.postTerm
                                ? performersModel.mainDetailsInptValues.postTerm
                                : ''
                        }
                    />
                </Grid>
                <Grid size={6}>
                    <TextFieldWrapper
                        inputProps={{ tabIndex: '20' }}
                        label="Accounting Frequency"
                        onChange={(evt) => handleInputChange(evt)}
                        name="accounting"
                        type="text"
                        fullWidth
                        InputProps={{
                            readOnly: isDisabled,
                        }}
                        value={
                            performersModel.mainDetailsInptValues.accounting
                                ? performersModel.mainDetailsInptValues.accounting
                                : ''
                        }
                    />
                </Grid>
                <Grid size={6}>
                    <CommonDropDownWithInput
                        menuItemsList={currencyMenuItems}
                        labelName="Currency"
                        fullWidth
                        preSelectedValue={
                            performersModel.mainDetailsInptValues.currency
                                ? performersModel.mainDetailsInptValues.currency
                                : ''
                        }
                        isDisabled={isDisabled}
                        isEditing={isDisabled}
                        inputProps={{ tabIndex: '4' }}
                        onValueChangeCb={handleCurrencyValueChange}
                    />
                </Grid>
            </Grid>
            <div
                className="flex-horizontal"
                style={{
                    width: '100%',
                    justifyContent: 'space-between',
                    marginBottom: '13.5px',
                }}
            >
                <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
                    Deals
                </Typography>
                <Button variant="outlined" onClick={handleAddDeal}>
                    <AddIcon style={{ margin: '0 10px 0 0' }} />
                    ADD DEAL
                </Button>
            </div>
            <PerformerDealsTable />
            <TerritoriesModal
                entityType="performer"
                showTerritoryModal={showTerritoryModal}
                setShowTerritoryModal={setShowTerritoryModal}
                territories={territories}
                setTerritories={setTerritories}
                groupedTerritories={territoriesModel.groupedTerritories}
                clientId={props.match.params.id}
                isEditing={false}
            />
        </>
    );
};

const withUrlPerformerDealTermsContent = withRouter(PerformerDealTermsContent);
export default connect((r) => r)(withUrlPerformerDealTermsContent);
