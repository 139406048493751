import React, { useCallback, useEffect, useState } from 'react';

import {
    getAlertMsgByCmoRegId,
    getCmoConnectionsByClientId,
    updateNestedField,
} from '@actions/apiActions';
import { changeStateByNestedKey } from '@actions/fugaActions';
import { formatTerritoryCodes, getAllTerritories } from '@actions/territories';
import { changeRegistrationStatusAPI, deleteCmoRegistrationAPI } from '@api/cmoController';
import CommonConfirmationDialog from '@common/CommonConfirmationDialog';
import CommonDropDownChips, {
    ChipLabel,
    STATUSES_LIST,
    chipThemes,
} from '@common/CommonDropDownChips';
import CommonTableGrid from '@common/CommonTable/CommonTableGrid';
import CmoRegAlertsModal from '@common/modals/CmoRegAlertsModal';
import CmoRegCommentsModal from '@common/modals/CmoRegCommentsModal';
import NewCmoConnectionModal from '@common/modals/NewCmoConnectionModal/NewCmoConnectionModal';
import StatusHistoryModal from '@common/modals/StatusHistoryModal';
import MoreDropDownButton from '@common/MoreDropDownButton';
import OverflowTip from '@common/OverflowTip';
import ChatBubbleUnread from '@images/svgComponents/ChatBubbleUnreadSvg';
import InfoIconSmallBlueSvg from '@images/svgComponents/InfoIconSmallBlueSvg';
import PendingIconSvg from '@images/svgComponents/PendingIconSvg';
import WarningOrangeSvg from '@images/svgComponents/WarningOrangeSvg';
import {
    ChatBubbleOutline,
    Close,
    Done,
    ErrorOutline,
    ScheduleOutlined,
} from '@mui/icons-material';
import { IconButton, Tooltip } from '@mui/material';
import { makeStyles } from '@mui/styles';
import moment from 'moment';
import { connect, useSelector, useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';

const useClasses = makeStyles({
    statusIcons: {
        width: '18px',
        height: '18px',
        marginRight: '21.25px',
    },
    flexHorizontal_s_b: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    activeIcon: {
        color: '#00A542',
    },
    inactiveIcon: {
        color: '#5F5F60',
    },
    pendingIcon: {
        color: '#FF8800',
    },
    statusIconBtns: {
        padding: '8px',
        '&:hover': {
            backgroundColor: 'unset',
        },
    },
    displayFlex: {
        display: 'flex',
    },
    alertIcons: {
        marginLeft: '4px',
    },
});
// All the commented lines are to reuse old functionality (endpoints cor CRA for cmo manipulation and retrieve NR-973, NR-985) in case if we need revert

function CmoConnectionTable(props) {
    const classes = useClasses();
    const dispatch = useDispatch();
    const cmoConnectionModel = useSelector((state) => state.fugaReducers.cmoConnection);
    const performersModel = useSelector((state) => state.fugaReducers.performers);
    // Commented lines in this file are to disable selction option as it is currently not used
    // const selectedCmos = useRef([]);
    // const setSelectedCmos = (newValue) => {
    //     selectedCmos.current = newValue;
    // };
    // const excludedCmos = useRef([]);
    // const setExcludedCmos = (newValue) => {
    //     excludedCmos.current = newValue;
    // };
    const [showTerritoryModal, setShowTerritoryModal] = useState(false);
    const [territories, setTerritories] = useState([]);
    const territoriesModel = useSelector((state) => state.fugaReducers.territories);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [isStatusDialogOpen, setIsStatusDialogOpen] = useState(false);
    const [cmoRegId, setCmoRegId] = useState(null);
    const [statusChangeOptions, setStatusChangeOptions] = useState([]);
    const [anchorEl, setAnchorEl] = useState(false);
    const [openCommentsModal, setOpenCommentsModal] = useState(false);
    const [openAlertsModal, setOpenAlertsModal] = useState(false);
    const [clickedDataItem, setClickedDataItem] = useState({});

    useEffect(() => {
        if (!cmoConnectionModel.inUpdateMode)
            dispatch(getCmoConnectionsByClientId(props.match.params.id));
    }, []);

    useEffect(() => {
        dispatch(getAllTerritories());
        setTerritories(territoriesModel.checkedTerritories);
    }, [territoriesModel.checkedTerritories]);

    const openModal = () => {
        setShowTerritoryModal(true);
    };

    const headers = [
        {
            title: 'CMO',
            dataIndex: 'cmoName',
        },
        {
            title: 'Territories',
            dataIndex: 'territoriesToShow',
        },
        {
            title: 'Start Date',
            dataIndex: 'startDate',
        },
        {
            title: 'End Date',
            dataIndex: 'endDate',
        },
        {
            title: 'Registration Status',
            dataIndex: 'registrationStatus',
            customStyle: {
                minWidth: 'unset',
                maxWidth: 'unset',
            },
        },
        {
            title: 'Last Modified',
            dataIndex: 'statusDate',
        },
        {
            title: '',
            dataIndex: 'commentPresented',
            hasIcon: true,
        },
    ];

    const deleteConnection = async () => {
        await deleteCmoRegistrationAPI(cmoRegId);
        await dispatch(getCmoConnectionsByClientId(props.match.params.id));
    };

    const handleHistoryClick = (evt, dataItem) => {
        dispatch(changeStateByNestedKey('performers', 'selectedRowDataItem', dataItem));
        setAnchorEl(evt.currentTarget);
    };

    const handleCommentsClick = (e, dataItem) => {
        setClickedDataItem(dataItem);
        setOpenCommentsModal(true);
    };

    const handleAlertClick = async (dataItem) => {
        setClickedDataItem(dataItem);
        await dispatch(getAlertMsgByCmoRegId(dataItem.cmoRegId));
        setOpenAlertsModal(true);
    };

    const openHistoryInfo = Boolean(anchorEl);

    const handleHistoryClose = () => {
        dispatch(changeStateByNestedKey('performers', 'registrationHistory', []));
        setAnchorEl(null);
    };

    const updateStatusInRow = (response) => {
        const dataItemFromResp = response.data[0].id;
        dispatch(
            updateNestedField('cmoConnection', 'dataFromBE', dataItemFromResp.statusDate, {
                arrayName: 'content',
                idFieldName: 'cmoRegId',
                id: dataItemFromResp.clientCmoRegistrationId,
                fieldNameToChange: 'statusDate',
            }),
        );
        dispatch(
            updateNestedField('cmoConnection', 'dataFromBE', dataItemFromResp.newStatus, {
                arrayName: 'content',
                idFieldName: 'cmoRegId',
                id: dataItemFromResp.clientCmoRegistrationId,
                fieldNameToChange: 'registrationStatus',
            }),
        );
        dispatch(
            getCmoConnectionsByClientId(props.match.params.id, {
                isUpdateble: true,
                arrayName: 'content',
                idFieldName: 'cmoRegId',
                id: dataItemFromResp.clientCmoRegistrationId,
                fieldNameToChange: 'acceptableRegistrationStatuses',
            }),
        );
    };

    const statusChangeCb = async (status, dataItem, fromModal = false) => {
        if (cmoConnectionModel.isFirstToSubmit && !fromModal) {
            setStatusChangeOptions([status, dataItem]);
            setIsStatusDialogOpen(true);
        } else {
            const response = await changeRegistrationStatusAPI({
                clientCmoRegistrationId: dataItem.cmoRegId,
                newStatus: status,
            });

            updateStatusInRow(response);
        }
    };

    const moreOptionsSpecificData = useCallback((headerItem, dataItem) => {
        const coulumnTitle = headerItem.title.toLowerCase();
        if (coulumnTitle === '') {
            const onEdit = () => {
                dispatch(formatTerritoryCodes(dataItem.territoriesCodes));
                dispatch(changeStateByNestedKey('performers', 'selectedRowDataItem', dataItem));
                dispatch(changeStateByNestedKey('performers', 'selectedCmoId', dataItem.cmoId));
                openModal();
            };

            const onDelete = () => {
                setIsDialogOpen(true);
                setCmoRegId(dataItem.cmoRegId);
            };
            return (
                <>
                    <IconButton
                        onClick={(e) => handleHistoryClick(e, dataItem)}
                        className={classes.statusIconBtns}
                        disableRipple
                        size="large"
                    >
                        <ScheduleOutlined style={{ width: '18px', height: '18px' }} />
                    </IconButton>
                    <IconButton
                        onClick={(e) => handleCommentsClick(e, dataItem)}
                        className={classes.statusIconBtns}
                        disableRipple
                        size="large"
                    >
                        {dataItem.commentPresented ? (
                            <ChatBubbleUnread style={{ width: '18px', height: '18px' }} />
                        ) : (
                            <ChatBubbleOutline style={{ width: '18px', height: '18px' }} />
                        )}
                    </IconButton>
                    <MoreDropDownButton
                        callbacks={{
                            onEditCB: onEdit,
                            onDeleteCB: onDelete,
                            onAlertCB: handleAlertClick,
                        }}
                        dataItem={dataItem}
                    />
                </>
            );
        } else if (coulumnTitle.includes('start date')) {
            const status = dataItem.status.toLowerCase();
            const StatusIcon = () => {
                if (status === 'active') {
                    return <Done className={`${classes.statusIcons} ${classes.activeIcon}`} />;
                } else if (status === 'inactive') {
                    return <Close className={`${classes.statusIcons} ${classes.inactiveIcon}`} />;
                } else {
                    return <PendingIconSvg styles={classes.statusIcons} />;
                }
            };
            return (
                <OverflowTip className={classes.flexHorizontal_s_b}>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'flex-start',
                            alignItems: 'center',
                        }}
                    >
                        <StatusIcon />
                        {dataItem[headerItem.dataIndex]}
                    </div>
                </OverflowTip>
            );
        } else if (coulumnTitle.includes('registration status') && dataItem.registrationStatus) {
            const menuItemsList = (dataItem.acceptableRegistrationStatuses || [])
                .filter((status) => Object.keys(STATUSES_LIST).includes(status))
                .map((status) => ({
                    title: status.split('_').join(' '),
                    onClickClb: statusChangeCb,
                    CbArgs: [status, dataItem],
                    color: STATUSES_LIST[status].color,
                }));

            return (
                <div className={classes.displayFlex}>
                    {dataItem[headerItem.dataIndex] === 'EXCLUSIVE_LICENSE_DEAL' ? (
                        <ChipLabel label="EXCLUSIVE LICENSE" style={chipThemes.greenChip} />
                    ) : (
                        <CommonDropDownChips
                            chipTitle={dataItem[headerItem.dataIndex].split('_').join(' ')}
                            menuItemsList={menuItemsList}
                            hasAlerts={dataItem?.alertCategories}
                        />
                    )}
                    <div
                        className={classes.displayFlex}
                        style={{ marginLeft: '3px', marginTop: '4px', alignItems: 'center' }}
                        onClick={() => handleAlertClick(dataItem)}
                    >
                        {(dataItem.alertCategories || []).includes('INTERNAL') && (
                            <Tooltip title="Internal action required">
                                <div>
                                    <ErrorOutline
                                        style={{ width: '18px', height: '18px', fill: '#F44139' }}
                                        className={classes.alertIcons}
                                    />
                                </div>
                            </Tooltip>
                        )}
                        {(dataItem.alertCategories || []).includes('CLIENT') && (
                            <Tooltip title="Client action required">
                                <div>
                                    <WarningOrangeSvg styles={classes.alertIcons} />
                                </div>
                            </Tooltip>
                        )}
                        {(dataItem.alertCategories || []).includes('CMO') && (
                            <Tooltip title="Awaiting CMO action">
                                <div>
                                    <InfoIconSmallBlueSvg styles={classes.alertIcons} />
                                </div>
                            </Tooltip>
                        )}
                    </div>
                </div>
            );
        } else if (coulumnTitle.includes('last modified')) {
            return (
                <OverflowTip>
                    {moment(dataItem[headerItem.dataIndex]).format('YYYY-MM-DD')}
                </OverflowTip>
            );
        } else {
            return <OverflowTip>{dataItem[headerItem.dataIndex]}</OverflowTip>;
        }
    });

    // Performer CMO Tab on /performer-page/id
    return (
        <div>
            <CommonTableGrid
                dataList={cmoConnectionModel.dataFromBE}
                headerList={headers}
                cellDataCb={moreOptionsSpecificData}
                loading={cmoConnectionModel.tableIsLoading}
                preferences={{
                    loadDataByPageCb: {
                        dispatchFn: dispatch,
                        callbackFn: getCmoConnectionsByClientId,
                        argumentsList: [props.match.params.id],
                    },
                }}
                initialRowsPerPage={50}
                selection={{
                    isSelectable: false,
                }}
            />
            {showTerritoryModal && (
                <NewCmoConnectionModal
                    showTerritoryModal={showTerritoryModal}
                    setShowTerritoryModal={setShowTerritoryModal}
                    territories={territories}
                    setTerritories={setTerritories}
                    groupedTerritories={territoriesModel.groupedTerritories}
                    clientId={props.match.params.id}
                    isEditing={true}
                    customData={{
                        // This is used for performers edit
                        dealsContainerName: 'clientDeals',
                        selectedCmoStoreName: 'performers',
                        dataModel: performersModel.byClietnId,
                        selectedCmoId: performersModel.selectedCmoId,
                        modalValidations: performersModel.modalValidations,
                        selectedRowDataItem: performersModel.selectedRowDataItem,
                        isPerformer: { isPerformer: true },
                        onTableReload: {
                            reloadCb: getCmoConnectionsByClientId,
                            params: [props.match.params.id],
                        },
                        cmoIssuedIds: performersModel.cmoIssuedIds,
                        hasIssuedId: performersModel.hasIssuedId,
                    }}
                />
            )}
            <CommonConfirmationDialog
                isOpen={isDialogOpen}
                closeCb={setIsDialogOpen}
                title="Delete CMO for this client?"
                description="Are you sure you want to delete this CMO row for this client?"
                onConfirmCb={deleteConnection}
            />
            <CommonConfirmationDialog
                isOpen={isStatusDialogOpen}
                closeCb={setIsStatusDialogOpen}
                title="Begin Client CMO Registration?"
                description="Once this registration is 'Submitted' then you will not be able to edit the Client Deal, do you want to proceed?"
                onConfirmCb={statusChangeCb}
                confirmCbArgs={[...statusChangeOptions, true]}
            />
            <StatusHistoryModal
                anchorEl={anchorEl}
                onClose={handleHistoryClose}
                isOpen={openHistoryInfo}
                selectedRowDataItem={performersModel.selectedRowDataItem}
                storeName="performers"
                contentData={performersModel.registrationHistory}
            />
            <CmoRegCommentsModal
                isOpen={openCommentsModal}
                setOpen={setOpenCommentsModal}
                dataItem={clickedDataItem}
                onSuccessCb={() => {
                    dispatch(
                        getCmoConnectionsByClientId(props.match.params.id, {
                            isUpdateble: true,
                            arrayName: 'content',
                            idFieldName: 'cmoRegId',
                            id: clickedDataItem.cmoRegId,
                            fieldNameToChange: 'commentPresented',
                        }),
                    );
                }}
            />
            <CmoRegAlertsModal
                isOpen={openAlertsModal}
                setOpen={setOpenAlertsModal}
                dataItem={clickedDataItem}
            />
        </div>
    );
}

const withUrlDataCmoConnectionTable = withRouter(CmoConnectionTable);
export default connect((r) => r)(withUrlDataCmoConnectionTable);
