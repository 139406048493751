import React, { useEffect } from 'react';

import { loadCmoInfo } from '@actions/fugaActions';
import { BreadcrumbNavigation } from '@common/BreadcrumbNavigation';
import { APP_ROUTES, BREADCRUMB_TEXTS } from '@utils/navigation';
import { connect, useSelector, useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';

function CmoPageTitle({ match, cmoType }) {
    const dispatch = useDispatch();
    const cmoModel = useSelector((state) => state.fugaReducers.cmoPage);
    const performerCmoPageModel = useSelector((state) => state.fugaReducers.performerCmoPage);
    const isPerformer = cmoType === 'PERFORMER';
    const pageModel = isPerformer ? performerCmoPageModel : cmoModel;

    useEffect(() => {
        dispatch(loadCmoInfo(match.params.id, cmoType === 'PERFORMER'));
    }, []);

    return (
        <BreadcrumbNavigation
            items={[
                { href: APP_ROUTES.CMO_LIST, text: BREADCRUMB_TEXTS.CMO_CATALOG },
                { href: '', text: pageModel?.cmoInfo?.name },
            ]}
            pageName={`${pageModel?.cmoInfo?.name} (${
                cmoType === 'PERFORMER' ? 'Performer' : 'Rights holder'
            })`}
        />
    );
}

const withUrlCmoPageTitle = withRouter(CmoPageTitle);
export default connect((r) => r)(withUrlCmoPageTitle);
