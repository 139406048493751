import {
    CREDS_AUTH_ERR_MSG,
    EMAIL_SUCCESS_MSG,
    RESET_PASSWORD_ERR_MSG,
    roles,
    TOKEN_AUTH_ERR_MSG,
    UPDATE_PASSWORD_ERR_MSG,
    USERNAME_ERR_MSG,
} from '@data/constants';
import userData from '@data/userData';
import cerberusUtils from '@utils/cerberusUtils';
import Fetch from '@utils/Fetch';
import helper from '@utils/helper';

import { changeStateByKey } from './fugaActions';

export function getRouteFromUserRole() {
    const currentRole = cerberusUtils.getUserRole();
    const roleInfo = roles.find((role) => role.name === currentRole);

    let landingPageUrl = '/';
    if (roleInfo) {
        landingPageUrl = roleInfo.landingPage.toBeGenerated
            ? helper.generateLandingPage(roleInfo.landingPage.url)
            : roleInfo.landingPage.url;
    }

    return landingPageUrl;
}
function navigationRoute() {
    const urlToFollow = getRouteFromUserRole();

    window.location.assign(urlToFollow);
}

export function login(queryParams = { username: '', password: '' }) {
    return async function (dispatch) {
        const url = `${userData.api.baseUrl}/login`;

        const body = JSON.stringify({
            username: queryParams.username,
            password: queryParams.password,
        });

        const options = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body,
        };

        try {
            const res = await Fetch.fetchWithErrorToast({
                url,
                options,
                errorMessage: 'An error has occurred',
            });

            if (res?.data) {
                const { access_token: token, clientId, isTnCaccepted, userRole } = res.data;

                cerberusUtils.setClientId(clientId + '');
                cerberusUtils.setCerberusToken(token);
                cerberusUtils.setUserRole(userRole.toLowerCase());

                if (isTnCaccepted) {
                    navigationRoute();
                } else {
                    dispatch(changeStateByKey('submitTermsAndConditions', true));
                }
            }
        } catch (rejected) {
            const error = rejected.status === 401 ? CREDS_AUTH_ERR_MSG : TOKEN_AUTH_ERR_MSG;
            console.log('Failed to send request via url: ' + url, `error msg: ${error}`);
            dispatch(changeStateByKey('loginErrorMsg', error));
        }
    };
}

export function resetPassword(query = { username: '' }) {
    return async function (dispatch) {
        const url = `${userData.api.baseUrl}/password/trigger-reset?name=${query.username}`;

        try {
            const res = await Fetch.fetchWithErrorToast({
                url,
                options: { method: 'POST' },
                errorMessage: 'An error has occurred',
            });

            if (res?.data) {
                dispatch(changeStateByKey('loginSuccessMsg', EMAIL_SUCCESS_MSG));
            } else {
                const error = res?.data?.error || RESET_PASSWORD_ERR_MSG;
                if (error === 'NOT_FOUND') {
                    dispatch(changeStateByKey('loginErrorMsg', USERNAME_ERR_MSG));
                } else {
                    dispatch(changeStateByKey('loginErrorMsg', error));
                }
            }
        } catch (rejected) {
            const error = rejected?.data?.error || RESET_PASSWORD_ERR_MSG;
            console.log('Failed to send request via url: ' + url, `error msg: ${error}`);
            dispatch(changeStateByKey('loginErrorMsg', error));
        }
    };
}

export function updatePassword(query = { password: '', id: '', key: '' }) {
    return async function (dispatch) {
        const url = `${userData.api.baseUrl}/password/reset`;

        const body = JSON.stringify({
            id: query.id,
            password: query.password,
            key: query.key,
        });

        try {
            const res = await Fetch.fetchWithErrorToast({
                url,
                options: {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body,
                },
                errorMessage: 'An error has occurred',
            });

            if (res?.data) {
                dispatch(changeStateByKey('updatedPassword', true));
            } else {
                const error = res?.data?.error || UPDATE_PASSWORD_ERR_MSG;
                console.log('Failed to send request via url: ' + url, `error msg: ${error}`);
                dispatch(changeStateByKey('loginErrorMsg', error));
            }
        } catch (rejected) {
            const error = rejected?.data?.error || UPDATE_PASSWORD_ERR_MSG;
            console.log('Failed to send request via url: ' + url, `error msg: ${error}`);
            dispatch(changeStateByKey('loginErrorMsg', error));
        }
    };
}

export function agreeTermsAndConditions() {
    return function () {
        const url = `${userData.api.baseUrl}/terms-n-conditions/agree`;

        Fetch.fetchWrapper(url, { method: 'POST' })
            .then(async (res) => {
                if (!res.ok) {
                    const result = await res.json();
                    return Promise.reject(result);
                } else {
                    // follow normal follow of login
                    navigationRoute();
                }
            })
            .catch((rejected) => {
                console.log(
                    'Failed to send request via url: ' + url,
                    `error msg: ${rejected?.data?.error}`,
                );
                // clean everything redirect to /login
                cerberusUtils.setUserRole('');
                cerberusUtils.setCerberusToken('');
                window.location.assign('/login');
            });
    };
}

export function cleanLoginMessages() {
    return function (dispatch) {
        dispatch(changeStateByKey('loginErrorMsg', ''));
        dispatch(changeStateByKey('loginSuccessMsg', ''));
        dispatch(changeStateByKey('submitTermsAndConditions', false));
    };
}
