import React from 'react';

import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined';
import Alert from '@mui/material/Alert';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    alert: {
        display: 'flex',
        alignItems: 'center',
        backgroundColor: theme.palette.orange.outlinedHoverBackground,
        marginBottom: theme.spacing(2),
        color: theme.palette.orange.content,
        padding: theme.spacing(2, 2),
        border: `1px solid ${theme.palette.orange.outlinedRestingBorder}`,
        width: '100%',
        '& div': {
            padding: 0,
            margin: 0,
        },

        '& > .MuiAlert-message': {
            flexGrow: 1,
        },
    },
    container: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    warningMessage: {
        color: theme.palette.orange.content,
        marginLeft: theme.spacing(1.75),
    },
}));

function WarningBanner({ text }) {
    const classes = useStyles();
    return (
        <Alert
            severity="warning"
            icon={<ReportProblemOutlinedIcon fontSize="inherit" />}
            className={classes.alert}
        >
            <div className={classes.container}>
                <Typography className={classes.warningMessage}>{text}</Typography>
            </div>
        </Alert>
    );
}

export default WarningBanner;
