import React, { useState, useEffect } from 'react';

import { changeStateByNestedKey } from '@actions/fugaActions';
import { CellsForFullTable } from '@common/CommonTable/CommonTableComponents';
import CommonTableRow from '@common/CommonTable/CommonTableRow';
import {
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Typography,
    TableFooter,
    TablePagination,
    TableSortLabel,
    CircularProgress,
    Checkbox,
} from '@mui/material';
import { findDataIndex } from '@utils/commonTableUtils';

const CustomTableSortLabel = ({ hasNumericData, ...props }) => (
    <TableSortLabel
        {...props}
        sx={[
            hasNumericData
                ? {
                      '& .MuiTableSortLabel-icon': {
                          margin: '0 8px 0 0',
                      },
                  }
                : {
                      '& .MuiTableSortLabel-icon': {
                          margin: '0 0 0 8px',
                      },
                  },
        ]}
    >
        {props.children}
    </TableSortLabel>
);

const handleCallbackFn = (preferences, callbackFn, callBackParams, dispatchFn) => {
    if (preferences?.loadDataByPageCb?.noDispatch) {
        callbackFn(...callBackParams);
    } else {
        dispatchFn(callbackFn(...callBackParams));
    }
};

export default function CommonTableGrid({
    headerList,
    dataList, // necessarily should contain 'content' property as main array for iterations
    // dataList should contain following properties if paginationBE is true {content, totalElements, pageable: {pageNumber, pageSize}
    cellDataCb, // optional param, in case need to wrap cell into some component/tag
    loading,
    preferences, // mandatory: if paginationBE is true
    paginationBE,
    searchTerm,
    selection, // provide object with dispatch and callback fns if checkbox selection is available
    rowsPerPageOff,
    tablePaginationOff,
    initialRowsPerPage,
    sortPrefs, // for BE sorting on all pages
    footerAddition,
    isInSubModal,
    trackPagination,
}) {
    const {
        loadDataByPageCb: { argumentsList, dispatchFn, callbackFn },
        onRowClick,
    } = preferences;
    // mandatory: selected/setSelected should be created by UseRef hook
    const {
        selectedRows: selected = [],
        setSelectedRows: setSelected,
        excludedRows: excluded = [],
        setExcludedAssets: setExcluded,
        downloadAllCmoAssets: downloadAllCmoAssets = {},
        storeName,
    } = selection;
    const [page, setPage] = useState(dataList?.pageable?.pageNumber || 0);
    const [rowsPerPage, setRowsPerPage] = useState(
        initialRowsPerPage || dataList?.pageable?.pageSize || 10,
    );
    const [orderBy, setOrderBy] = useState('trackTitle');
    const [order, setOrder] = useState('asc');
    const [data, setData] = useState([]);
    const [isHeaderCheckBoxDisabled, setIsHeaderCheckBoxDisabled] = useState(false);
    const makeLocalPagination = () => {
        if (!paginationBE && !tablePaginationOff) {
            const pagedArray = (dataList.content || []).slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage,
            );
            setData(pagedArray);
        }
    };

    useEffect(() => {
        setRowsPerPage(dataList?.pageable?.pageSize || 10);
        if (dataList.content) {
            setData(dataList.content);
            if (selection.isSelectable && dataList.content?.length === 0) {
                setSelected([]);
                setIsHeaderCheckBoxDisabled(true);
            }
            if (downloadAllCmoAssets?.requestFlag || downloadAllCmoAssets?.localSelectAll) {
                addToSelectedIfMissing(dataList.content);
            }
            // This if block makes local pagination work if it is not provided server side (from BE)
            makeLocalPagination();
        } else if (!('content' in dataList) && selection.isSelectable) {
            setSelected([]);
            setIsHeaderCheckBoxDisabled(true);
        }
    }, [dataList.content]);

    useEffect(() => {
        makeLocalPagination();
    }, [page, rowsPerPage]);

    useEffect(() => {
        setIsHeaderCheckBoxDisabled(false);
    }, [dataList.content]);

    useEffect(() => {
        setPage(dataList?.pageable?.pageNumber || 0);
    }, [dataList?.pageable?.pageNumber]);

    const addToSelectedIfMissing = (dataToSearch) => {
        let newSelected = [];
        dataToSearch.map((dataItem) => {
            const selectedIndex = findDataIndex(
                selection.filter,
                selected,
                dataItem,
                selection.fullRowData,
            );

            const excludedIndex = findDataIndex(
                selection.filter,
                excluded,
                dataItem,
                selection.fullRowData,
            );

            if (selectedIndex === -1 && excludedIndex === -1) {
                if (selection.fullRowData) {
                    newSelected.push(dataItem);
                } else {
                    newSelected.push(dataItem[selection.filter]);
                }
            }
        });
        setSelected(selected.concat(newSelected)); // works because of ref
    };

    const handleChangePage = (event, newPage) => {
        if (paginationBE) {
            if (downloadAllCmoAssets?.requestFlag || downloadAllCmoAssets?.localSelectAll) {
                addToSelectedIfMissing(dataList.content);
            }
        }
        setPage(newPage);
        if (trackPagination?.isPaginationTrackingOn) {
            // pageHasChanged param is mandatory for state that will be passed here
            trackPagination.paginationTrackingCB({
                pageHasChanged: true,
                page: newPage,
                size: rowsPerPage,
            });
        }
        if (paginationBE) {
            const callBackParams = [
                ...argumentsList,
                {
                    page: newPage,
                    size: rowsPerPage,
                    ...(searchTerm && { searchTerm: searchTerm }),
                    ...(sortPrefs?.value.value && { sort: sortPrefs?.value.value }),
                    ...(sortPrefs?.value.direction &&
                        !sortPrefs?.sortOnly && {
                            direction: sortPrefs?.value.direction,
                        }),
                },
            ];
            handleCallbackFn(preferences, callbackFn, callBackParams, dispatchFn);
        }
    };

    const handleChangeRowsPerPage = (event) => {
        // works only when change page and come back (for local not for BE pagination)
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
        if (paginationBE) {
            const callBackParams = [
                ...argumentsList,
                {
                    page: 0,
                    size: event.target.value,
                    ...(searchTerm && { searchTerm: searchTerm }),
                    ...(sortPrefs?.value.value && { sort: sortPrefs?.value.value }),
                    ...(sortPrefs?.value.direction &&
                        !sortPrefs?.sortOnly && {
                            direction: sortPrefs?.value.direction,
                        }),
                },
            ];
            handleCallbackFn(preferences, callbackFn, callBackParams, dispatchFn);
        }
    };

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy.toString() === property.toString() && order === 'asc';
        const orderLocal = isAsc ? 'desc' : 'asc';
        setOrder(orderLocal);
        setOrderBy(property);

        // replace hardcoded line with dynamic if case will come again in the future with other field name
        const getProperty = (property) => {
            if (property === 'artistList') {
                return 'primaryArtist';
            } else if (property === 'ownershipTerritories') {
                return 'ownershipTerritoryCodes';
            } else {
                return property;
            }
        };

        if (sortPrefs) {
            const propertyChecked = getProperty(property);
            const sortDesc = `${(orderLocal + '').toUpperCase()}`;
            let sortV = `${propertyChecked}`;
            if (!sortPrefs.sortOnly) {
                if (Array.isArray(propertyChecked)) {
                    sortV = propertyChecked[0] + '';
                }
            } else if (sortPrefs.sortOnly) {
                sortV += `,${sortDesc}`;
            }

            dispatchFn(
                changeStateByNestedKey(sortPrefs.storeName, sortPrefs.storeSubName, {
                    value: sortV,
                    ...(!sortPrefs?.sortOnly && { direction: sortDesc }),
                }),
            );

            const callBackParams = [
                ...argumentsList,
                {
                    page: page,
                    size: rowsPerPage,
                    ...(searchTerm && { searchTerm: searchTerm }),
                    sort: sortV,
                    ...(!sortPrefs?.sortOnly && { direction: sortDesc }),
                },
            ];

            handleCallbackFn(preferences, callbackFn, callBackParams, dispatchFn);
        }
    };

    function descendingComparator(a, b, orderBy) {
        if (b[orderBy] < a[orderBy]) {
            return -1;
        }
        if (b[orderBy] > a[orderBy]) {
            return 1;
        }
        return 0;
    }

    function getComparator(order, orderBy) {
        return order === 'desc'
            ? (a, b) => descendingComparator(a, b, orderBy)
            : (a, b) => -descendingComparator(a, b, orderBy);
    }

    const getHeaderContent = (headerItem) => {
        if (headerItem.disableSorting) {
            return headerItem.title;
        } else {
            const dataIndex = Array.isArray(headerItem.dataIndex)
                ? headerItem.dataIndex.join(',')
                : headerItem.dataIndex;

            return (
                <CustomTableSortLabel
                    hasNumericData={headerItem.hasNumericData}
                    active={orderBy === dataIndex}
                    sx={{
                        flexDirection: headerItem.hasNumericData ? 'row-reverse' : 'inherit',
                    }}
                    direction={orderBy === dataIndex ? order : 'asc'}
                    onClick={(e) => handleRequestSort(e, dataIndex)}
                >
                    {headerItem.title}
                </CustomTableSortLabel>
            );
        }
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            let newSelected;
            if (selection.fullRowData) {
                newSelected = [...data];
            } else {
                newSelected = data.map((n) => n[selection.filter]);
            }

            // TODO disabled checkboxes selection if the row has alert
            // const newSelecteds = data.filter((n) => {
            //   return n[selection.isValidField]
            // }).map((n) => n[selection.filter]);
            setSelected(newSelected);
            setExcluded([]);
            selection.dispatchFn(
                selection.callbackFn(storeName, 'downloadAllCmoAssets', {
                    requestFlag: true,
                    localSelectAll: true,
                }),
            );
        } else {
            setSelected([]);
            selection.dispatchFn(
                selection.callbackFn(storeName, 'downloadAllCmoAssets', {
                    requestFlag: false,
                    localSelectAll: false,
                }),
            );
        }
    };

    const getSelectedCount = () => {
        let count = 0;
        if (downloadAllCmoAssets.requestFlag) {
            if (excluded.length > 0) {
                count = dataList.totalElements - excluded.length;
            } else {
                count = dataList.totalElements;
            }
        } else {
            count = selected.length;
        }
        return count;
    };

    const listTableContetToRender = (dataArray = []) => {
        if (!sortPrefs) {
            const defined = dataArray.filter((el) => el[orderBy]);
            const notDefined = dataArray.filter((el) => !el[orderBy]);
            defined.sort(getComparator(order, orderBy));
            dataArray = [...defined, ...notDefined];
        }
        return dataArray.map((el, ind) => {
            return (
                <CommonTableRow
                    key={`${ind}-common-table-grid`}
                    dataItem={el}
                    headerList={headerList}
                    cellDataCb={cellDataCb}
                    selected={selected}
                    setSelected={setSelected}
                    selection={selection}
                    excluded={excluded}
                    setExcluded={setExcluded}
                    downloadAllCmoAssets={downloadAllCmoAssets}
                    onRowClick={onRowClick}
                    storeName={storeName}
                    isInSubModal={isInSubModal}
                />
            );
        });
    };

    const getRowsPerPageList = () => {
        const list = [10, 20, 50, 100];
        if (!list.includes(initialRowsPerPage) && initialRowsPerPage > 0)
            list.push(initialRowsPerPage);
        return list;
    };

    return (
        <div
            style={{
                overflow: 'auto',
                width: '100%',
            }}
        >
            <Table sx={{ position: 'relative', width: '100%' }}>
                <TableHead
                    sx={{
                        '& th': {
                            borderRight: 'none',
                        },
                    }}
                >
                    <TableRow>
                        {selection.isSelectable && (
                            <TableCell size="small">
                                <Checkbox
                                    sx={{ padding: '0px' }}
                                    color="primary"
                                    indeterminate={
                                        !downloadAllCmoAssets.localSelectAll &&
                                        selected.length > 0 &&
                                        selected.length !== dataList.totalElements
                                    }
                                    checked={
                                        (downloadAllCmoAssets.localSelectAll && data.length > 0) ||
                                        (data.length > 0 &&
                                            selected.length === dataList.totalElements)
                                    }
                                    onChange={handleSelectAllClick}
                                    disabled={isHeaderCheckBoxDisabled}
                                />
                            </TableCell>
                        )}
                        {headerList.map((header, ind) => (
                            <TableCell
                                align={header.hasNumericData ? 'right' : 'left'}
                                key={`${header.dataIndex}-${ind}`}
                                sx={{
                                    maxWidth: header.wideTableCell ? '200px' : '180px',
                                    minWidth: '30px',
                                }}
                            >
                                <Typography
                                    variant="subtitle2"
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}
                                >
                                    {getHeaderContent(header)}
                                    {header?.additionalHeaderContent?.headerCallBack &&
                                        header.additionalHeaderContent.headerCallBack(header)}
                                </Typography>
                            </TableCell>
                        ))}
                        <CellsForFullTable headerList={headerList} />
                    </TableRow>
                </TableHead>
                <TableBody
                    sx={{
                        position: 'relative',
                        width: '100%',
                        '& .MuiTableRow-root:hover > *': {
                            cursor: 'pointer',
                            backgroundColor: '#F4F4F6',
                        },
                        '& td': {
                            border: 'none',
                            backgroundColor: '#FFFFFF',
                        },
                    }}
                >
                    {loading && (
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                right: 0,
                                bottom: 0,
                                backgroundColor: 'rgba(243,243,243,0.5)',
                                zIndex: 2,
                            }}
                        >
                            <CircularProgress />
                        </div>
                    )}
                    {data.length === 0 && (
                        <TableRow sx={{ height: '90px' }}>
                            <TableCell colSpan={headerList.length + 1} align="center">
                                No data
                            </TableCell>
                        </TableRow>
                    )}
                    {listTableContetToRender(data)}
                </TableBody>
                <TableFooter>
                    <TableRow>
                        {footerAddition && (
                            <TableCell
                                sx={{
                                    '& > *': {
                                        minWidth: '240px',
                                    },
                                    border: 'none',
                                }}
                            >
                                {footerAddition.itemComponent}
                            </TableCell>
                        )}
                        <TableCell
                            sx={{
                                border: 'none',
                            }}
                        />
                        <TableCell
                            sx={{
                                border: 'none',
                                '& p': {
                                    width: '130px',
                                },
                            }}
                        >
                            {selection.isSelectable && (
                                <Typography variant="body1">{`Selected: (${getSelectedCount()})`}</Typography>
                            )}
                        </TableCell>
                        {!tablePaginationOff && (
                            <TablePagination
                                rowsPerPageOptions={rowsPerPageOff ? [] : getRowsPerPageList()}
                                slotProps={{
                                    select: {
                                        inputProps: {
                                            'aria-label': 'rows per page',
                                        },
                                        native: true,
                                    },
                                }}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                                count={dataList.totalElements || dataList?.content?.length || 0}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                sx={{
                                    '& .MuiTablePagination-selectLabel, & .MuiTablePagination-displayedRows':
                                        {
                                            margin: 0,
                                            padding: 0,
                                        },
                                    '& .MuiNativeSelect-select': {
                                        paddingTop: 0,
                                        paddingBottom: 0,
                                    },
                                }}
                            />
                        )}
                    </TableRow>
                </TableFooter>
            </Table>
        </div>
    );
}
