import React, { useRef, useState } from 'react';

import { importCSVAPI } from '@api/assetController';
import { ArrowDropDown } from '@mui/icons-material';
import { Button as MuiButton, Menu, MenuItem } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
    arrowDropDownIcon: {
        width: '18px',
        height: '18px',
        marginLeft: '10px',
    },
}));

export default function DropDownButton() {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState(null);
    const [inputClicked, setInputClicked] = useState(false);
    const inputRef = useRef(null);

    const handleOpen = ({ currentTarget }) => {
        setAnchorEl(currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
        setInputClicked(false);
    };

    const uploadFile = async ({ currentTarget }) => {
        setAnchorEl(null);
        if (currentTarget.value) {
            await importCSVAPI(currentTarget.files);
        }
        setInputClicked(false);
    };

    return (
        <div style={{ marginLeft: '16px' }}>
            <MuiButton
                variant="contained"
                sx={{
                    height: '35px',
                }}
                onClick={(e) => handleOpen(e)}
                color="primary"
                id="add-from-src-btn"
                disableElevation
            >
                ADD FROM SOURCE
                <ArrowDropDown className={classes.arrowDropDownIcon} />
            </MuiButton>
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                getContentAnchorEl={null}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                transformOrigin={{ vertical: 'top', horizontal: 'left' }}
            >
                <MenuItem
                    key="from-csv"
                    style={{ width: '100%' }}
                    onClick={() => {
                        if (!inputClicked) {
                            setInputClicked(true);
                            inputRef.current.click();
                            handleClose();
                        }
                    }}
                >
                    <div>Add from XLS</div>
                </MenuItem>
                {/* TODO items below will be implemented later */}
                {/* <MenuItem
                    key="from-fuga"
                >
                    Add from Fuga DB
                </MenuItem>
                <MenuItem
                    key="from-spotify"
                >
                    Add from Spotify
                </MenuItem> */}
            </Menu>
            <input
                ref={inputRef}
                type="file"
                style={{ position: 'fixed', top: '-100%' }}
                onChange={(e) => uploadFile(e)}
            />
        </div>
    );
}
