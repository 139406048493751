import React, { useState } from 'react';

import { cleanLoginMessages } from '@actions/authActions';
import { Alert, Button, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { makeStyles } from '@mui/styles';
import TextFieldWrapper from '@MuiWrappers/TextFieldWrapper';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

const updatePasswordStyles = makeStyles(() => ({
    alertContainer: {
        '& .MuiAlert-icon': {
            display: 'none',
        },
        '& .MuiAlert-message': {
            textAlign: 'left',
        },
    },
}));

const NOT_VALID_CONFIRMATION_PASSWORD = 'Passwords do not match.';

const UpdatePasswordForm = ({ onUpdatePassword }) => {
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [errorConfimationPassword, setErrorConfirmPassword] = useState(false);
    const [errorNewPassword, setErrorNewPassword] = useState(false);
    const dispatch = useDispatch();
    const errorMessage = useSelector((state) => state.fugaReducers.loginErrorMsg);
    const classes = updatePasswordStyles();

    const { search } = useLocation();

    const query = new URLSearchParams(search);
    const id = query.get('id');
    const key = query.get('key');

    const validatePassword = (passwordText) => {
        // regex matching all the criterias mentioned on lines 433-446
        const VALID_PASSWD_REGEX = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[!@#$%^&*_+,.'"]).{12,}$/;
        return VALID_PASSWD_REGEX.test(passwordText);
    };

    function onBlurNewPassword() {
        if (!newPassword || validatePassword(newPassword)) {
            setErrorNewPassword(false);

            if ((confirmPassword && confirmPassword === newPassword) || !confirmPassword) {
                setErrorConfirmPassword(false);
            } else {
                setErrorConfirmPassword(true);
            }
        } else {
            setErrorNewPassword(true);
        }
    }

    function onBlurConfirmPassword() {
        if (confirmPassword === newPassword || !confirmPassword) {
            setErrorConfirmPassword(false);
        } else {
            setErrorConfirmPassword(true);
        }
    }

    function onChangeNewPassword(evt) {
        dispatch(cleanLoginMessages());
        setNewPassword(evt.target.value);
    }
    function onChangeConfirmPassword(evt) {
        dispatch(cleanLoginMessages());
        setConfirmPassword(evt.target.value);
    }

    function onUpdatePasswordAction() {
        onUpdatePassword(newPassword, id, key);
    }

    function handleSubmit(evt) {
        evt.preventDefault();
        onUpdatePasswordAction();
    }

    function handleKeyPress(evt) {
        if (evt.key.toString() === 'Enter') {
            evt.target.blur();
            if (confirmPassword === newPassword && !!confirmPassword && !!newPassword) {
                handleSubmit(evt);
            }
        }
    }

    return (
        <form name="updatePassword" onSubmit={handleSubmit} onKeyPress={handleKeyPress}>
            <Grid direction="column" spacing={3} container>
                <Grid>
                    <Typography variant="h5" align="justify">
                        Reset Password
                    </Typography>
                </Grid>
                {errorMessage && (
                    <Grid>
                        <Alert severity="error" variant="outlined">
                            {errorMessage}
                        </Alert>
                    </Grid>
                )}
                <Grid>
                    <TextFieldWrapper
                        // error={errorNewPassword}
                        label="New password"
                        type="password"
                        fullWidth
                        value={newPassword}
                        onChange={onChangeNewPassword}
                        onBlur={onBlurNewPassword}
                        // helperText={errorNewPassword && NOT_VALID_FORMAT_PASSWORD}
                    />
                </Grid>
                <Grid>
                    <TextFieldWrapper
                        error={errorConfimationPassword}
                        label="Re-enter new password"
                        type="password"
                        fullWidth
                        value={confirmPassword}
                        onChange={onChangeConfirmPassword}
                        onBlur={onBlurConfirmPassword}
                        helperText={errorConfimationPassword && NOT_VALID_CONFIRMATION_PASSWORD}
                    />
                </Grid>
                {errorNewPassword && (
                    <Grid>
                        <Alert
                            severity="error"
                            variant="outlined"
                            className={classes.alertContainer}
                        >
                            <Typography variant="body2">
                                - Password must be 12 characters minimum
                            </Typography>
                            <Typography variant="body2">
                                - Password must contain at least one alphabetical character
                            </Typography>
                            <Typography variant="body2">
                                - Password must contain at least one digit (0-9)
                            </Typography>
                            <Typography variant="body2">
                                {`
                                - Password must contain at least one special character (!@#$%^&*_+-,.')
                                `}
                            </Typography>
                        </Alert>
                    </Grid>
                )}
                <Grid>
                    <Button
                        disabled={
                            errorConfimationPassword ||
                            errorNewPassword ||
                            !newPassword ||
                            !confirmPassword
                        }
                        fullWidth
                        disableElevation
                        variant="contained"
                        color="primary"
                        onClick={handleSubmit}
                        type="submit"
                    >
                        Update Password
                    </Button>
                </Grid>
            </Grid>
        </form>
    );
};

export default UpdatePasswordForm;
