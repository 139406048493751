import { useState } from 'react';

/**
 * A reusable hook to manage popover state and dynamic content.
 *
 * @param {function} getPopoverContent Function to dynamically generate popover content based on the triggering element.
 * @returns {object} Hook state and handlers for managing the popover.
 */
export const useInfoPopover = (getPopoverContent = () => ({ title: '', text: '' })) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [popoverContent, setPopoverContent] = useState({ title: '', text: '' });

    const handleInfoClick = (evt, context = null) => {
        const { currentTarget } = evt;
        setPopoverContent(getPopoverContent(context));
        setAnchorEl(currentTarget);
    };

    const handleInfoClose = () => setAnchorEl(null);

    const isOpen = Boolean(anchorEl);

    return { anchorEl, popoverContent, handleInfoClick, handleInfoClose, isOpen };
};
