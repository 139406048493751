import React, { useEffect, useState } from 'react';

import { changeStateByNestedKey } from '@actions/fugaActions';
import { Snackbar, Slide, CircularProgress } from '@mui/material';
import { Alert as MuiAlert } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { connect, useDispatch } from 'react-redux';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const LoadingIcon = () => {
    return <CircularProgress style={{ width: '22px', height: '22px', color: 'white' }} />;
};

const slideDirection = {
    right: 'left',
    up: 'up',
    left: 'right',
    down: 'down',
};

const useStyles = makeStyles((theme) => ({
    snackbar: {
        maxWidth: '25vw',
    },
}));

function InfoMessage({ transitionDirection, duration, severity, infoMessage, children }) {
    const direction = infoMessage.transitionDirection
        ? slideDirection[infoMessage.transitionDirection]
        : transitionDirection
          ? slideDirection[transitionDirection]
          : slideDirection.right;
    const hideDuration = infoMessage.duration
        ? parseInt(infoMessage.duration * 1000)
        : duration
          ? parseInt(duration * 1000)
          : 3000;
    const msgText = children ? children : infoMessage.message ? infoMessage.message : '';
    const msgSeverity = severity ? severity : infoMessage.severity ? infoMessage.severity : 'info';

    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);
    const [transition, setTransition] = React.useState(undefined);

    const classes = useStyles();

    function TransitionSide(props) {
        return <Slide {...props} direction={direction} unmountOnExit />;
    }

    useEffect(() => {
        setTransition(() => TransitionSide);
        setOpen(infoMessage.isVisible);
    }, [infoMessage.isVisible]);

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        dispatch(changeStateByNestedKey('infoMessage', 'isVisible', false));
        setOpen(false);
    };

    const alertProps = {
        onClose: handleClose,
        severity: msgSeverity,
        ...(infoMessage.isLoading && { icon: <LoadingIcon /> }),
        ...(infoMessage.customColor && { style: { backgroundColor: infoMessage.customColor } }),
    };

    return (
        <Snackbar
            className={classes.snackbar}
            open={open}
            autoHideDuration={hideDuration}
            onClose={handleClose}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            TransitionComponent={transition}
            key={transition ? transition.name : ''}
        >
            <Alert {...alertProps}>{msgText}</Alert>
        </Snackbar>
    );
}

export default connect((store) => ({
    infoMessage: store.fugaReducers.infoMessage,
}))(InfoMessage);
