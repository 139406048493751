import React, { useEffect, useState } from 'react';

import { changeStateByKey, changeStateByNestedKey } from '@actions/fugaActions';
import { Tabs, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Helpers from '@utils/helper';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';

import FilterChip from './Filters/FilterChip';
import FilterMenuDialogWithInput from './Filters/FilterMenuDialogWithInput';

const ISRCS_SIZE_LIMIT = 5000;

const useStyles = makeStyles((theme) => ({
    titile: {
        padding: '19px 16px',
    },
    content: {
        padding: '0 8px',
        width: '91%',
        height: 'auto',
    },
    flexHor: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
    },
    labels: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
    },
    clearAll: {
        '&:hover': {
            cursor: 'pointer',
        },
    },
    rootTabs: {
        '&.MuiTabs-root': {
            position: 'relative',
            width: '100%',
            justifyContent: 'space-between',
            alignItems: 'center',
        },
        '& > .MuiTabs-scroller.MuiTabs-scrollable': {
            position: 'absolute',
            left: 0,
            right: 0,
        },
        '& .MuiTabScrollButton-root': {
            opacity: 'unset',
        },
        '& .MuiTabs-scrollButtons': {
            position: 'absolute',
            width: '32px',
            height: '32px',
            background: '#ffffff',
            borderRadius: '50%',
            boxShadow: '0px 3px 5px -1px',
        },
        '& .MuiTabs-scrollButtons:first-child': {
            left: '8px',
            zIndex: '1',
        },
        '& .MuiTabs-scrollButtons:nth-child(4)': {
            right: '8px',
        },
    },
}));

function TableFilters({ tableReloadCb, onClearAll }) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [value, setValue] = useState(0);
    const [isFilterMenuOpen, setIsFilterMenuOpen] = useState(false);
    const [anchor, setAnchor] = useState(true);
    const [dataItemForMenu, setDataItemForMenu] = useState({});
    const filtersModel = useSelector((state) => state.fugaReducers.filters);

    useEffect(() => {
        dispatch(changeStateByKey('filters', {}));
    }, []);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleFilterClick = (event, dataItem) => {
        setAnchor(event.currentTarget);
        setDataItemForMenu(dataItem);
        setIsFilterMenuOpen(true);
    };

    const handleFilterCancel = (dataItem) => {
        setDataItemForMenu({}); // this line is optional
        dispatch(changeStateByNestedKey('filters', dataItem.filterField, ''));
        if (tableReloadCb) tableReloadCb();
        setIsFilterMenuOpen(false);
    };

    const onFilterConfirm = (value) => {
        dispatch(changeStateByNestedKey('filters', dataItemForMenu.filterField, value));
        if (tableReloadCb) tableReloadCb({ isrcs: filtersModel[dataItemForMenu.filterField] });
    };

    const handleClearAll = () => {
        const filtersModelCopy = { ...filtersModel };
        for (let key of Object.keys(filtersModel)) {
            filtersModelCopy[key] = '';
        }
        dispatch(changeStateByKey('filters', filtersModelCopy));
        if (onClearAll) onClearAll();
    };

    // NOTE: the data format should be followed in order to set it in redux store correctly
    const menuItems = [
        {
            label: 'ISRC',
            inputLabel: 'ISRCs',
            filterField: 'isrcs', // same as field read for value below
            placeholder: 'Enter or paste ISRCs separated by commas',
            value: filtersModel.isrcs,
            validation: (value) => {
                // validation field for input dialog
                // return a message with the reason for invalidation if '' valid if not invalid
                let reason = '';
                let isValid = true;
                let invalidSize = 0;

                if (value !== '') {
                    const isrcs = value.split(',');

                    if (isrcs.length > ISRCS_SIZE_LIMIT) {
                        reason = `Exceeds the limit of ${ISRCS_SIZE_LIMIT} ISRCs with ${isrcs.length} ISRCs`;
                        isValid = false;
                    } else {
                        invalidSize = isrcs
                            .map((isrc) => isrc.trim())
                            .filter((isrc) => {
                                return Helpers.validateISRC(isrc) === false;
                            }).length;

                        isValid = invalidSize === 0;
                        if (!isValid) {
                            reason = `${invalidSize} ISRC(s) with invalid format`;
                        }
                    }
                }

                return reason;
            },
        },
    ];

    return (
        <div className={`${classes.content} ${classes.flexHor}`}>
            <Tabs
                className={`${classes.rootTabs}`}
                value={value}
                onChange={handleChange}
                variant="scrollable"
                TabIndicatorProps={{
                    style: {
                        display: 'none',
                    },
                }}
            >
                {menuItems.map((filterItem) => {
                    return (
                        <FilterChip
                            key={`filter-item-${filterItem.filterField}`}
                            onClick={(e) => handleFilterClick(e, filterItem)}
                            dataItem={filterItem}
                            handleFilterCancelCb={() => handleFilterCancel(filterItem)}
                        />
                    );
                })}
                <Typography
                    color="primary"
                    variant="body2"
                    className={`${classes.labels} ${classes.clearAll}`}
                    onClick={handleClearAll}
                >
                    Clear all
                </Typography>
            </Tabs>
            <FilterMenuDialogWithInput
                isOpen={isFilterMenuOpen}
                closeCb={setIsFilterMenuOpen}
                anchorEl={anchor}
                dataItem={dataItemForMenu}
                onConfirm={onFilterConfirm}
            />
        </div>
    );
}

export default withRouter(TableFilters);
