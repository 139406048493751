import React, { useEffect, useState } from 'react';

import { changeStateByNestedKey } from '@actions/fugaActions';
import { ArrowDropDown } from '@mui/icons-material';
import { IconButton, InputAdornment, Menu, MenuItem, TextField, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { connect, useDispatch, useSelector } from 'react-redux';

const useStyles = makeStyles(() => ({
    iconBtn: {
        padding: '0',
        margin: '0',
        '&:hover': {
            background: 'none',
        },
    },
}));

function CommonDropDownInput({ menuItemsList, isEditing, storeName = 'performers' }) {
    const [anchorEl, setAnchorEl] = useState(null);
    const [value, setValue] = useState('');
    const [shrink, setShrink] = useState(false);
    const classes = useStyles();
    const dispatch = useDispatch();
    const dataModel = useSelector((state) => state.fugaReducers[storeName]);

    useEffect(() => {
        if (dataModel.selectedCmoId) {
            setValue(menuItemsList.find((item) => item.cmoId === dataModel.selectedCmoId)?.title);
            setShrink(true);
        }
    }, [menuItemsList]);

    const handleOpen = ({ currentTarget }) => {
        setAnchorEl(currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const onItemClick = (item) => {
        handleClose();
        setValue(item.title);
        dispatch(changeStateByNestedKey(storeName, 'selectedCmoId', item.cmoId));
        setShrink(true);
        if (item.onClickClb) item.onClickClb();
    };

    return (
        <>
            <TextField
                style={{ marginBottom: '32px' }}
                value={value}
                label="CMO name"
                variant="standard"
                fullWidth
                disabled={isEditing}
                slotProps={{
                    input: {
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    className={`${classes.iconBtn}`}
                                    disableRipple
                                    onClick={(e) => handleOpen(e)}
                                    disabled={isEditing}
                                    size="large"
                                >
                                    <ArrowDropDown style={{ marginRight: '0' }} />
                                </IconButton>
                            </InputAdornment>
                        ),
                    },
                    htmlInput: { style: { width: '450px' } },
                    inputLabel: { shrink },
                }}
            />
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                getContentAnchorEl={null}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
                {menuItemsList &&
                    menuItemsList.map((item, ind) => (
                        <MenuItem
                            key={`${ind}-${item.title}`}
                            onClick={() => onItemClick(item)}
                            style={{ padding: '0px 15px' }}
                        >
                            {item.icon ? item.icon : <></>}
                            <Typography variant="body1" style={{ margin: '14px 0 5px 0' }}>
                                {item.title}
                            </Typography>
                        </MenuItem>
                    ))}
            </Menu>
        </>
    );
}

export default connect((r) => r)(CommonDropDownInput);
