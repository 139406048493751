import React from 'react';

import { TableCell } from '@mui/material';

export const CellsForFullTable = React.memo(({ headerList }) => {
    if (headerList.length === 1)
        return (
            <>
                <TableCell></TableCell> <TableCell></TableCell>
            </>
        );
    if (headerList.length < 3) return <TableCell></TableCell>;
});
