import React, { useState } from 'react';

import DownloadIconSvg from '@images/svgComponents/DownloadIconSvg';
import { MoreVertSharp as MoreVert } from '@mui/icons-material';
import { Menu, MenuItem, IconButton } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
    moreIcon: {
        width: '18px',
        height: '18px',
        marginRight: '0',
        color: '#5F5F60',
    },
    downloadIcon: {
        height: '20px',
        marginRight: '7px',
    },
}));

export default function DropDownButtonKebabMenu({
    callbacks,
    isSpotifyIdPresent,
    handleExportRepertoire,
    disabled,
}) {
    const [anchorEl, setAnchorEl] = useState(null);
    const classes = useStyles();
    const { getSpotifyCb = () => {} } = callbacks;

    const handleOpen = ({ currentTarget }) => {
        setAnchorEl(currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div style={{ marginLeft: '16px' }}>
            <IconButton onClick={(e) => handleOpen(e)} disableRipple size="large">
                <MoreVert className={`${classes.moreIcon}`} />
            </IconButton>
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                getContentAnchorEl={null}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                transformOrigin={{ vertical: 'top', horizontal: 'left' }}
            >
                <MenuItem
                    key="getSpotify"
                    onClick={() => {
                        getSpotifyCb();
                        handleClose();
                    }}
                    disabled={!isSpotifyIdPresent}
                >
                    <DownloadIconSvg styles={classes.downloadIcon} />
                    Download Spotify Data
                </MenuItem>
                <MenuItem key="getReportoire" onClick={handleExportRepertoire} disabled={disabled}>
                    <DownloadIconSvg styles={classes.downloadIcon} />
                    Export Repertoire - full data
                </MenuItem>
            </Menu>
        </div>
    );
}
