import React, { useEffect, useState } from 'react';

import { Chip, MenuItem, CircularProgress, Autocomplete, TextField } from '@mui/material';
import classnames from 'classnames';

const DEBOUNCE_DELAY = 300;
const sleep = (delay = 0) => new Promise((resolve) => setTimeout(resolve, delay));

export const ChipTextInput = ({ open, dataItem, setSelectedOptions, selectedOptions, setOpen }) => {
    const [inputValue, setInputValue] = useState('');
    const [options, setOptions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [text, setText] = useState('');

    useEffect(() => {
        if (!open) {
            setOptions([]);
            setText('');
        }
    }, [open]);

    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;

        if (open && text.length > 2) {
            setLoading(true);

            loadSuggestions(signal);
        }

        return () => {
            controller.abort();
        };
    }, [open, text]);

    const loadSuggestions = async (signal) => {
        await sleep(DEBOUNCE_DELAY);

        const newOptions = await dataItem.getSuggestions(text, signal);

        if (!signal.aborted) {
            setOptions(newOptions?.data[dataItem.lookupField] || []);
            setLoading(false);
        }
    };

    const handleAddChip = (event, newValue) => {
        setSelectedOptions(newValue);
    };

    const handleDeleteChip = (chipToDelete) => () => {
        setSelectedOptions((chips) => chips.filter((chip) => chip !== chipToDelete));
    };

    return (
        <Autocomplete
            multiple
            freeSolo
            open={open}
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            value={selectedOptions}
            onChange={handleAddChip}
            inputValue={inputValue}
            onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
            }}
            options={options}
            renderTags={(value, getTagProps) =>
                value.map((option, index) => {
                    const tagProps = getTagProps({ index });
                    return (
                        <Chip
                            label={option}
                            {...tagProps}
                            className={classnames(tagProps.className)}
                            onDelete={handleDeleteChip(option)}
                            sx={{
                                backgroundColor: 'chips.background',
                                '&:hover': {
                                    backgroundColor: 'rgba(0, 0, 0, 0.07)',
                                },
                            }}
                        />
                    );
                })
            }
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={dataItem?.placeholder || 'Type to search...'}
                    placeholder={dataItem.placeholder || 'Type to search...'}
                    componentsProps={{
                        input: {
                            onChange: (e) => setText(e.target.value),
                            endAdornment: (
                                <>
                                    {loading && <CircularProgress color="inherit" size={20} />}
                                    {params.InputProps.endAdornment}
                                </>
                            ),
                        },
                        label: {
                            sx: {
                                color: 'grey.500',
                                '&.Mui-focused': {
                                    color: 'grey.500',
                                },
                            },
                        },
                    }}
                    variant="standard"
                />
            )}
            renderOption={(props, option) => <MenuItem {...props}>{option}</MenuItem>}
        />
    );
};
