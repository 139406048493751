import { getReportByIdAPI } from '@api/assetImportController';

import { changeStateByNestedKey } from './fugaActions';

function getReportById(id) {
    return async function (dispatch) {
        const resp = await getReportByIdAPI(id);

        if (
            (resp.data.errors && resp.data.errors.length > 0) ||
            (resp.data.warnings && resp.data.warnings.length > 0) ||
            (resp.data.success && resp.data.success.length > 0) ||
            (resp.data.cmoValidationWarnings && resp.data.cmoValidationWarnings.length > 0)
        ) {
            dispatch(changeStateByNestedKey('repertoires', 'uploadErrors', resp.data));
        }
        dispatch(changeStateByNestedKey('repertoires', 'errorDialogIsOpen', true));
    };
}

export { getReportById };
