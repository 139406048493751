import React, { useEffect, useState } from 'react';

import { Badge, Popover, Typography } from '@mui/material';
import { makeStyles, withStyles } from '@mui/styles';

import { STATUS_SUMMARY_LIST } from './RegStatusBarChart';

const useClasses = makeStyles(() => ({
    regStatPopover: {
        pointerEvents: 'none',
        '& .MuiPopover-paper': {
            width: '271px',
            height: 'auto',
            padding: '8px 16px',
            boxSizing: 'content-box',
            marginTop: '7px',
            pointerEvents: 'auto',
        },
    },
    row: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        padding: '13px 0',
    },
}));

export default function RegStatusNumbersPopUp({ isOpen, closeCb, anchorEl, dataItem }) {
    const classes = useClasses();
    const [open, setOpen] = useState(isOpen);
    const statusSummaryListFiltered = (dataItem.statusSummary || []).filter(
        (el) => el.registrationStatusName !== 'EXCLUSIVE_LICENSE_DEAL_REGISTERED',
    );

    useEffect(() => {
        setOpen(isOpen);
    }, [isOpen]);

    const handleClose = () => {
        setOpen(false);
        closeCb(false);
    };

    const BadgeCustomized = withStyles({
        root: {
            position: 'unset',
            '& .MuiBadge-badge': {
                color: (props) => props.contentColor,
                backgroundColor: (props) => props.bgColor,
                position: 'unset',
                transform: 'none',
            },
        },
    })(Badge);

    return (
        <Popover
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            className={classes.regStatPopover}
        >
            <Typography variant="subtitle2" color="primary" style={{ padding: '13px 0 11px 0' }}>
                CMO registrations
            </Typography>
            {statusSummaryListFiltered.map((statSumItem) => {
                const customColor =
                    STATUS_SUMMARY_LIST[statSumItem.registrationStatusName]?.backgroundColor;
                const contetnCustomColor =
                    STATUS_SUMMARY_LIST[statSumItem.registrationStatusName]?.color;
                const statusTypeName =
                    STATUS_SUMMARY_LIST[statSumItem.registrationStatusName]?.title;
                return (
                    <div className={classes.row}>
                        <BadgeCustomized
                            badgeContent={statSumItem.numberOfRegistrations}
                            bgColor={customColor}
                            contentColor={contetnCustomColor}
                        />
                        <Typography variant="body1" style={{ marginLeft: '16px' }}>
                            {statusTypeName}
                        </Typography>
                    </div>
                );
            })}
            {statusSummaryListFiltered.length === 0 && (
                <Typography variant="body1" className={classes.row}>
                    No contract added
                </Typography>
            )}
        </Popover>
    );
}
