import React, { useState } from 'react';

import { cleanLoginMessages } from '@actions/authActions';
import { Alert, Button, Link, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import TextFieldWrapper from '@MuiWrappers/TextFieldWrapper';
import { useDispatch, useSelector } from 'react-redux';

const NOT_VALID_EMAIL = 'Please enter a valid email.';

const ResetPasswordForm = ({ onResetPassword }) => {
    const [email, setEmail] = useState('');
    const [error, setError] = useState(false);
    const [helperText, setHelperText] = useState('');

    // this shouldn't be wired with redux.
    const dispatch = useDispatch();
    const errorMessage = useSelector((state) => state.fugaReducers.loginErrorMsg);
    const successMessage = useSelector((state) => state.fugaReducers.loginSuccessMsg);

    function onChangeUsername(evt) {
        const value = evt.target.value;
        dispatch(cleanLoginMessages());
        setEmail(value);
    }

    function onResetPasswordAction() {
        onResetPassword(email);
    }

    function handleSubmit(evt) {
        evt.preventDefault();
        onResetPasswordAction();
    }

    function handleKeyPress(evt) {
        if (evt.key.toString() === 'Enter') {
            if (email !== '') {
                setError(false);
                handleSubmit(evt);
            } else {
                setError(true);
                setHelperText(NOT_VALID_EMAIL);
            }
        }
    }

    return (
        <form name="resetPassword" onSubmit={handleSubmit} onKeyPress={handleKeyPress}>
            <Grid direction="column" spacing={3} container>
                <Grid>
                    <Typography variant="h5" align="justify">
                        Reset Password
                    </Typography>
                </Grid>
                <Grid>
                    <Typography variant="body2" align="left">
                        Enter your username and we will send you a link to reset your password
                    </Typography>
                </Grid>
                {errorMessage && (
                    <Grid>
                        <Alert severity="error" variant="outlined">
                            {errorMessage}
                        </Alert>
                    </Grid>
                )}
                {successMessage && (
                    <Grid>
                        <Alert severity="success" variant="outlined">
                            {successMessage}
                        </Alert>
                    </Grid>
                )}
                <Grid>
                    <TextFieldWrapper
                        error={error}
                        label="Username"
                        type="text"
                        fullWidth
                        value={email}
                        onChange={onChangeUsername}
                        helperText={error && helperText}
                    />
                </Grid>
                <Grid>
                    <Button
                        disabled={error}
                        fullWidth
                        disableElevation
                        variant="contained"
                        color="primary"
                        onClick={handleSubmit}
                        type="submit"
                    >
                        Request reset link
                    </Button>
                </Grid>
                <Grid>
                    <Link
                        href="/login"
                        variant="body2"
                        align="justify"
                        color="inherit"
                        style={{ marginBottom: 24 }}
                    >
                        Back to login page
                    </Link>
                </Grid>
            </Grid>
        </form>
    );
};

export default ResetPasswordForm;
