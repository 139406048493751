import React, { useState } from 'react';

import { cleanLoginMessages } from '@actions/authActions';
import { Alert, Button, Link, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import TextFieldWrapper from '@MuiWrappers/TextFieldWrapper';
import { useDispatch, useSelector } from 'react-redux';

const LoginForm = ({ onLogin }) => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    // this shouldn't be wired with redux.
    const errorMessage = useSelector((state) => state.fugaReducers.loginErrorMsg);
    const dispatch = useDispatch();

    function onChangeUsername(evt) {
        dispatch(cleanLoginMessages());
        setUsername(evt.target.value);
    }
    function onChangePassword(evt) {
        dispatch(cleanLoginMessages());
        setPassword(evt.target.value);
    }

    function handleSubmit(evt) {
        evt.preventDefault();
        onLogin(username, password);
    }

    return (
        <form name="login" onSubmit={handleSubmit}>
            <Grid direction="column" spacing={3} container>
                <Grid>
                    <Typography variant="h5" align="justify">
                        Log in
                    </Typography>
                </Grid>
                {errorMessage && (
                    <Grid>
                        <Alert severity="error" variant="outlined">
                            {errorMessage}
                        </Alert>
                    </Grid>
                )}
                <Grid>
                    <TextFieldWrapper
                        label="Username"
                        type="text"
                        fullWidth
                        value={username}
                        onChange={onChangeUsername}
                    />
                </Grid>
                <Grid>
                    <TextFieldWrapper
                        label="Password"
                        type="password"
                        fullWidth
                        value={password}
                        onChange={onChangePassword}
                    />
                </Grid>
                <Grid>
                    <Button
                        type="submit"
                        fullWidth
                        disableElevation
                        variant="contained"
                        color="primary"
                    >
                        Log in
                    </Button>
                </Grid>
                <Grid>
                    <Link
                        href="/reset_password"
                        variant="body2"
                        align="justify"
                        color="inherit"
                        style={{ marginBottom: 24 }}
                    >
                        Forgot your password?
                    </Link>
                </Grid>
                <Grid>
                    <Link
                        variant="body2"
                        align="justify"
                        color="inherit"
                        href="https://fuga.com/privacypolicy/"
                        target="_blank"
                    >
                        Privacy Policy
                    </Link>
                </Grid>
            </Grid>
        </form>
    );
};

export default LoginForm;
