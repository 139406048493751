import React, { useCallback, useEffect, useState } from 'react';

import {
    getAlertMsgByCmoRegId,
    getCmoConnectionsByClientId,
    updateNestedField,
} from '@actions/apiActions';
import { changeStateByNestedKey } from '@actions/fugaActions';
import { formatTerritoryCodes, getAllTerritories } from '@actions/territories';
import { changeRegistrationStatusAPI, deleteCmoRegistrationAPI } from '@api/cmoController';
import CommonConfirmationDialog from '@common/CommonConfirmationDialog';
import CommonDropDownChips, {
    ChipLabel,
    STATUSES_LIST,
    chipThemes,
} from '@common/CommonDropDownChips';
import CommonMoreDropDownButton from '@common/CommonMoreDropDownButton';
import CommonTableGrid from '@common/CommonTable/CommonTableGrid';
import CmoRegAlertsModal from '@common/modals/CmoRegAlertsModal';
import CmoRegCommentsModal from '@common/modals/CmoRegCommentsModal';
import NewCmoConnectionModal from '@common/modals/NewCmoConnectionModal/NewCmoConnectionModal';
import StatusHistoryModal from '@common/modals/StatusHistoryModal';
import OverflowTip from '@common/OverflowTip';
import ChatBubbleUnread from '@images/svgComponents/ChatBubbleUnreadSvg';
import InfoIconSmallBlueSvg from '@images/svgComponents/InfoIconSmallBlueSvg';
import PendingIconSvg from '@images/svgComponents/PendingIconSvg';
import WarningAmberSvg from '@images/svgComponents/WarningAmberSvg';
import WarningOrangeSvg from '@images/svgComponents/WarningOrangeSvg';
import {
    ChatBubbleOutline,
    Close,
    DeleteOutlineOutlined,
    Done,
    EditOutlined,
    ErrorOutline,
    ScheduleOutlined,
} from '@mui/icons-material';
import { IconButton, Tooltip } from '@mui/material';
import { makeStyles } from '@mui/styles';
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

const useClasses = makeStyles({
    content: {
        marginTop: '16px',
    },
    statusIcons: {
        width: '18px',
        height: '18px',
        marginRight: '21.25px',
    },
    flexHorizontal_s_b: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    activeIcon: {
        color: '#00A542',
    },
    inactiveIcon: {
        color: '#5F5F60',
    },
    pendingIcon: {
        color: '#FF8800',
    },
    statusIconBtns: {
        padding: '8px',
        '&:hover': {
            backgroundColor: 'unset',
        },
    },
    displayFlex: {
        display: 'flex',
    },
    alertIcons: {
        marginLeft: '4px',
    },
});

function ClientCmoConnectionTable() {
    const classes = useClasses();
    const dispatch = useDispatch();
    const params = useParams();
    const cmoConnectionModel = useSelector((state) => state.fugaReducers.cmoConnection);
    const rhDataModel = useSelector((state) => state.fugaReducers.rhData);
    const [anchorEl, setAnchorEl] = useState(false);
    const [showTerritoryModal, setShowTerritoryModal] = useState(false);
    const [territories, setTerritories] = useState([]);
    const territoriesModel = useSelector((state) => state.fugaReducers.territories);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [cmoRegId, setCmoRegId] = useState(null);
    const [statusChangeOptions, setStatusChangeOptions] = useState([]);
    const [isStatusDialogOpen, setIsStatusDialogOpen] = useState(false);
    const [openCommentsModal, setOpenCommentsModal] = useState(false);
    const [clickedDataItem, setClickedDataItem] = useState({});
    const [openAlertsModal, setOpenAlertsModal] = useState(false);

    useEffect(() => {
        dispatch(getCmoConnectionsByClientId(params.id));
    }, []);

    useEffect(() => {
        dispatch(getAllTerritories());
        setTerritories(territoriesModel.checkedTerritories);
    }, [territoriesModel.checkedTerritories]);

    const openModal = () => {
        setShowTerritoryModal(true);
    };

    const handleAlertClick = async (dataItem) => {
        setClickedDataItem(dataItem);
        await dispatch(getAlertMsgByCmoRegId(dataItem.cmoRegId));
        setOpenAlertsModal(true);
    };

    const handleHistoryClick = (evt, dataItem) => {
        dispatch(changeStateByNestedKey('cmoConnection', 'selectedRowDataItem', dataItem));
        setAnchorEl(evt.currentTarget);
    };

    const openHistoryInfo = Boolean(anchorEl);

    const handleHistoryClose = () => {
        setAnchorEl(null);
    };

    const headers = [
        {
            title: 'CMO',
            dataIndex: 'cmoName',
            fixedWidth: {
                isFixed: true,
                value: '100px',
            },
        },
        {
            title: 'Territories',
            dataIndex: 'territoriesToShow',
            fixedWidth: {
                isFixed: true,
                value: '100px',
            },
        },
        {
            title: 'Start Date',
            dataIndex: 'startDate',
        },
        {
            title: 'End Date',
            dataIndex: 'endDate',
        },
        {
            title: 'Registration Status',
            dataIndex: 'registrationStatus',
            customStyle: {
                width: '1.5vw',
                minWidth: 'unset',
                maxWidth: 'unset',
            },
        },
        {
            title: 'Last Modified',
            dataIndex: 'statusDate',
            fixedWidth: {
                isFixed: true,
                value: '100px',
            },
        },
        {
            title: '',
            dataIndex: 'commentPresented',
            hasIcon: true,
        },
    ];

    const deleteConnection = async () => {
        await deleteCmoRegistrationAPI(cmoRegId);
        await dispatch(getCmoConnectionsByClientId(params.id));
    };

    const updateStatusInRow = (response) => {
        const dataItemFromResp = response.data[0].id;
        dispatch(
            updateNestedField('cmoConnection', 'dataFromBE', dataItemFromResp.statusDate, {
                arrayName: 'content',
                idFieldName: 'cmoRegId',
                id: dataItemFromResp.clientCmoRegistrationId,
                fieldNameToChange: 'statusDate',
            }),
        );
        dispatch(
            updateNestedField('cmoConnection', 'dataFromBE', dataItemFromResp.newStatus, {
                arrayName: 'content',
                idFieldName: 'cmoRegId',
                id: dataItemFromResp.clientCmoRegistrationId,
                fieldNameToChange: 'registrationStatus',
            }),
        );
        dispatch(
            getCmoConnectionsByClientId(params.id, {
                isUpdateble: true,
                arrayName: 'content',
                idFieldName: 'cmoRegId',
                id: dataItemFromResp.clientCmoRegistrationId,
                fieldNameToChange: 'acceptableRegistrationStatuses',
            }),
        );
    };

    const statusChangeCb = async (status, dataItem, fromModal = false) => {
        if (cmoConnectionModel.isFirstToSubmit && !fromModal) {
            setStatusChangeOptions([status, dataItem]);
            setIsStatusDialogOpen(true);
        } else {
            const response = await changeRegistrationStatusAPI({
                clientCmoRegistrationId: dataItem.cmoRegId,
                newStatus: status,
            });

            updateStatusInRow(response);
        }
    };

    const handleCommentsClick = (e, dataItem) => {
        setClickedDataItem(dataItem);
        setOpenCommentsModal(true);
    };

    const moreOptionsSpecificData = useCallback((headerItem, dataItem) => {
        const coulumnTitle = headerItem.title.toLowerCase();
        if (coulumnTitle === '') {
            const menuItems = [
                {
                    title: 'Alert',
                    onClickClb: () => handleAlertClick(dataItem),
                    icon: <WarningAmberSvg />,
                },
                {
                    title:
                        dataItem.registrationStatus === 'EXCLUSIVE_LICENSE_DEAL' ? 'View' : ' Edit',
                    onClickClb: () => {
                        dispatch(formatTerritoryCodes(dataItem.territoriesCodes));
                        dispatch(changeStateByNestedKey('rhData', 'selectedRowDataItem', dataItem));
                        dispatch(changeStateByNestedKey('rhData', 'selectedCmoId', dataItem.cmoId));
                        openModal();
                    },
                    icon: (
                        <EditOutlined
                            style={{ width: '24px', height: '24px', color: '#00000099' }}
                        />
                    ),
                },
                {
                    title: 'Delete',
                    onClickClb: () => {
                        setIsDialogOpen(true);
                        setCmoRegId(dataItem.cmoRegId);
                    },
                    isDisabled: dataItem.lockedForDelete,
                    tooltip: {
                        visible: dataItem.lockedForDelete,
                        text:
                            dataItem.registrationStatus !== 'EXCLUSIVE_LICENSE_DEAL'
                                ? 'This CMO registration cannot be deleted as registration action(s) already taken'
                                : 'This CMO Registration cannot be edited or deleted as it is under an exclusive license claim party',
                    },
                    icon: (
                        <DeleteOutlineOutlined
                            style={{ width: '24px', height: '24px', color: '#00000099' }}
                        />
                    ),
                },
            ];
            return (
                <>
                    <IconButton
                        onClick={(e) => handleHistoryClick(e, dataItem)}
                        className={classes.statusIconBtns}
                        disableRipple
                        size="large"
                    >
                        <ScheduleOutlined style={{ width: '18px', height: '18px' }} />
                    </IconButton>
                    <IconButton
                        onClick={(e) => handleCommentsClick(e, dataItem)}
                        className={classes.statusIconBtns}
                        disableRipple
                        size="large"
                    >
                        {dataItem.commentPresented ? (
                            <ChatBubbleUnread style={{ width: '18px', height: '18px' }} />
                        ) : (
                            <ChatBubbleOutline style={{ width: '18px', height: '18px' }} />
                        )}
                    </IconButton>
                    <CommonMoreDropDownButton menuItems={menuItems} />
                </>
            );
        } else if (coulumnTitle.includes('start date')) {
            const status = dataItem.status.toLowerCase();
            const StatusIcon = () => {
                if (status === 'active') {
                    return <Done className={`${classes.statusIcons} ${classes.activeIcon}`} />;
                } else if (status === 'inactive') {
                    return <Close className={`${classes.statusIcons} ${classes.inactiveIcon}`} />;
                } else {
                    return <PendingIconSvg styles={classes.statusIcons} />;
                }
            };
            return (
                <OverflowTip className={classes.flexHorizontal_s_b}>
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        <StatusIcon />
                        {dataItem[headerItem.dataIndex]}
                    </div>
                </OverflowTip>
            );
        } else if (coulumnTitle.includes('registration status') && dataItem.registrationStatus) {
            const menuItemsList = (dataItem.acceptableRegistrationStatuses || [])
                .filter((status) => Object.keys(STATUSES_LIST).includes(status))
                .map((status) => ({
                    title: status.split('_').join(' '),
                    onClickClb: statusChangeCb,
                    CbArgs: [status, dataItem],
                    color: STATUSES_LIST[status].color,
                }));

            return (
                <div className={classes.displayFlex}>
                    {dataItem[headerItem.dataIndex] === 'EXCLUSIVE_LICENSE_DEAL' ? (
                        <ChipLabel label="EXCLUSIVE LICENSE" style={chipThemes.greenChip} />
                    ) : (
                        <CommonDropDownChips
                            chipTitle={dataItem[headerItem.dataIndex].split('_').join(' ')}
                            menuItemsList={menuItemsList}
                            hasAlerts={dataItem?.alertCategories}
                        />
                    )}
                    <div
                        className={classes.displayFlex}
                        style={{ marginLeft: '3px' }}
                        onClick={() => handleAlertClick(dataItem)}
                    >
                        {(dataItem.alertCategories || []).includes('INTERNAL') && (
                            <Tooltip title="Internal action required">
                                <div>
                                    <ErrorOutline
                                        style={{ width: '18px', height: '18px', fill: '#F44139' }}
                                        className={classes.alertIcons}
                                    />
                                </div>
                            </Tooltip>
                        )}
                        {(dataItem.alertCategories || []).includes('CLIENT') && (
                            <Tooltip title="Client action required">
                                <div>
                                    <WarningOrangeSvg styles={classes.alertIcons} />
                                </div>
                            </Tooltip>
                        )}
                        {(dataItem.alertCategories || []).includes('CMO') && (
                            <Tooltip title="Awaiting CMO action">
                                <div>
                                    <InfoIconSmallBlueSvg styles={classes.alertIcons} />
                                </div>
                            </Tooltip>
                        )}
                    </div>
                </div>
            );
        } else if (coulumnTitle.includes('last modified')) {
            return (
                <OverflowTip>
                    {moment(dataItem[headerItem.dataIndex]).format('YYYY-MM-DD')}
                </OverflowTip>
            );
        } else {
            return <OverflowTip>{dataItem[headerItem.dataIndex]}</OverflowTip>;
        }
    });

    // Right Holder CMO Tab on /client-page/id
    return (
        <div className={classes.content}>
            <CommonTableGrid
                dataList={cmoConnectionModel.dataFromBE}
                headerList={headers}
                cellDataCb={moreOptionsSpecificData}
                loading={cmoConnectionModel.tableIsLoading}
                preferences={{
                    loadDataByPageCb: {
                        dispatchFn: dispatch,
                        callbackFn: getCmoConnectionsByClientId,
                        argumentsList: [params.id],
                    },
                }}
                initialRowsPerPage={50}
                selection={{
                    isSelectable: false,
                }}
            />
            {showTerritoryModal && (
                <NewCmoConnectionModal
                    showTerritoryModal={showTerritoryModal}
                    setShowTerritoryModal={setShowTerritoryModal}
                    territories={territories}
                    setTerritories={setTerritories}
                    groupedTerritories={territoriesModel.groupedTerritories}
                    clientId={params.id}
                    isEditing={true}
                    customData={{
                        // This is edit
                        dealsContainerName: 'clientDealDTO',
                        selectedCmoStoreName: 'rhData',
                        dataModel: rhDataModel.detailsData,
                        selectedCmoId: rhDataModel.selectedCmoId,
                        modalValidations: rhDataModel.modalValidations,
                        selectedRowDataItem: rhDataModel.selectedRowDataItem,
                        isPerformer: { isRH: true },
                        onTableReload: {
                            reloadCb: getCmoConnectionsByClientId,
                            params: [params.id],
                        },
                        cmoIssuedIds: rhDataModel.cmoIssuedIds,
                        hasIssuedId: rhDataModel.hasIssuedId,
                    }}
                />
            )}
            <CommonConfirmationDialog
                isOpen={isDialogOpen}
                closeCb={setIsDialogOpen}
                title="Delete CMO for this client?"
                description="Are you sure you want to delete this CMO row for this client?"
                onConfirmCb={deleteConnection}
            />
            {isStatusDialogOpen && (
                <CommonConfirmationDialog
                    isOpen={isStatusDialogOpen}
                    closeCb={setIsStatusDialogOpen}
                    title="Begin Client CMO Registration?"
                    description="Once this registration is 'Submitted' then you will not be able to edit the Client Deal, do you want to proceed?"
                    onConfirmCb={statusChangeCb}
                    confirmCbArgs={[...statusChangeOptions, true]}
                />
            )}
            <StatusHistoryModal
                anchorEl={anchorEl}
                onClose={handleHistoryClose}
                isOpen={openHistoryInfo}
                selectedRowDataItem={cmoConnectionModel.selectedRowDataItem}
                storeName="cmoConnection"
                contentData={cmoConnectionModel.registrationHistory}
            />
            <CmoRegCommentsModal
                isOpen={openCommentsModal}
                setOpen={setOpenCommentsModal}
                dataItem={clickedDataItem}
                onSuccessCb={() => {
                    dispatch(
                        getCmoConnectionsByClientId(params.id, {
                            isUpdateble: true,
                            arrayName: 'content',
                            idFieldName: 'cmoRegId',
                            id: clickedDataItem.cmoRegId,
                            fieldNameToChange: 'commentPresented',
                        }),
                    );
                }}
            />
            <CmoRegAlertsModal
                isOpen={openAlertsModal}
                setOpen={setOpenAlertsModal}
                dataItem={clickedDataItem}
            />
        </div>
    );
}
export default ClientCmoConnectionTable;
