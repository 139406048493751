import React, { useEffect } from 'react';

import { changeStateByNestedKey, loadAllCmos } from '@actions/fugaActions';
import CommonDropDownInput from '@common/CommonDropDownInput';
import { Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import TextFieldWrapper from '@MuiWrappers/TextFieldWrapper';
import { useDispatch, useSelector } from 'react-redux';

const NewCmoConnectionDatesAndClaimMethods = ({
    isEditing,
    selectedStartDate,
    handleStartDateChange,
    selectedEndDate,
    handleEndDateChange,
    errorOperations,
    dateErrMsg,
    isPerformer,
    selectedCmoId,
    cmoIssuedIds,
    issuedId,
    setIssuedId,
    storeName,
    isEndDateDisabled,
    isStartDateDisabled,
    claimMethodDefault,
}) => {
    const dispatch = useDispatch();
    const cmosModel = useSelector((state) => state.fugaReducers.cmos);

    useEffect(() => {
        if (!isEditing) {
            handleStartDateChange(new Date());
            handleEndDateChange(new Date());
        }
    }, [isEditing]);

    useEffect(() => {
        dispatch(loadAllCmos(isPerformer));
    }, []);

    useEffect(() => {
        const foundCmo = cmoIssuedIds.find((el) => {
            return el.cmoId === selectedCmoId;
        });
        if (foundCmo) {
            setIssuedId(foundCmo.cmoIssuedIdValue);
            dispatch(changeStateByNestedKey(storeName, 'hasIssuedId', true));
        } else {
            setIssuedId('');
            dispatch(changeStateByNestedKey(storeName, 'hasIssuedId', false));
        }
    }, [selectedCmoId]);

    const handleIssuedIdChange = (evt) => {
        setIssuedId(evt.currentTarget.value);
    };

    const menuItemsList = cmosModel.dataFromBE.map((cmo) => ({
        title: `${cmo.cmoName} ${cmo.hometerritoryFullname}`,
        cmoId: cmo.cmoId,
    }));

    return (
        <Grid container rowSpacing={1} columnSpacing={4}>
            <Grid size={6}>
                <CommonDropDownInput
                    menuItemsList={menuItemsList}
                    isEditing={isEditing}
                    storeName={storeName}
                />
            </Grid>
            <Grid size={6} />
            <Grid size={6}>
                <TextFieldWrapper
                    label="CMO issued ID"
                    onChange={(evt) => handleIssuedIdChange(evt)}
                    name="cmoIssuedId"
                    type="text"
                    fullWidth
                    value={issuedId}
                    style={{ margin: '0 0 32px 0' }}
                    isDisabled={storeName === 'rhData'}
                    InputProps={{
                        readOnly: storeName === 'rhData',
                    }}
                />
            </Grid>
            <Grid size={6} />
            <Grid size={6}>
                <Typography variant="body2" style={{ margin: '0 14px 8.5px 0', display: 'inline' }}>
                    Claim method
                </Typography>
                <Typography variant="caption">{claimMethodDefault}</Typography>
            </Grid>
            <Grid size={6}></Grid>
            <Grid size={3}>
                <DatePicker
                    label="Start Date"
                    fullWidth
                    value={selectedStartDate || null}
                    onChange={(date) => handleStartDateChange(date)}
                    format="dd/MM/yyyy"
                    disabled={isStartDateDisabled}
                    renderInput={(params) => (
                        <TextFieldWrapper
                            {...params}
                            fullWidth
                            helperText={errorOperations.dateError.start?.error ? dateErrMsg : ''}
                            error={errorOperations.dateError.start?.error}
                        />
                    )}
                />
            </Grid>
            <Grid size={3}>
                <DatePicker
                    label="End Date"
                    value={selectedEndDate || null}
                    onChange={(date) => handleEndDateChange(date)}
                    format="dd/MM/yyyy"
                    disabled={isEndDateDisabled}
                    renderInput={(params) => (
                        <TextFieldWrapper
                            {...params}
                            fullWidth
                            helperText={errorOperations.dateError.end?.error ? dateErrMsg : ''}
                            error={errorOperations.dateError.end?.error}
                        />
                    )}
                />
            </Grid>
        </Grid>
    );
};

export default NewCmoConnectionDatesAndClaimMethods;
