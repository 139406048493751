import React, { useState } from 'react';

import { Box, Button, ClickAwayListener, Popover } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import TextFieldWrapper from '@MuiWrappers/TextFieldWrapper';

const useStyles = makeStyles((theme) => ({
    box: {
        padding: '24px',
        width: '400px',
        minHeight: '200px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    actionBtns: {
        marginTop: '32px',
        display: 'flex',
        justifyContent: 'flex-end',
        '& > *': {
            marginLeft: '24px',
        },
    },
}));

export const FilterMenuDialogWithDatepicker = ({ onConfirm, anchorEl, closeCb, isOpen }) => {
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    const classes = useStyles();

    const handleClose = () => {
        closeCb(false);
    };

    const handleConfirm = () => {
        const parsedStartDate = startDate ? startDate.toISOString().split('T')[0] : '';
        const parsedEndDate = endDate ? endDate.toISOString().split('T')[0] : '';
        onConfirm({
            valuesToShow: [parsedStartDate, parsedEndDate],
            valuesToSend: {
                lastModifiedAfterDate: parsedStartDate,
                lastModifiedBeforeDate: parsedEndDate,
            },
        });
        handleClose();
    };

    return (
        <Popover
            open={isOpen}
            anchorEl={anchorEl}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
        >
            <ClickAwayListener onClickAway={handleClose}>
                <Box className={classes.box}>
                    <DatePicker
                        label="Modified start date"
                        format="MM/dd/yyyy"
                        value={startDate}
                        onChange={setStartDate}
                        renderInput={(params) => (
                            <TextFieldWrapper
                                {...params}
                                fullWidth
                                aria-label="change start date"
                            />
                        )}
                    />
                    <DatePicker
                        label="Modified end date"
                        format="MM/dd/yyyy"
                        value={endDate}
                        onChange={setEndDate}
                        renderInput={(params) => (
                            <TextFieldWrapper {...params} fullWidth aria-label="change end date" />
                        )}
                    />
                    <div className={classes.actionBtns}>
                        <Button
                            onClick={handleClose}
                            variant="text"
                            color="primary"
                            disableElevation
                        >
                            cancel
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            disableElevation
                            onClick={handleConfirm}
                            disabled={!startDate || !endDate}
                        >
                            apply
                        </Button>
                    </div>
                </Box>
            </ClickAwayListener>
        </Popover>
    );
};
