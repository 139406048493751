import React, { useEffect, useState } from 'react';

import { Backdrop, CircularProgress } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
    loaderBackground: {
        zIndex: theme.zIndex.drawer + 1,
        backgroundColor: 'rgb(255,255,255,0.5)',
    },
}));

export default function FullScreenLoader() {
    const [open, setOpen] = useState(false);
    const classes = useStyles();
    const isFullScreenLoaderActive = useSelector(
        (state) => state.fugaReducers.isFullScreenLoaderActive,
    );

    useEffect(() => {
        if (isFullScreenLoaderActive <= 0) {
            setOpen(false);
        } else {
            setOpen(true);
        }
    }, [isFullScreenLoaderActive]);

    return (
        <Backdrop className={classes.loaderBackground} open={open}>
            <CircularProgress color="inherit" />
        </Backdrop>
    );
}
