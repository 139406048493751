import React from 'react';

import { getAlertMsgByCmoRegId, getCmoConnectionsByClientId } from '@actions/apiActions';
import {
    createAlertMsgByCmoRegIdAPI,
    resolveAlertMsgByCmoRegIdAPI,
    updateAlertMsgByCmoRegIdAPI,
} from '@api/alertController';
import { ErrorOutline } from '@mui/icons-material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import AlertsModal from './AlertsModal';

export default function CmoRegAlertsModal({ isOpen, setOpen, dataItem }) {
    const dispatch = useDispatch();
    const params = useParams();
    const cmoConnectionModel = useSelector((state) => state.fugaReducers.cmoConnection);

    const onClose = () => {
        setOpen(false);
        setTimeout(() => {
            dispatch(
                getCmoConnectionsByClientId(params.id, {
                    isUpdateble: true,
                    arrayName: 'content',
                    idFieldName: 'cmoRegId',
                    id: dataItem.cmoRegId,
                    fieldNamesListToChange: ['alertCategories', 'statusDate'],
                }),
            );
        }, 0);
    };

    const onUpdateAlert = async (alertData) => {
        await updateAlertMsgByCmoRegIdAPI(alertData);
        await dispatch(getAlertMsgByCmoRegId(dataItem.cmoRegId));
    };

    const onResolveAlert = async (alertId) => {
        await resolveAlertMsgByCmoRegIdAPI(alertId);
        await dispatch(getAlertMsgByCmoRegId(dataItem.cmoRegId));
    };

    const onCreateAlert = async (alertData) => {
        await createAlertMsgByCmoRegIdAPI(alertData);
    };

    const menuItemsList = [
        {
            title: 'Internal action required',
            beValue: 'INTERNAL',
            coloredIcon: () => (
                <ErrorOutline sx={{ width: '18px', height: '18px', fill: '#F44139' }} />
            ),
            color: '#F44139',
        },
        {
            title: 'Client action required',
            beValue: 'CLIENT',
            coloredIcon: () => (
                <WarningAmberOutlinedIcon sx={{ width: '18px', height: '18px', fill: '#FF8800' }} />
            ),
            color: '#FF8800',
        },
        {
            title: 'Awaiting CMO action',
            beValue: 'CMO',
            coloredIcon: () => (
                <InfoOutlinedIcon sx={{ width: '18px', height: '18px', fill: '#45A2DD' }} />
            ),
            color: '#45A2DD',
        },
    ];

    return (
        <AlertsModal
            isOpen={isOpen}
            onClose={onClose}
            dataItem={dataItem}
            alerts={cmoConnectionModel.alertMsgs}
            onUpdateAlert={onUpdateAlert}
            onResolveAlert={onResolveAlert}
            onCreateAlert={onCreateAlert}
            menuItemsList={menuItemsList}
            getAlertId={(alert) => alert.regAlertId}
            getAlertDate={(alert) => alert.date}
            getAlertCategory={(alert) => alert.alertCategory}
            getAlertMsgProp={(alert) => alert.alertMsg}
            getAlertState={(alert) => alert.alertState}
            dataItemIdKey="cmoRegId"
            getAlertsData={() => dispatch(getAlertMsgByCmoRegId(dataItem.cmoRegId))}
        />
    );
}
