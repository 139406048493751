import React from 'react';

import TextField from '@mui/material/TextField';

export default function TextFieldWrapper(props) {
    const { classNameOpt, isDisabled, ...textFieldProps } = props;
    const isSelectField = textFieldProps.select;

    return (
        <TextField
            variant="standard"
            helperText=" "
            {...textFieldProps}
            className={classNameOpt}
            disabled={isDisabled || textFieldProps.disabled}
            slotProps={{
                inputLabel: {
                    ...textFieldProps.InputLabelProps,
                    shrink: isSelectField || Boolean(textFieldProps.value),
                },
            }}
        />
    );
}
