import React, { useCallback, useEffect, useState, useMemo } from 'react';

import { validateAssetMetadataById } from '@actions/apiActions';
import { changeStateByNestedKey } from '@actions/fugaActions';
import { getRHAssetsById } from '@actions/rightHolders';
import AssetMetaDataEditModal from '@common/AssetMetaDataEdit/AssetMetaDataEditModal';
import CommonFiltersContainer from '@common/CommonFiltersContainer';
import CommonMoreDropDownButton from '@common/CommonMoreDropDownButton';
import CommonSearchInput from '@common/CommonSearchInput';
import CommonTableGrid from '@common/CommonTable/CommonTableGrid';
import DropDownButton from '@common/DropDownButton';
import LoadingScreen from '@common/LoadingScreen';
import OverflowTip from '@common/OverflowTip';
import TableFilters from '@common/TableFilters';
import { items } from '@data/constants';
import useAssetData from '@hooks/useAssetData';
import { useInfoPopover } from '@hooks/useInfoPopover';
import { EditOutlined } from '@mui/icons-material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Button, Popover, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { styled } from '@mui/material/styles';
import helper from '@utils/helper';
import utils from '@utils/utils';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';

const HelpIcon = styled(InfoOutlinedIcon)(({ theme }) => ({
    width: '20px',
    height: '20px',
    display: 'flex',
    marginLeft: theme.spacing(1),
    cursor: 'pointer',
}));

const InfoPopover = styled(Popover)(({ theme }) => ({
    '& .MuiPopover-paper': {
        width: '444px',
        padding: theme.spacing(2),
        boxSizing: 'content-box',
        marginTop: theme.spacing(1),
    },
}));

const createHeader = (handleInfoClick) => [
    {
        title: 'Artist(s)',
        dataIndex: 'artistList',
    },
    {
        title: 'Track / Version',
        dataIndex: ['trackTitle', 'versionTitle'],
    },
    {
        title: 'ISRC',
        dataIndex: 'isrcCode',
    },
    {
        title: 'PYEAR',
        dataIndex: 'assetPYear',
        hasNumericData: true,
    },
    {
        title: 'Product',
        dataIndex: 'albumTitle',
    },
    {
        title: 'Territories',
        dataIndex: 'ownershipTerritories',
        additionalHeaderContent: {
            headerCallBack: (headerItem) => (
                <HelpIcon onClick={(e) => handleInfoClick(e, headerItem)} />
            ),
        },
    },
    {
        title: 'Start Date',
        dataIndex: 'ownershipStartDate',
        additionalHeaderContent: {
            headerCallBack: (headerItem) => (
                <HelpIcon onClick={(e) => handleInfoClick(e, headerItem)} />
            ),
        },
    },
    ...(utils.checkItemRolesAndPermissions(items.tableColumns.repertoireEndDate)
        ? [
              {
                  title: 'End Date',
                  dataIndex: 'ownershipEndDate',
              },
          ]
        : []),
    ...(utils.checkItemRolesAndPermissions(items.tableColumns.lastModified)
        ? [
              {
                  title: 'Last modified',
                  dataIndex: 'lastModified',
              },
          ]
        : []),
    ...(utils.checkItemRolesAndPermissions(items.tableColumns.ingested)
        ? [
              {
                  title: 'Ingested',
                  dataIndex: 'createdDate',
              },
          ]
        : []),
    {
        title: '',
        dataIndex: '',
    },
];

function Repertoires(props) {
    const dispatch = useDispatch();

    const getPopoverContent = (headerItem) => ({
        title: headerItem.title,
        text:
            headerItem.title === 'Territories'
                ? 'These are the territories of rights holder ownership of the asset, these may be different to the territories of the deal terms.'
                : 'These are the dates of rights holder ownership of the asset, these may be different to the dates of the deal terms.',
    });

    const { anchorEl, popoverContent, handleInfoClick, handleInfoClose, isOpen } =
        useInfoPopover(getPopoverContent);
    const [openAssetMetadataModal, setOpenAssetMetadataModal] = useState(false);
    const [clickedDataItem, setClickedDataItem] = useState({});

    const queryParams = useSelector((state) => state.fugaReducers.queryParams);

    const pageModelName = 'repertoires';
    const fetchAssetsFunction = getRHAssetsById;

    const {
        getAssetData,
        handlePaginationAndSearch,
        handleSearch,
        clearSearch,
        pageModel: repertoiresModel,
    } = useAssetData({
        match: props.match,
        fetchAssetsFunction,
        pageModelName,
    });

    useEffect(() => {
        getAssetData();
    }, [repertoiresModel]);

    const openModal = () => {
        setOpenAssetMetadataModal(true);
    };

    const onFilterClearAll = () => {
        getAssetData();
    };

    const getClientSpecificData = useCallback(
        (headerItem, dataItem) => {
            const columnTitle = headerItem.title.toLowerCase();
            if (columnTitle.includes('track')) {
                return (
                    <>
                        <Typography variant="body2">
                            <OverflowTip>{dataItem[headerItem.dataIndex[0]]}</OverflowTip>
                        </Typography>
                        <Typography variant="caption">
                            <OverflowTip>{dataItem[headerItem.dataIndex[1]]}</OverflowTip>
                        </Typography>
                    </>
                );
            } else if (columnTitle === '') {
                const menuItems = [
                    {
                        title: 'Track details',
                        onClickClb: () => {
                            setClickedDataItem(dataItem);
                            openModal();
                        },
                        icon: (
                            <EditOutlined
                                sx={{ width: '24px', height: '24px', color: 'rgba(0, 0, 0, 0.6)' }}
                            />
                        ),
                    },
                ];
                return <CommonMoreDropDownButton menuItems={menuItems} />;
            } else {
                return <OverflowTip>{dataItem[headerItem.dataIndex]}</OverflowTip>;
            }
        },
        [setClickedDataItem, openModal],
    );

    const handleExportRepertoire = () => {
        helper.exportRepertoire(props.match.params.id, dispatch);
    };

    const memoizedHeaders = useMemo(() => createHeader(handleInfoClick), [handleInfoClick]);

    return (
        <div style={{ padding: '32px 0' }}>
            <Grid
                container
                spacing={2}
                sx={{
                    marginBottom: 2,
                    height: '35px',
                }}
            >
                <Grid
                    size={8}
                    display="flex"
                    alignItems="center"
                    sx={{
                        height: '100%',
                    }}
                >
                    <CommonSearchInput
                        placeHolderText="Search in repertoire"
                        onSearch={handleSearch}
                        term=""
                        onClearCb={clearSearch}
                    />
                </Grid>
                <Grid size={4} display="flex" justifyContent="flex-end">
                    <Button
                        variant="outlined"
                        onClick={handleExportRepertoire}
                        sx={{
                            height: '35px',
                        }}
                    >
                        EXPORT REPERTOIRE
                    </Button>
                    {utils.checkItemRolesAndPermissions(items.btns.rhRepertoireAddFromSource) && (
                        <DropDownButton />
                    )}
                </Grid>
            </Grid>
            <CommonFiltersContainer
                innerFiltersComponent={
                    <TableFilters tableReloadCb={getAssetData} onClearAll={onFilterClearAll} />
                }
            />
            <CommonTableGrid
                dataList={repertoiresModel.dataFromBE}
                headerList={memoizedHeaders}
                cellDataCb={getClientSpecificData}
                loading={repertoiresModel.tableIsLoading}
                sortPrefs={{
                    storeName: 'queryParams',
                    storeSubName: 'sort',
                    value: queryParams.sort,
                }}
                preferences={{
                    loadDataByPageCb: {
                        dispatchFn: dispatch,
                        callbackFn: handlePaginationAndSearch,
                        argumentsList: [{}],
                        noDispatch: true,
                    },
                }}
                paginationBE
                selection={{
                    isSelectable: false,
                    dispatchFn: dispatch,
                    callbackFn: changeStateByNestedKey,
                }}
            />
            <InfoPopover
                open={isOpen}
                anchorEl={anchorEl}
                onClose={handleInfoClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                transformOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Typography variant="subtitle1" sx={{ marginBottom: 1 }}>
                    {popoverContent.title}
                </Typography>
                <Typography variant="body2">{popoverContent.text}</Typography>
            </InfoPopover>
            <LoadingScreen />
            {openAssetMetadataModal && (
                <AssetMetaDataEditModal
                    isOpen={openAssetMetadataModal}
                    setOpen={setOpenAssetMetadataModal}
                    dataItem={clickedDataItem}
                    onSuccessCb={validateAssetMetadataById}
                    isPerformer={false}
                />
            )}
        </div>
    );
}

export default withRouter(Repertoires);
