import React from 'react';

import { downloadStatementsByIdsAPI, downloadStatementsGuideAPI } from '@api/statementsController';
import DownloadIconStatementsSvg from '@images/svgComponents/DownloadIconStatementsSvg';
import StatementsGuideSvg from '@images/svgComponents/StatementsGuideSvg';
import { Button } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';

const useClasses = makeStyles(() => ({
    downloadStatementsIcon: {
        marginRight: '8px',
    },
    downloadStatementsIconEnabled: {
        '& svg': {
            fill: '#FFFFFF',
        },
    },
    downloadStatementsIconDisabled: {
        '& svg': {
            fill: '#A6A6A6',
        },
    },
}));

const StatementSourceEnum = {
    RASA: 'RASA',
    CURVE: 'CURVE',
};

export default function StatementsBtnGroup({ selectedFiles, clientId }) {
    const classes = useClasses();

    const downloadStatements = async () => {
        const statementIds = selectedFiles
            .filter((file) => file.statementSource === StatementSourceEnum.RASA)
            .map((file) => file.id);

        const curveStatementIds = selectedFiles
            .filter((file) => file.statementSource === StatementSourceEnum.CURVE)
            .map((file) => file.id);

        await downloadStatementsByIdsAPI({ clientId, statementIds, curveStatementIds });
    };

    const downloadStatementGuide = async () => {
        await downloadStatementsGuideAPI();
    };

    return (
        <Grid container>
            <Grid size={8} />
            <Grid
                size={4}
                sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                    padding: '16px 0',
                }}
            >
                <Button
                    variant="text"
                    disableRipple
                    disableElevation
                    onClick={downloadStatementGuide}
                    sx={{ marginRight: '16px', color: '#000', fontWeight: 'bold' }}
                >
                    <StatementsGuideSvg
                        style={{
                            marginRight: '8px',
                        }}
                    />
                    PDF GUIDE
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    disableRipple
                    disableElevation
                    disabled={selectedFiles.length === 0}
                    onClick={downloadStatements}
                >
                    <DownloadIconStatementsSvg
                        styles={classNames(classes.downloadStatementsIcon, {
                            [classes.downloadStatementsIconEnabled]: selectedFiles.length > 0,
                            [classes.downloadStatementsIconDisabled]: selectedFiles.length === 0,
                        })}
                    />
                    STATEMENTS
                </Button>
            </Grid>
        </Grid>
    );
}
