import React from 'react';

import { CellsForFullTable } from '@common/CommonTable/CommonTableComponents';
import OverflowTip from '@common/OverflowTip';
import { Checkbox, TableCell, TableRow, Typography } from '@mui/material';
import { findDataIndex } from '@utils/commonTableUtils';

const updateList = (dataArray, value, selectedIndex) => {
    let newSelected = [];

    if (selectedIndex === -1) {
        newSelected = newSelected.concat(dataArray, value);
    } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(dataArray.slice(1));
    } else if (selectedIndex === dataArray.length - 1) {
        newSelected = newSelected.concat(dataArray.slice(0, -1));
    } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
            dataArray.slice(0, selectedIndex),
            dataArray.slice(selectedIndex + 1),
        );
    }
    return newSelected;
};

const CommonTableRow = React.memo((props) => {
    const {
        headerList,
        dataItem,
        cellDataCb,
        selected,
        setSelected,
        selection,
        excluded,
        setExcluded,
        downloadAllCmoAssets,
        onRowClick,
        storeName,
        isInSubModal,
    } = props;

    const getDataForCell = (headerItem) => {
        if (cellDataCb) {
            return cellDataCb(headerItem, dataItem);
        } else {
            return (
                <Typography variant="body2">
                    <OverflowTip>
                        {dataItem[headerItem.dataIndex] ||
                            (dataItem[headerItem.dataIndex] === 0 ? '0' : '')}
                    </OverflowTip>
                </Typography>
            );
        }
    };

    const getValue = (value) => (selection.fullRowData ? value : value[selection.filter]);

    const handleCheckBoxClick = (e, value) => {
        const indexExcluded = findDataIndex(
            selection.filter,
            excluded,
            dataItem,
            selection.fullRowData,
        );
        const indexSelected = findDataIndex(
            selection.filter,
            selected,
            dataItem,
            selection.fullRowData,
        );

        if (e.target.checked) {
            if (indexExcluded !== -1) {
                setExcluded(updateList(excluded, getValue(value), indexExcluded));
            }
            if (indexSelected === -1) {
                setSelected(updateList(selected, getValue(value), indexSelected));
            }
        } else {
            if (indexSelected !== -1) {
                setSelected(updateList(selected, getValue(value), indexSelected));
            }
            if (indexExcluded === -1) {
                setExcluded(updateList(excluded, getValue(value), indexExcluded));
            }
        }

        selection.dispatchFn(
            selection.callbackFn(storeName, 'downloadAllCmoAssets', {
                requestFlag: downloadAllCmoAssets.requestFlag,
                localSelectAll: false,
            }),
        );
    };

    const isSelected = () =>
        findDataIndex(selection.filter, selected, dataItem, selection.fullRowData) !== -1;

    const handleRowClick = () => {
        if (onRowClick?.onRowClickCb)
            onRowClick.onRowClickCb(dataItem[onRowClick.rowDataField], dataItem);
    };

    const RowCheckbox = () => (
        <TableCell sx={{ padding: '16px' }} size="small">
            <Checkbox
                sx={{ padding: 0 }}
                color="primary"
                checked={isSelected()}
                onClick={(event) => handleCheckBoxClick(event, dataItem)}
            />
        </TableCell>
    );

    const checkFixedWidth = (header) => {
        let widthConf = {};
        if (header.fixedWidth?.isFixed) widthConf.width = header.fixedWidth?.value;
        if (header.customStyle) widthConf = { ...header.customStyle };
        return widthConf;
    };

    return (
        <TableRow
            sx={{
                '&:hover > *': {
                    cursor: 'pointer',
                    backgroundColor: '#F4F4F6',
                },
                '& td': {
                    border: 'none',
                    backgroundColor: '#FFFFFF',
                },
            }}
            onClick={handleRowClick}
        >
            {selection.isSelectable && <RowCheckbox />}
            {headerList.map((header, ind) => (
                <TableCell
                    key={`${ind}-${header.dataIndex}`}
                    sx={[
                        {
                            minWidth: '30px',
                            ...checkFixedWidth(header),
                            textAlign:
                                header.hasNumericData && !header.numericHeaderOnly
                                    ? 'right'
                                    : header.hasIcon
                                      ? 'center'
                                      : 'left',
                        },
                        isInSubModal
                            ? {
                                  maxWidth: '129px',
                              }
                            : {
                                  maxWidth: header.wideTableCell ? '200px' : '180px',
                              },
                    ]}
                >
                    {getDataForCell(header)}
                </TableCell>
            ))}
            <CellsForFullTable headerList={headerList} />
        </TableRow>
    );
});

export default CommonTableRow;
