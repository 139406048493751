import React, { useCallback, useState } from 'react';

import { deleteContribution, getAssetMetadataById } from '@actions/apiActions';
import CommonConfirmationDialog from '@common/CommonConfirmationDialog';
import CommonTableGrid from '@common/CommonTable/CommonTableGrid';
import OverflowTip from '@common/OverflowTip';
import { DeleteOutline, EditOutlined } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import { Button, FormControlLabel, Radio, RadioGroup, Typography } from '@mui/material';
import { Alert } from '@mui/material';
import { makeStyles } from '@mui/styles';
import TextFieldWrapper from '@MuiWrappers/TextFieldWrapper';
import { ASSET_METADATA_FIELDS } from '@utils/constants/AssetMetadataEditModal';
import { Controller, useFormContext } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import NewContributorModal from './NewContributorModal';

const useStyles = makeStyles(() => ({
    sectionTitle: {
        marginBottom: '16px',
    },
    actionBtn: {
        '&:hover': {
            cursor: 'pointer',
        },
        width: '18px',
        height: '18px',
        color: '#5F5F60',
    },
    inputItemContainer: {
        display: 'flex',
        width: '100%',
        marginBottom: '16px',
    },
    firsInputInRow: {
        marginRight: '32px',
    },
    textField: {
        '& .MuiFormLabel-root': {
            fontSize: '12px',
            fontWeight: '400',
            letterSpacing: '0.4px',
        },
    },
    featuredLineUpContainer: {
        marginLeft: '32px',
    },
}));

const headers = [
    {
        title: 'Performer',
        dataIndex: 'fullName',
    },
    {
        title: 'Instrument/Role',
        dataIndex: 'instruments',
    },
    {
        title: 'Contract category',
        dataIndex: 'category',
    },
    {
        title: '',
        dataIndex: '',
        hasIcon: true,
    },
];

export default function AssetMetaDataContributorsSection({
    dataItem,
    contributorsDataFromBE,
    contributionSaveResult,
    tableReloadOnSuccess,
}) {
    const classes = useStyles();
    const dispatch = useDispatch();

    const {
        control,
        formState: { errors },
    } = useFormContext();

    const [showCotributionModal, setShowCotributionModal] = useState(false);
    const [isContributorEditable, setIsContributorEditable] = useState(false);
    const [territories, setTerritories] = useState([]);
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
    const [selectedContributorDataItem, setSelectedContributorDataItem] = useState({});

    const openModal = () => {
        setShowCotributionModal(true);
    };

    const handleDeleteContribution = () => {
        dispatch(
            deleteContribution(
                selectedContributorDataItem.performerId,
                dataItem.assetId,
                tableReloadOnSuccess,
            ),
        );
    };

    const getSpecificData = useCallback((headerItem, dataItem) => {
        const columnTitle = headerItem.title.toLowerCase();

        if (columnTitle === '') {
            const handleEditClick = () => {
                setSelectedContributorDataItem(dataItem);
                setShowCotributionModal(true);
                setIsContributorEditable(true);
            };
            const handleDeleteClick = () => {
                setSelectedContributorDataItem(dataItem);
                setIsDeleteDialogOpen(true);
            };

            return (
                <div style={{ display: 'flex' }}>
                    <div onClick={handleEditClick}>
                        <EditOutlined
                            className={classes.actionBtn}
                            style={{ marginRight: '7px' }}
                        />
                    </div>
                    <div onClick={handleDeleteClick}>
                        <DeleteOutline className={classes.actionBtn} />
                    </div>
                </div>
            );
        } else {
            return <OverflowTip>{dataItem[headerItem.dataIndex] || ''}</OverflowTip>;
        }
    });

    return (
        <>
            <Typography variant="h6" className={classes.sectionTitle}>
                Contributors
            </Typography>
            <div className={`${classes.inputItemContainer}`}>
                <Controller
                    name={ASSET_METADATA_FIELDS.numberOfFeaturedContr}
                    control={control}
                    defaultValue={null}
                    render={({ field }) => (
                        <TextFieldWrapper
                            {...field}
                            label="Featured performers"
                            type="number"
                            error={!!errors.numberOfFeaturedContr}
                            helperText={errors.numberOfFeaturedContr?.message}
                            classNameOpt={`${classes.textField} ${classes.firsInputInRow}`}
                            style={{ maxWidth: '82px' }}
                        />
                    )}
                />

                <Controller
                    name={ASSET_METADATA_FIELDS.numberOfNonFeaturedContr}
                    control={control}
                    defaultValue={null}
                    render={({ field }) => (
                        <TextFieldWrapper
                            {...field}
                            label="Non-featured performers"
                            type="number"
                            error={!!errors.numberOfNonFeaturedContr}
                            helperText={errors.numberOfNonFeaturedContr?.message}
                            classNameOpt={classes.textField}
                            style={{ maxWidth: '82px' }}
                        />
                    )}
                />
                <div className={classes.featuredLineUpContainer}>
                    <Typography variant="caption">Featured line up complete</Typography>
                    <Controller
                        name={ASSET_METADATA_FIELDS.lineUpComplete}
                        control={control}
                        defaultValue="false"
                        render={({ field }) => (
                            <RadioGroup
                                {...field}
                                row
                                style={{ marginTop: '4px' }}
                                value={field.value}
                                onChange={(e) => {
                                    field.onChange(e.target.value);
                                }}
                            >
                                <FormControlLabel
                                    value="true"
                                    control={<Radio size="small" color="primary" />}
                                    label={<Typography variant="body2">Yes</Typography>}
                                />
                                <FormControlLabel
                                    value="false"
                                    control={<Radio size="small" color="primary" />}
                                    label={<Typography variant="body2">No</Typography>}
                                />
                            </RadioGroup>
                        )}
                    />
                </div>
            </div>
            {contributionSaveResult.isSuccessful && (
                <Alert
                    style={{ marginBottom: '16px' }}
                    variant="outlined"
                    severity={contributionSaveResult.hasWarning ? 'warning' : 'success'}
                >
                    {`${
                        contributionSaveResult?.isDeleted
                            ? 'Contributor successfully deleted.'
                            : 'Contributor added and saved to the asset.'
                    }${
                        contributionSaveResult.hasWarning
                            ? ' This asset needs to be re-registered with one or more CMOs'
                            : ''
                    }`}
                </Alert>
            )}
            <CommonTableGrid
                dataList={contributorsDataFromBE}
                headerList={headers}
                preferences={{
                    loadDataByPageCb: {
                        // no need to pass params here
                    },
                }}
                selection={{
                    isSelectable: false,
                }}
                cellDataCb={getSpecificData}
                isInSubModal={true}
                rowsPerPageOff={true}
                tablePaginationOff={true}
                footerAddition={{
                    itemComponent: (
                        <Button
                            variant="outlined"
                            color="primary"
                            disableElevation
                            disableRipple
                            onClick={openModal}
                        >
                            <AddIcon style={{ margin: '0 10px 0 0' }} />
                            Add Contributor
                        </Button>
                    ),
                }}
            />
            {showCotributionModal && (
                <NewContributorModal
                    showModal={showCotributionModal}
                    setShowModal={setShowCotributionModal}
                    territories={territories}
                    setTerritories={setTerritories}
                    isEditing={isContributorEditable}
                    setIsEditing={setIsContributorEditable}
                    customData={{
                        selectedRowDataItem: { ...selectedContributorDataItem, ...dataItem },
                        onTableReload: {
                            reloadCb: getAssetMetadataById,
                            params: [dataItem.assetId],
                        },
                    }}
                />
            )}
            <CommonConfirmationDialog
                isOpen={isDeleteDialogOpen}
                closeCb={setIsDeleteDialogOpen}
                title="Confirm delete contributor"
                description={`Would you like to delete '${
                    selectedContributorDataItem?.fullName || ''
                }' ?`}
                onConfirmCb={handleDeleteContribution}
                customConfirmText="Delete"
            />
        </>
    );
}
