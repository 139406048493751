import React, { useState, useEffect } from 'react';

import { changeStateByNestedKey } from '@actions/fugaActions';
import TextFieldWrapper from '@common/MuiWrappers/TextFieldWrapper';
import Grid from '@mui/material/Grid2';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

const ClientIdentifiersContent = () => {
    const dispatch = useDispatch();
    const rhDataModel = useSelector((state) => state.fugaReducers.rhData);
    const { detailsData } = rhDataModel;
    const params = useParams();
    const areFieldsDisabled = useSelector(
        (state) => state.fugaReducers.clientPage.areFieldsDisabled,
    );

    useEffect(() => {
        dispatch(changeStateByNestedKey('rhData', 'aliasesError', ''));
    }, []);

    useEffect(() => {
        if (params.id === '-1' || !areFieldsDisabled) {
            setIsDisabled(false);
        } else {
            setIsDisabled(true);
        }
    }, [areFieldsDisabled, params.id]);

    useEffect(() => {
        dispatch(
            changeStateByNestedKey('rhData', 'mainDetailsInptValues', {
                ...rhDataModel.mainDetailsInptValues,
                fugaOrgId: detailsData.fugaOrgId,
                otherIdentifiers: detailsData.otherIdentifiers,
                royaltiesClientName: detailsData.royaltiesClientName,
                ddexPartyId: detailsData.ddexPartyId,
                nrpClientId: detailsData.nrpClientId,
                clientAliases: (detailsData.clientAliases || []).join(', '),
            }),
        );
    }, [rhDataModel.detailsData]);

    const [isDisabled, setIsDisabled] = useState(true);

    const handleInputChange = (event) => {
        dispatch(
            changeStateByNestedKey('rhData', 'mainDetailsInptValues', {
                ...rhDataModel.mainDetailsInptValues,
                [event.target.name]: event.currentTarget.value,
            }),
        );
    };

    return (
        <Grid container rowSpacing={1} columnSpacing={4}>
            {/* NRP Client ID */}
            <Grid size={6}>
                <TextFieldWrapper
                    label="NRP Client ID"
                    onChange={handleInputChange}
                    name="nrpClientId"
                    type="text"
                    isDisabled={true}
                    InputProps={{
                        readOnly: true,
                    }}
                    fullWidth
                    value={rhDataModel.mainDetailsInptValues.nrpClientId || ''}
                    inputProps={{ tabIndex: '6' }}
                />
            </Grid>

            <Grid size={6} />

            {/* FUGA Org ID */}
            <Grid size={6}>
                <TextFieldWrapper
                    label="FUGA Org ID"
                    onChange={handleInputChange}
                    name="fugaOrgId"
                    type="text"
                    InputProps={{
                        readOnly: isDisabled,
                    }}
                    fullWidth
                    value={rhDataModel.mainDetailsInptValues.fugaOrgId || ''}
                    inputProps={{ tabIndex: '7' }}
                />
            </Grid>

            {/* DDEX Party ID */}
            <Grid size={6}>
                <TextFieldWrapper
                    label="DDEX Party ID"
                    onChange={handleInputChange}
                    name="ddexPartyId"
                    type="text"
                    InputProps={{
                        readOnly: isDisabled,
                    }}
                    fullWidth
                    value={rhDataModel.mainDetailsInptValues.ddexPartyId || ''}
                    inputProps={{ tabIndex: '8' }}
                />
            </Grid>

            {/* Other Identifiers */}
            <Grid size={6}>
                <TextFieldWrapper
                    label="Other Identifiers"
                    onChange={handleInputChange}
                    name="otherIdentifiers"
                    type="text"
                    InputProps={{
                        readOnly: isDisabled,
                    }}
                    fullWidth
                    value={rhDataModel.mainDetailsInptValues.otherIdentifiers || ''}
                    inputProps={{ tabIndex: '9' }}
                />
            </Grid>

            <Grid size={6} />

            {/* Royalties Client Name */}
            <Grid size={6}>
                <TextFieldWrapper
                    label="Royalties Client Name"
                    onChange={handleInputChange}
                    name="royaltiesClientName"
                    type="text"
                    InputProps={{
                        readOnly: isDisabled,
                    }}
                    fullWidth
                    value={rhDataModel.mainDetailsInptValues.royaltiesClientName || ''}
                    inputProps={{ tabIndex: '10' }}
                />
            </Grid>

            {/* Royalties Contract Reference(s) */}
            <Grid size={6}>
                <TextFieldWrapper
                    label="Royalties Contract Reference(s)"
                    helperText={rhDataModel.aliasesError}
                    error={!!rhDataModel.aliasesError}
                    onChange={handleInputChange}
                    name="clientAliases"
                    type="text"
                    InputProps={{
                        readOnly: isDisabled,
                    }}
                    fullWidth
                    value={joinClientAliases(rhDataModel)}
                    inputProps={{ tabIndex: '11' }}
                />
            </Grid>
        </Grid>
    );
};

function joinClientAliases(dataModel) {
    if (Array.isArray(dataModel.mainDetailsInptValues.clientAliases)) {
        return dataModel.mainDetailsInptValues.clientAliases.join(', ');
    } else if (dataModel.mainDetailsInptValues.clientAliases !== undefined) {
        return dataModel.mainDetailsInptValues.clientAliases.toString();
    } else {
        return '';
    }
}

export default ClientIdentifiersContent;
