import { getAssetByPerformerIdAPI } from '@api/assetController';
import { createInnerClientAPI } from '@api/clientController';
import {
    editPerformerAPI,
    getAllPerformersAPI,
    getPerformerByIdAPI,
} from '@api/performerClientController';
import { createPerformerClientAPI } from '@api/performerController';
import { SUMMARY_BAR_ORDER } from '@common/RegStatusBarChart';
import fugaState from '@state/fugaState';
import helper from '@utils/helper';

import { changeStateByKey, changeStateByNestedKey } from './fugaActions';

function getAllPerformers(queryParams = { page: '', size: '' }) {
    return async function (dispatch) {
        dispatch(changeStateByNestedKey('performers', 'tableIsLoading', true));

        const resp = await getAllPerformersAPI(queryParams);

        const listWithSortedSummaries = (resp.data.content || []).map((el) => {
            const sortedData = { ...el };
            if (el.statusSummary) {
                sortedData.statusSummary.sort(
                    (firstItem, secondItem) =>
                        SUMMARY_BAR_ORDER.indexOf(firstItem.registrationStatusName) -
                        SUMMARY_BAR_ORDER.indexOf(secondItem.registrationStatusName),
                );
            }
            return sortedData;
        });
        dispatch(
            changeStateByNestedKey('performers', 'dataFromBE', {
                ...resp.data,
                content: listWithSortedSummaries,
            }),
        );
        dispatch(changeStateByNestedKey('performers', 'tableIsLoading', false));
    };
}

function getPerformerById(id) {
    return async function (dispatch) {
        try {
            dispatch(
                changeStateByKey('isFullScreenLoaderActive', ++fugaState.isFullScreenLoaderActive),
            );

            const resp = await getPerformerByIdAPI(id);

            dispatch(changeStateByNestedKey('performers', 'byClietnId', resp.data));
            const deals = helper.getDealsInfoPerformer(resp.data.clientDeals);
            dispatch(changeStateByNestedKey('performers', 'clientDeals', deals));
            if (deals.content.length === 0) {
                dispatch(changeStateByNestedKey('performers', 'isDeletable', true));
            } else {
                dispatch(changeStateByNestedKey('performers', 'isDeletable', false));
            }
            dispatch(
                changeStateByKey('isFullScreenLoaderActive', --fugaState.isFullScreenLoaderActive),
            );
        } catch (e) {
            dispatch(
                changeStateByKey('isFullScreenLoaderActive', --fugaState.isFullScreenLoaderActive),
            );
        }
    };
}

function getAssetByPerformerId(
    id,
    queryParams = { page: '', size: '', sort: '', direction: '' },
    requestBody = { isrcs: [], searchString: '' },
) {
    return async function (dispatch) {
        try {
            dispatch(changeStateByNestedKey('performersRepertoire', 'tableIsLoading', true));

            const resp = await getAssetByPerformerIdAPI(id, queryParams, requestBody);

            const dataToSet = resp.data.content ? resp.data : { ...resp.data, content: [] };
            dispatch(changeStateByNestedKey('performersRepertoire', 'dataFromBE', dataToSet));
            dispatch(changeStateByNestedKey('performersRepertoire', 'tableIsLoading', false));
        } catch (e) {
            dispatch(changeStateByNestedKey('performersRepertoire', 'tableIsLoading', false));
        }
    };
}

function editPerformer(content) {
    return async function (dispatch) {
        await editPerformerAPI(content);

        dispatch(changeStateByNestedKey('performers', 'isInEditMode', false));
    };
}

function createPerformerClient(
    isSelected,
    redirectCb,
    content = { createPerformer: {}, createPerfClient: {} },
) {
    async function createClientInner(performerId, dispatch) {
        const resp = await createInnerClientAPI(content, performerId, true);

        dispatch(changeStateByNestedKey('performers', 'isInEditMode', false));
        dispatch(changeStateByNestedKey('performers', 'isInSaveNewMode', false));
        dispatch(changeStateByNestedKey('performers', 'isDeletable', true));
        const clientId = resp.data.clientId;
        dispatch(getPerformerById(clientId));
        redirectCb(`/performer-page/${clientId}`);
    }

    return async function (dispatch) {
        try {
            const resp = await createPerformerClientAPI(content);

            await createClientInner(parseInt(resp.data.performerId), dispatch);
        } catch (e) {
            if (isSelected >= 0) {
                dispatch(changeStateByNestedKey('performers', 'selectedIndex', -1));

                const resp = await helper.getPerformerInfoByName(
                    content.createPerformer.fullname,
                    dispatch,
                );

                await createClientInner(parseInt(resp.performerId), dispatch);
            }
        }
    };
}

export {
    getAllPerformers,
    getPerformerById,
    getAssetByPerformerId,
    editPerformer,
    createPerformerClient,
};
