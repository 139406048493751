import React from 'react';

import { updateNestedField } from '@actions/apiActions';
import { getAlertsByAssetClaimId } from '@actions/assetsClaimEvent';
import { changeStateByNestedKey } from '@actions/fugaActions';
import { changeRegistrationStatusForAssetAPI } from '@api/assetsClaimEventController';
import CommonDropDownChips, { ChipLabel } from '@common/CommonDropDownChips';
import { ASSET_STATUSES_LIST, chipThemes } from '@common/CommonDropDownChips';
import MoreDropDownButton from '@common/MoreDropDownButton';
import OverflowTip from '@common/OverflowTip';
import ChatBubbleUnread from '@images/svgComponents/ChatBubbleUnreadSvg';
import InfoIconSmallBlueSvg from '@images/svgComponents/InfoIconSmallBlueSvg';
import WarningOrangeSvg from '@images/svgComponents/WarningOrangeSvg';
import { ScheduleOutlined, ErrorOutline } from '@mui/icons-material';
import ChatBubbleOutlineOutlinedIcon from '@mui/icons-material/ChatBubbleOutlineOutlined';
import { Typography, IconButton, Tooltip, styled } from '@mui/material';
import { useDispatch } from 'react-redux';

// Styled components
const IconRow = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
}));

const DisplayFlex = styled('div')({
    display: 'flex',
});

const ManualAlerts = styled('div')({
    display: 'flex',
    marginLeft: '8px',
    alignItems: 'center',
});

const IconStyle = styled('div')({
    width: '18px',
    height: '18px',
});

const getAlertIconAndTooltip = (alertType) => {
    let icon = null;
    let tooltipTitle = '';

    switch (alertType) {
        case 'DISPUTE':
            icon = <ErrorOutline sx={{ color: '#F44139', width: 18, height: 18 }} />;
            tooltipTitle = 'Internal action required';
            break;
        case 'EVIDENCE_REQUIRED':
        case 'CMO_VALIDATION':
            icon = <WarningOrangeSvg style={{ width: '18px', height: '18px' }} />;
            tooltipTitle =
                alertType === 'EVIDENCE_REQUIRED' ? 'Client action required' : 'Validation error';
            break;
        case 'ACTION_REQUIRED':
            icon = <InfoIconSmallBlueSvg style={{ width: '18px', height: '18px' }} />;
            tooltipTitle = 'Awaiting CMO action';
            break;
        default:
            break;
    }

    return { icon, tooltipTitle };
};

export const AssetsCmoTableRow = ({
    headerItem,
    dataItem,
    pageModelName,
    setAnchorEl,
    setDialogDataItem,
    setIsDialogOpen,
    setIsCommentsDialogOpen,
    getAssetData,
}) => {
    const dispatch = useDispatch();
    const columnTitle = headerItem.title.toLowerCase();

    const handleHistoryClick = (evt, dataItem) => {
        dispatch(changeStateByNestedKey(pageModelName, 'selectedRowDataItem', dataItem));
        setAnchorEl(evt.currentTarget);
    };

    const handleCommentsClick = (dataItem) => {
        dispatch(changeStateByNestedKey(pageModelName, 'selectedRowDataItem', dataItem));
        setIsCommentsDialogOpen(true);
    };

    const handleAlertClick = (dataItem) => {
        setDialogDataItem(dataItem);
        dispatch(getAlertsByAssetClaimId(dataItem.assetClaimId, pageModelName));
        setIsDialogOpen(true);
    };

    const statusChangeCb = async (status, dataItem) => {
        const response = await changeRegistrationStatusForAssetAPI(dataItem.assetClaimId, {
            claimStatus: status,
        });

        if (response?.data) {
            updateStatusInRow(response);
        }
    };

    const updateStatusInRow = (response) => {
        const dataItemFromResp = response.data;

        dispatch(
            updateNestedField(pageModelName, 'dataFromBE', dataItemFromResp.statusDate, {
                arrayName: 'content',
                idFieldName: 'assetClaimId',
                id: dataItemFromResp.assetClaimId,
                fieldNameToChange: 'statusDate',
            }),
        );
        dispatch(
            updateNestedField(pageModelName, 'dataFromBE', dataItemFromResp.newStatus, {
                arrayName: 'content',
                idFieldName: 'assetClaimId',
                id: dataItemFromResp.assetClaimId,
                fieldNameToChange: 'claimStatus',
            }),
        );
        getAssetData();
    };

    if (columnTitle.includes('track')) {
        return (
            <>
                <Typography variant="body2">
                    <OverflowTip>{dataItem[headerItem.dataIndex[0]]}</OverflowTip>
                </Typography>
                <Typography variant="caption">
                    <OverflowTip>{dataItem[headerItem.dataIndex[1]]}</OverflowTip>
                </Typography>
            </>
        );
    } else if (columnTitle === '') {
        return (
            <IconRow>
                <IconButton
                    onClick={(e) => handleHistoryClick(e, dataItem)}
                    disableRipple
                    size="large"
                >
                    <ScheduleOutlined sx={{ width: 18, height: 18 }} />
                </IconButton>
                <IconButton
                    onClick={() => handleCommentsClick(dataItem)}
                    disableRipple
                    size="large"
                >
                    {dataItem.hasComment ? (
                        <ChatBubbleUnread sx={{ width: 18, height: 18 }} />
                    ) : (
                        <ChatBubbleOutlineOutlinedIcon sx={{ width: 18, height: 18 }} />
                    )}
                </IconButton>
                <MoreDropDownButton
                    callbacks={{
                        onAlertCB: handleAlertClick,
                    }}
                    dataItem={dataItem}
                />
            </IconRow>
        );
    } else if (columnTitle.includes('registration status') && dataItem.claimStatus) {
        const menuItemsList = (dataItem.acceptableClaimStatuses || [])
            .filter((status) => Object.keys(ASSET_STATUSES_LIST).includes(status))
            .map((status) => ({
                title: status.split('_').join(' '),
                onClickClb: statusChangeCb,
                CbArgs: [status, dataItem],
                color: ASSET_STATUSES_LIST[status].color,
            }));

        return (
            <DisplayFlex sx={{ minWidth: '150px', flexGrow: 1 }}>
                {dataItem[headerItem.dataIndex] === 'EXCLUSIVE_LICENSE_DEAL' ? (
                    <ChipLabel label="EXCLUSIVE LICENSE" style={chipThemes.greenChip} />
                ) : (
                    <CommonDropDownChips
                        chipTitle={dataItem[headerItem.dataIndex].split('_').join(' ')}
                        menuItemsList={menuItemsList}
                        hasAlerts={dataItem?.alertCategories}
                    />
                )}
                <ManualAlerts onClick={() => handleAlertClick(dataItem)}>
                    {['DISPUTE', 'EVIDENCE_REQUIRED', 'ACTION_REQUIRED', 'CMO_VALIDATION'].map(
                        (alertType, index) => {
                            const { icon, tooltipTitle } = getAlertIconAndTooltip(alertType);

                            return (
                                (dataItem.alertCategories || []).includes(alertType) && (
                                    <Tooltip title={tooltipTitle} key={index}>
                                        <IconStyle>{icon}</IconStyle>
                                    </Tooltip>
                                )
                            );
                        },
                    )}
                </ManualAlerts>
            </DisplayFlex>
        );
    } else {
        return <OverflowTip>{dataItem[headerItem.dataIndex] || ''}</OverflowTip>;
    }
};
