import { getRHAssetsByIdAPI } from '@api/assetController';
import { createInnerClientAPI, getRhClientInfoByIdAPI } from '@api/clientController';
import {
    createRightHolderAPI,
    editRightHolderAPI,
    getRightHoldersListAPI,
} from '@api/rightHoldersController';
import helper from '@utils/helper';

import { changeStateByNestedKey } from './fugaActions';

function getRightHoldersList(searchTerm, clientConnected = true) {
    return async function (dispatch) {
        const resp = await getRightHoldersListAPI(searchTerm, clientConnected);

        const foundClients = resp.data.filter(
            (el) =>
                el.rightsHolderName.includes(searchTerm || null) ||
                el.rightsHolderName.toLocaleLowerCase().includes(searchTerm || null),
        );

        dispatch(changeStateByNestedKey('clients', 'suggestedClients', foundClients));
    };
}

function getRHAssetsById(
    id,
    queryParams = { sort: '', page: '', size: '' },
    requestBody = { isrcs: [], searchString: '' },
) {
    return async function (dispatch) {
        dispatch(changeStateByNestedKey('repertoires', 'tableIsLoading', true));

        const resp = await getRHAssetsByIdAPI(id, queryParams, requestBody);

        const dataToSet = resp.data.content ? resp.data : { ...resp.data, content: [] };
        dispatch(changeStateByNestedKey('repertoires', 'dataFromBE', dataToSet));
        dispatch(changeStateByNestedKey('repertoires', 'tableIsLoading', false));
    };
}

function getRhClientInfoById(clientId) {
    return async function (dispatch) {
        const resp = await getRhClientInfoByIdAPI(clientId);

        dispatch(changeStateByNestedKey('rhData', 'detailsData', resp.data));
        const deals = helper
            .getDealsInfo(resp.data.clientDealDTO)
            .sort((deal) => (deal.status ? -1 : 1));
        dispatch(
            changeStateByNestedKey('rhData', 'deals', {
                content: deals,
            }),
        );
        if (deals.length === 0) {
            dispatch(changeStateByNestedKey('clientPage', 'isDeletable', true));
        } else {
            dispatch(changeStateByNestedKey('clientPage', 'isDeletable', false));
        }
    };
}

function createClient(rhName, clientDetails, isSelected, redirectCb) {
    async function createClientInner(rightsHolderId, dispatch) {
        const resp = await createInnerClientAPI(clientDetails, rightsHolderId, false);

        dispatch(changeStateByNestedKey('clientPage', 'isInEditMode', false));
        dispatch(changeStateByNestedKey('clientPage', 'isInSaveNewMode', false));
        dispatch(changeStateByNestedKey('clientPage', 'isDeletable', true));
        const clientId = resp.data.clientId;
        dispatch(getRhClientInfoById(clientId));
        redirectCb(`/client-page/${clientId}`);
    }

    return async function (dispatch) {
        try {
            const resp = await createRightHolderAPI(rhName);
            await createClientInner(parseInt(resp.data.rightsHolderId), dispatch);
        } catch (e) {
            if (isSelected >= 0) {
                dispatch(changeStateByNestedKey('clientPage', 'selectedIndex', -1));
                const idStr = e || e.data.error;
                const rightsHolderId = idStr
                    .match(/id.*(\d+)/i)[0]
                    .split('id')[1]
                    .trim();
                await createClientInner(parseInt(rightsHolderId), dispatch);
            }
        }
    };
}

function editRightHolder(content) {
    return async function (dispatch) {
        await editRightHolderAPI(content);

        dispatch(changeStateByNestedKey('clientPage', 'isInEditMode', false));
        dispatch(changeStateByNestedKey('clientPage', 'areFieldsDisabled', true));
    };
}

export { getRightHoldersList, getRHAssetsById, getRhClientInfoById, createClient, editRightHolder };
