import React from 'react';

import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
    titile: {
        padding: '19px 16px',
    },
    content: {
        padding: '8px 4px 8px 12px',
        width: '100%',
        height: '48px',
        marginBottom: '16px',
    },
    flexHor: {
        display: 'flex',
    },
    labels: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
    },
    clearAll: {
        '&:hover': {
            cursor: 'pointer',
        },
    },
}));

function CommonFiltersContainer({ innerFiltersComponent }) {
    const classes = useStyles();

    return (
        <div className={`${classes.content} ${classes.flexHor}`}>
            <Typography variant="body2" className={classes.labels}>
                Filters:
            </Typography>
            {innerFiltersComponent}
        </div>
    );
}

export default CommonFiltersContainer;
