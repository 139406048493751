import React, { useState, useEffect } from 'react';

import { changeStateByNestedKey } from '@actions/fugaActions';
import TextFieldWrapper from '@common/MuiWrappers/TextFieldWrapper';
import { FormControl } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { connect, useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';

const PerformerBillingContent = (props) => {
    const dispatch = useDispatch();
    const performersModel = useSelector((state) => state.fugaReducers.performers);
    const { byClietnId } = performersModel;
    const [isDisabled, setIsDisabled] = useState(true);

    useEffect(() => {
        if (performersModel.isInEditMode || performersModel.isInSaveNewMode) {
            setIsDisabled(false);
        } else {
            setIsDisabled(true);
        }
    }, [performersModel.isInEditMode, performersModel.isInSaveNewMode]);

    useEffect(() => {
        if (props.match.params.id === '-1') {
            setIsDisabled(false);
        }
    }, []);

    useEffect(() => {
        dispatch(
            changeStateByNestedKey('performers', 'mainDetailsInptValues', {
                ...performersModel.mainDetailsInptValues,
                bankAccNum: byClietnId.bankAccountNumber || '',
                bankCity: byClietnId.bankCity || '',
                iban: byClietnId.bankIbanNumber || '',
                bankName: byClietnId.bankName || '',
                bankAccPayee: byClietnId.bankAccountPayee || '',
                bankCountry: byClietnId.bankCountry || '',
                bankSwiftNumber: byClietnId.bankSwiftNumber || '',
            }),
        );
    }, [performersModel.byClietnId]);

    const handleInputChange = (event) => {
        dispatch(
            changeStateByNestedKey('performers', 'mainDetailsInptValues', {
                ...performersModel.mainDetailsInptValues,
                [event.target.name]: event.currentTarget.value,
            }),
        );
    };

    return (
        <>
            <Grid columnSpacing={4} rowSpacing={1} container>
                <Grid size={6}>
                    <TextFieldWrapper
                        label="Bank account number"
                        onChange={(evt) => handleInputChange(evt)}
                        name="bankAccNum"
                        type="text"
                        fullWidth
                        InputProps={{
                            readOnly: isDisabled,
                        }}
                        value={
                            performersModel.mainDetailsInptValues.bankAccNum
                                ? performersModel.mainDetailsInptValues.bankAccNum
                                : ''
                        }
                        inputProps={{ tabIndex: '21' }}
                    />
                </Grid>
                <Grid size={6}>
                    <TextFieldWrapper
                        inputProps={{ tabIndex: '22' }}
                        label="Bank account payee"
                        onChange={(evt) => handleInputChange(evt)}
                        name="bankAccPayee"
                        type="text"
                        fullWidth
                        InputProps={{
                            readOnly: isDisabled,
                        }}
                        value={
                            performersModel.mainDetailsInptValues.bankAccPayee
                                ? performersModel.mainDetailsInptValues.bankAccPayee
                                : ''
                        }
                    />
                </Grid>
                <Grid size={6}>
                    <TextFieldWrapper
                        inputProps={{ tabIndex: '23' }}
                        label="Bank city"
                        onChange={(evt) => handleInputChange(evt)}
                        name="bankCity"
                        fullWidth
                        type="text"
                        InputProps={{
                            readOnly: isDisabled,
                        }}
                        value={
                            performersModel.mainDetailsInptValues.bankCity
                                ? performersModel.mainDetailsInptValues.bankCity
                                : ''
                        }
                    />
                </Grid>
                <Grid size={6}>
                    <TextFieldWrapper
                        inputProps={{ tabIndex: '24' }}
                        label="Bank country"
                        onChange={(evt) => handleInputChange(evt)}
                        name="bankCountry"
                        type="text"
                        fullWidth
                        InputProps={{
                            readOnly: isDisabled,
                        }}
                        value={
                            performersModel.mainDetailsInptValues.bankCountry
                                ? performersModel.mainDetailsInptValues.bankCountry
                                : ''
                        }
                    />
                </Grid>
                <Grid size={6}>
                    <TextFieldWrapper
                        inputProps={{ tabIndex: '25' }}
                        label="Bank IBAN number"
                        onChange={(evt) => handleInputChange(evt)}
                        name="iban"
                        type="text"
                        fullWidth
                        InputProps={{
                            readOnly: isDisabled,
                        }}
                        value={
                            performersModel.mainDetailsInptValues.iban
                                ? performersModel.mainDetailsInptValues.iban
                                : ''
                        }
                    />
                </Grid>
                <Grid size={6}>
                    <TextFieldWrapper
                        inputProps={{ tabIndex: '26' }}
                        label="Bank Swift number"
                        onChange={(evt) => handleInputChange(evt)}
                        name="bankSwiftNumber"
                        type="text"
                        fullWidth
                        InputProps={{
                            readOnly: isDisabled,
                        }}
                        value={
                            performersModel.mainDetailsInptValues.bankSwiftNumber
                                ? performersModel.mainDetailsInptValues.bankSwiftNumber
                                : ''
                        }
                    />
                </Grid>
                <Grid size={6}>
                    <TextFieldWrapper
                        inputProps={{ tabIndex: '27' }}
                        label="Bank name"
                        onChange={(evt) => handleInputChange(evt)}
                        name="bankName"
                        type="text"
                        fullWidth
                        InputProps={{
                            readOnly: isDisabled,
                        }}
                        value={
                            performersModel.mainDetailsInptValues.bankName
                                ? performersModel.mainDetailsInptValues.bankName
                                : ''
                        }
                    />
                </Grid>
            </Grid>
        </>
    );
};

const withUrlPerformerBillingContent = withRouter(PerformerBillingContent);
export default connect((r) => r)(withUrlPerformerBillingContent);
