import React, { useEffect, useState } from 'react';

import { ArrowDropDown } from '@mui/icons-material';
import { IconButton, InputAdornment, Menu, MenuItem, Typography } from '@mui/material';
import TextFieldWrapper from '@MuiWrappers/TextFieldWrapper';

export function CommonDropDownWithInput({
    menuItemsList,
    isEditing,
    labelName,
    preSelectedValue,
    onValueChangeCb,
    inputProps,
    fieldToSend = 'title',
    isDisabled,
    fullWidth = false,
}) {
    const [anchorEl, setAnchorEl] = useState(null);
    const [value, setValue] = useState('');

    useEffect(() => {
        if (preSelectedValue || preSelectedValue === 0) {
            setValue(preSelectedValue);
        }
    }, [preSelectedValue]);

    useEffect(() => {
        if (!isEditing) {
            setValue(menuItemsList[0]?.title || '');
        }
    }, [isEditing, menuItemsList]);

    const handleOpen = (event) => {
        if (!isDisabled) {
            setAnchorEl(event.currentTarget);
        }
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const onItemClick = (item) => {
        handleClose();
        setValue(item.title);
        onValueChangeCb(item[fieldToSend]);
    };

    return (
        <>
            <TextFieldWrapper
                sx={{ marginBottom: '32px' }}
                value={value}
                label={labelName}
                disabled={isDisabled}
                fullWidth={fullWidth}
                inputProps={inputProps}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton
                                sx={{
                                    padding: '0',
                                    margin: '0',
                                    '&:hover': { background: 'none' },
                                }}
                                disableRipple
                                onClick={handleOpen}
                                size="large"
                            >
                                <ArrowDropDown sx={{ marginRight: '0' }} />
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
            />
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
                {menuItemsList &&
                    menuItemsList.map((item, ind) => (
                        <MenuItem
                            key={`${ind}-${item.title}`}
                            onClick={() => onItemClick(item)}
                            sx={{ padding: '0px 15px' }}
                        >
                            {item.icon || null}
                            <Typography variant="body1" sx={{ margin: '14px 0 5px 0' }}>
                                {item.title}
                            </Typography>
                        </MenuItem>
                    ))}
            </Menu>
        </>
    );
}
