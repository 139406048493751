import React, { useEffect, useState } from 'react';

import CommonDropDownButtonWithFixedLabel from '@common/CommonDropDownButtonWithFixedLabel';
import CrossIconSvg from '@images/svgComponents/CrossIconSvg';
import SaveIconSvg from '@images/svgComponents/SaveIconSvg';
import ThumbsUpIconSvg from '@images/svgComponents/ThumbsUpIconSvg';
import { EditOutlined } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormHelperText,
    Tooltip,
    Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import TextFieldWrapper from '@MuiWrappers/TextFieldWrapper';
import { format } from 'date-fns';
import { useForm, Controller, useFieldArray } from 'react-hook-form';

const PREFIX = 'AlertsModal';

const classes = {
    title: `${PREFIX}-title`,
    content: `${PREFIX}-content`,
    textField: `${PREFIX}-textField`,
    textCounter: `${PREFIX}-textCounter`,
    addAlertButton: `${PREFIX}-addAlertButton`,
    addIcon: `${PREFIX}-addIcon`,
    addAlertBtn: `${PREFIX}-addAlertBtn`,
    alertDropDownLabel: `${PREFIX}-alertDropDownLabel`,
    operationsContainer: `${PREFIX}-operationsContainer`,
    saveIcon: `${PREFIX}-saveIcon`,
    thumbsUPIcon: `${PREFIX}-thumbsUPIcon`,
    operationIcons: `${PREFIX}-operationIcons`,
    disabledIcon: `${PREFIX}-disabledIcon`,
    metaDataIcons: `${PREFIX}-metaDataIcons`,
    actionNDateContainer: `${PREFIX}-actionNDateContainer`,
    editIcon: `${PREFIX}-editIcon`,
    alertRow: `${PREFIX}-alertRow`,
};

const StyledDialog = styled(Dialog)(({ theme }) => ({
    [`& .${classes.title}`]: {
        padding: theme.spacing(2),
    },
    [`& .${classes.content}`]: {
        padding: theme.spacing(1, 2),
        minWidth: '568px',
    },
    [`& .${classes.textField}`]: {
        width: '488px',
        minHeight: 0,
        marginTop: '8px',
    },
    [`& .${classes.textCounter}`]: {
        display: 'flex',
        justifyContent: 'flex-end',
        width: '488px',
    },
    [`& .${classes.addAlertButton}`]: {
        fontSize: '13px',
        height: '30px',
        padding: '4px 10px',
    },
    [`& .${classes.addIcon}`]: {
        marginRight: '10px',
        width: '18px',
        height: '18px',
    },
    [`& .${classes.addAlertBtn}`]: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    [`& .${classes.alertDropDownLabel}`]: {
        fontSize: '14px',
        fontWeight: '400',
        textTransform: 'capitalize',
        padding: 0,
        '&:hover': {
            background: 'unset',
        },
    },
    [`& .${classes.operationsContainer}`]: {
        display: 'flex',
        alignItems: 'center',
    },
    [`& .${classes.saveIcon}`]: {
        marginLeft: '16px',
        marginRight: '4px',
    },
    [`& .${classes.thumbsUPIcon}`]: {
        marginLeft: '16px',
        marginRight: '4px',
    },
    [`& .${classes.operationIcons}`]: {
        '&:hover': { cursor: 'pointer' },
        marginLeft: '8px',
    },
    [`& .${classes.disabledIcon}`]: {
        '&:hover': { cursor: 'unset' },
    },
    [`& .${classes.metaDataIcons}`]: {
        marginRight: '8px',
    },
    [`& .${classes.actionNDateContainer}`]: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: '18px',
        justifyContent: 'space-between',
    },
    [`& .${classes.editIcon}`]: {
        '&:hover': {
            cursor: 'pointer',
        },
        width: '24px',
        height: '24px',
        color: '#5F5F60',
        marginLeft: '8px',
    },
    [`& .${classes.alertRow}`]: {
        marginBottom: '26px',
    },
}));

function AlertsModal({
    isOpen,
    onClose,
    dataItem,
    alerts,
    onUpdateAlert,
    onResolveAlert,
    onCreateAlert,
    menuItemsList,
    getAlertsData,
    getMaxSymbolsCount = 5000,
    getAlertId = (alert) => alert.alertId,
    getAlertDate = (alert) => alert.date,
    getAlertCategory = (alert) => alert.alertCategory,
    getAlertMsgProp = (alert) => alert.alertMsg,
    getAlertState = (alert) => alert.alertState,
    dataItemIdKey = 'assetClaimId',
}) {
    const { control, reset, watch, setValue, getValues } = useForm({
        defaultValues: {
            alerts: [],
            newAlertMsg: '',
            selectedValue: null,
        },
    });

    const { fields } = useFieldArray({
        control,
        name: 'alerts',
        keyName: 'fieldKey',
    });

    const [tooltipOpen, setTooltipOpen] = useState(false);
    const [isTypesDropdownVisible, setIsTypesDropdownVisible] = useState(false);
    const [isUpdated, setIsUpdated] = useState(false);

    useEffect(() => {
        setIsUpdated(false);
    }, []);

    useEffect(() => {
        const alertFields = alerts.map((el) => ({
            id: getAlertId(el),
            text: getAlertMsgProp(el),
            isDisabled: false,
            count: (getAlertMsgProp(el) + '').length || 0,
        }));
        reset({ alerts: alertFields, newAlertMsg: '', selectedValue: null });
    }, [alerts, reset, getAlertId, getAlertMsgProp]);

    const handleTooltipClose = () => {
        setTooltipOpen(false);
    };

    const handleTooltipOpen = () => {
        setTooltipOpen(true);
    };

    const handleDialogClose = (event, reason, updated = isUpdated) => {
        if (reason !== 'backdropClick') {
            onClose();
            reset({ alerts: [], newAlertMsg: '', selectedValue: null });
            setIsTypesDropdownVisible(false);
            if (updated && getAlertsData) {
                getAlertsData();
            }
        }
    };

    const getSymbolsCount = (value) => {
        const symbolsCount = value.length;
        if (symbolsCount > getMaxSymbolsCount) {
            handleTooltipOpen();
            return false;
        } else {
            handleTooltipClose();
            return true;
        }
    };

    const updateAndClose = () => {
        setIsUpdated(true);
        handleDialogClose(null, null, true);
    };

    const handleAlertInputSave = async () => {
        const { newAlertMsg, selectedValue } = getValues();
        if (selectedValue && newAlertMsg) {
            const dataItemKey =
                dataItemIdKey === 'cmoRegId' ? 'clientCmoRegistrationId' : dataItemIdKey;
            await onCreateAlert({
                alertMsg: newAlertMsg,
                alertCategory: selectedValue?.beValue,
                [dataItemKey]: dataItem[dataItemIdKey],
            });
            updateAndClose();
        }
    };

    const handleAlertInputUpdate = (alertId = -1, index = -1) => {
        const alertText = getValues(`alerts.${index}.text`);
        onUpdateAlert(
            {
                alertMsg: alertText,
                alertId,
            },
            dataItem[dataItemIdKey],
        );
        setIsUpdated(true);
    };

    const handleAlertResolve = (alertId) => {
        onResolveAlert(alertId, dataItem[dataItemIdKey]);
        setIsUpdated(true);
    };

    const handleAddNewAlertBtn = () => {
        setIsTypesDropdownVisible(true);
    };

    const handleAlertInputCancel = () => {
        const { selectedValue } = getValues();
        if (selectedValue) {
            setValue('selectedValue', null);
            setValue('newAlertMsg', '');
        }
    };

    const getMetaDataByAlertCategory = (category) => {
        return menuItemsList.find((el) => el.beValue === category) || {};
    };

    const isCloseButtonActive = () => {
        const { selectedValue } = getValues();
        const anyAlertEditing = fields.some((field, index) => watch(`alerts.${index}.isDisabled`));
        return !!selectedValue || anyAlertEditing;
    };

    const toggleAlertEditState = (index) => {
        const isDisabled = watch(`alerts.${index}.isDisabled`);
        setValue(`alerts.${index}.isDisabled`, !isDisabled);
    };

    const resetAlertTextBeforeEdit = (index, id) => {
        const originalText = getAlertMsgProp(alerts.find((el) => getAlertId(el) === id)) || '';
        setValue(`alerts.${index}.text`, originalText);
    };

    return (
        <StyledDialog onClose={handleDialogClose} open={isOpen} maxWidth="md" disableEscapeKeyDown>
            <DialogTitle className={classes.title}>
                <Typography variant="h6">Alerts</Typography>
            </DialogTitle>
            <DialogContent className={classes.content}>
                {fields.map((field, index) => {
                    const item = alerts[index];
                    const alertMetaData = getMetaDataByAlertCategory(getAlertCategory(item));
                    if (getAlertState(item) !== 'RESOLVED') {
                        const alertId = getAlertId(item);
                        return (
                            <div key={field.fieldKey} className={classes.alertRow}>
                                <div className={classes.actionNDateContainer}>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <div className={classes.metaDataIcons}>
                                            {alertMetaData?.coloredIcon &&
                                                alertMetaData?.coloredIcon({
                                                    styles: { fill: alertMetaData.color },
                                                })}
                                        </div>
                                        <Typography
                                            style={{ color: alertMetaData.color }}
                                            variant="subtitle2"
                                        >
                                            {alertMetaData.title}
                                        </Typography>
                                    </div>
                                    <div style={{ marginTop: '2px' }}>
                                        <Typography
                                            variant="caption"
                                            style={{
                                                marginLeft: '8px',
                                                display: 'flex',
                                                height: '100%',
                                            }}
                                        >
                                            Added{' '}
                                            {`${format(
                                                new Date(getAlertDate(item)),
                                                'd/M/yyyy HH:mm',
                                            )} UTC`}{' '}
                                        </Typography>
                                    </div>
                                </div>
                                <div className={classes.operationsContainer}>
                                    {alertMetaData.beValue === 'CMO_VALIDATION' ? (
                                        <Typography variant="body1">
                                            {getAlertMsgProp(item) || 'Empty message'}
                                        </Typography>
                                    ) : (
                                        <div>
                                            <Tooltip
                                                title="Text should be less than 5000 characters"
                                                disableHoverListener
                                                open={tooltipOpen}
                                                className={classes.textField}
                                            >
                                                <Controller
                                                    name={`alerts.${index}.text`}
                                                    control={control}
                                                    defaultValue={field.text}
                                                    render={({ field: controllerField }) => (
                                                        <TextFieldWrapper
                                                            {...controllerField}
                                                            onChange={(e) => {
                                                                const value = e.target.value;
                                                                if (getSymbolsCount(value)) {
                                                                    controllerField.onChange(e);
                                                                }
                                                            }}
                                                            name="alertMsg"
                                                            type="text"
                                                            multiline
                                                            maxRows={10}
                                                            isDisabled={
                                                                !watch(`alerts.${index}.isDisabled`)
                                                            }
                                                            classNameOpt={classes.textField}
                                                            InputProps={{
                                                                readOnly: !watch(
                                                                    `alerts.${index}.isDisabled`,
                                                                ),
                                                                sx: { minHeight: '0 !important' },
                                                            }}
                                                            onBlur={handleTooltipClose}
                                                        />
                                                    )}
                                                />
                                            </Tooltip>
                                            <div className={classes.textCounter}>
                                                <FormHelperText>
                                                    {watch(`alerts.${index}.text`)?.length || 0}/
                                                    {getMaxSymbolsCount}
                                                </FormHelperText>
                                            </div>
                                        </div>
                                    )}
                                    {alertMetaData.beValue !== 'CMO_VALIDATION' && (
                                        <div className={classes.operationsContainer}>
                                            {!watch(`alerts.${index}.isDisabled`) ? (
                                                <Tooltip title="Resolve this open alert">
                                                    <div>
                                                        <ThumbsUpIconSvg
                                                            styles={`${classes.thumbsUPIcon} ${classes.operationIcons}`}
                                                            onClickCb={() =>
                                                                handleAlertResolve(alertId)
                                                            }
                                                        />
                                                    </div>
                                                </Tooltip>
                                            ) : (
                                                <Tooltip title="Save this alert">
                                                    <div>
                                                        <SaveIconSvg
                                                            styles={`${classes.saveIcon} ${classes.operationIcons}`}
                                                            onClickCb={() =>
                                                                handleAlertInputUpdate(
                                                                    alertId,
                                                                    index,
                                                                )
                                                            }
                                                        />
                                                    </div>
                                                </Tooltip>
                                            )}
                                            {!watch(`alerts.${index}.isDisabled`) ? (
                                                <Tooltip title="Edit text of this alert">
                                                    <div
                                                        onClick={() => toggleAlertEditState(index)}
                                                    >
                                                        <EditOutlined
                                                            className={classes.editIcon}
                                                        />
                                                    </div>
                                                </Tooltip>
                                            ) : (
                                                <Tooltip title="Cancel edit">
                                                    <div>
                                                        <CrossIconSvg
                                                            styles={`${classes.operationIcons}`}
                                                            onClickCb={() => {
                                                                resetAlertTextBeforeEdit(
                                                                    index,
                                                                    alertId,
                                                                );
                                                                toggleAlertEditState(index);
                                                            }}
                                                        />
                                                    </div>
                                                </Tooltip>
                                            )}
                                        </div>
                                    )}
                                </div>
                            </div>
                        );
                    }
                    return null;
                })}

                {isTypesDropdownVisible ? (
                    <CommonDropDownButtonWithFixedLabel
                        btnTitle="Alert Type"
                        manualAlertsItemsList={menuItemsList}
                        customStyles={{
                            color: 'primary',
                            variant: 'text',
                            classStyles: classes.alertDropDownLabel,
                        }}
                        selectedValue={watch('selectedValue')}
                        setSelectedValue={(value) => setValue('selectedValue', value)}
                    />
                ) : (
                    <div className={classes.addAlertBtn}>
                        <Button
                            className={classes.addAlertButton}
                            variant="outlined"
                            onClick={handleAddNewAlertBtn}
                        >
                            <AddIcon className={classes.addIcon} />
                            Add new alert
                        </Button>
                    </div>
                )}
                {isTypesDropdownVisible && (
                    <div className={classes.operationsContainer}>
                        <div>
                            <Tooltip
                                title="Text should be less than 5000 characters"
                                disableHoverListener
                                open={tooltipOpen}
                            >
                                <Controller
                                    name="newAlertMsg"
                                    control={control}
                                    defaultValue=""
                                    render={({ field }) => (
                                        <TextFieldWrapper
                                            {...field}
                                            onChange={(e) => {
                                                const value = e.target.value;
                                                if (getSymbolsCount(value)) {
                                                    field.onChange(e);
                                                }
                                            }}
                                            name="alertMsg"
                                            type="text"
                                            multiline
                                            maxRows={10}
                                            isDisabled={!watch('selectedValue')}
                                            classNameOpt={classes.textField}
                                            InputProps={{
                                                readOnly: !watch('selectedValue'),
                                                sx: { minHeight: '0 !important' },
                                            }}
                                            onBlur={handleTooltipClose}
                                        />
                                    )}
                                />
                            </Tooltip>
                            <div className={classes.textCounter}>
                                <FormHelperText>
                                    {watch('newAlertMsg')?.length || 0}/{getMaxSymbolsCount}
                                </FormHelperText>
                            </div>
                        </div>
                        <div className={classes.operationsContainer}>
                            <Tooltip title="Save this alert">
                                <div>
                                    <SaveIconSvg
                                        styles={`${classes.saveIcon} ${classes.operationIcons} ${
                                            watch('selectedValue') ? '' : classes.disabledIcon
                                        }`}
                                        onClickCb={handleAlertInputSave}
                                    />
                                </div>
                            </Tooltip>
                            <Tooltip title="Clear text">
                                <div>
                                    <CrossIconSvg
                                        styles={`${classes.operationIcons} ${
                                            watch('selectedValue') ? '' : classes.disabledIcon
                                        }`}
                                        onClickCb={handleAlertInputCancel}
                                    />
                                </div>
                            </Tooltip>
                        </div>
                    </div>
                )}
            </DialogContent>
            <DialogActions>
                <Tooltip
                    disableHoverListener={!isCloseButtonActive()}
                    title="Select 'Save' or 'Clear text' before closing"
                >
                    <div>
                        <Button
                            onClick={handleDialogClose}
                            variant="outlined"
                            color="primary"
                            disableElevation
                            disabled={isCloseButtonActive()}
                        >
                            Close
                        </Button>
                    </div>
                </Tooltip>
            </DialogActions>
        </StyledDialog>
    );
}

export default AlertsModal;
