import React, { useState, useEffect } from 'react';

import { changeStateByNestedKey } from '@actions/fugaActions';
import TextFieldWrapper from '@common/MuiWrappers/TextFieldWrapper';
import { FormControl } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

const ClientCommentsContent = () => {
    const dispatch = useDispatch();
    const rhDataModel = useSelector((state) => state.fugaReducers.rhData);
    const { detailsData } = rhDataModel;
    const params = useParams();
    const areFieldsDisabled = useSelector(
        (state) => state.fugaReducers.clientPage.areFieldsDisabled,
    );

    useEffect(() => {
        if (params.id === '-1' || !areFieldsDisabled) {
            setIsDisabled(false);
        } else {
            setIsDisabled(true);
        }
    }, [areFieldsDisabled, params.id]);

    useEffect(() => {
        dispatch(
            changeStateByNestedKey('rhData', 'mainDetailsInptValues', {
                ...rhDataModel.mainDetailsInptValues,
                comments: detailsData.comments,
            }),
        );
    }, [rhDataModel.detailsData]);

    const [isDisabled, setIsDisabled] = useState(true);

    const handleInputChange = (event) => {
        dispatch(
            changeStateByNestedKey('rhData', 'mainDetailsInptValues', {
                ...rhDataModel.mainDetailsInptValues,
                [event.target.name]: event.currentTarget.value,
            }),
        );
    };

    return (
        <Grid spacing={4} container>
            <Grid
                size={{
                    xs: 6,
                    md: 6,
                }}
            >
                <FormControl variant="standard" style={{ width: '100%' }}>
                    <TextFieldWrapper
                        inputProps={{ tabIndex: '27' }}
                        multiline={true}
                        label="comments"
                        onChange={(evt) => handleInputChange(evt)}
                        name="comments"
                        type="text"
                        value={rhDataModel.mainDetailsInptValues.comments || ''}
                        InputProps={{
                            readOnly: isDisabled,
                        }}
                    />
                </FormControl>
            </Grid>
        </Grid>
    );
};

export default ClientCommentsContent;
