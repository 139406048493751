import React, { useState, useEffect } from 'react';

import { changeStateByNestedKey, changeStateByKey } from '@actions/fugaActions';
import { getAllTerritories } from '@actions/territories';
import { CommonDropDownWithInput } from '@common/CommonDropDownWithInput';
import TerritoriesModal from '@common/modals/Territories/TerritoriesModal';
import TextFieldWrapper from '@common/MuiWrappers/TextFieldWrapper';
import AddIcon from '@mui/icons-material/Add';
import { Button, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { connect, useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';

import ClientDealsTable from './ClientDealsTable';

const currencyMenuItems = [
    { title: 'EUR', value: 'EUR' },
    { title: 'GBP', value: 'GBP' },
    { title: 'USD', value: 'USD' },
];

const ClientDetailsContent = (props) => {
    const dispatch = useDispatch();
    const territoriesModel = useSelector((state) => state.fugaReducers.territories);
    const rhDataModel = useSelector((state) => state.fugaReducers.rhData);
    const { detailsData } = rhDataModel;
    const areFieldsDisabled = useSelector(
        (state) => state.fugaReducers.clientPage.areFieldsDisabled,
    );

    const [showTerritoryModal, setShowTerritoryModal] = useState(false);
    const [territories, setTerritories] = useState([]);
    const [isDisabled, setIsDisabled] = useState(true);

    useEffect(() => {
        setIsDisabled(areFieldsDisabled || props.match.params.id !== '-1');
    }, [areFieldsDisabled, props.match.params.id]);

    useEffect(() => {
        if (props.match.params.id === '-1') {
            setIsDisabled(false);
        }
        dispatch(getAllTerritories());
    }, []);

    useEffect(() => {
        dispatch(
            changeStateByNestedKey('rhData', 'mainDetailsInptValues', {
                ...rhDataModel.mainDetailsInptValues,
                advance: detailsData.advance,
                dealRate: detailsData.dealRate,
                rateAfterRec: detailsData.rateAfterRecoupment,
                rateDuringRec: detailsData.rateDuringRecoupment,
                accounting: detailsData.accountingFrequency,
                currency: detailsData.currency,
                postTerm: detailsData.postTerm,
            }),
        );
    }, [detailsData]);

    const handleInputChange = (event) => {
        dispatch(
            changeStateByNestedKey('rhData', 'mainDetailsInptValues', {
                ...rhDataModel.mainDetailsInptValues,
                [event.target.name]: event.currentTarget.value,
            }),
        );
    };

    const handleCurrencyValueChange = (value) => {
        dispatch(
            changeStateByNestedKey('rhData', 'mainDetailsInptValues', {
                ...rhDataModel.mainDetailsInptValues,
                currency: value,
            }),
        );
    };

    const handleAddDeal = () => {
        if (props.match.params.id === '-1') {
            dispatch(
                changeStateByKey('infoMessage', {
                    isVisible: true,
                    message: 'Please save this Client before adding their Deal terms',
                    severity: 'warning',
                }),
            );
        } else {
            setShowTerritoryModal(true);
        }
    };

    return (
        <>
            <Grid container columnSpacing={4} rowSpacing={1}>
                {/* Advance Field */}
                <Grid size={6}>
                    <TextFieldWrapper
                        label="Advance"
                        onChange={handleInputChange}
                        name="advance"
                        type="text"
                        value={rhDataModel.mainDetailsInptValues.advance || ''}
                        inputProps={{ tabIndex: '15' }}
                        fullWidth
                        InputProps={{ readOnly: isDisabled }}
                    />
                </Grid>

                {/* Rate After Recoupment Field */}
                <Grid size={6}>
                    <TextFieldWrapper
                        inputProps={{ tabIndex: '17' }}
                        label="Rate (after recoupment)"
                        onChange={handleInputChange}
                        name="rateAfterRec"
                        type="text"
                        fullWidth
                        InputProps={{ readOnly: isDisabled }}
                        value={rhDataModel.mainDetailsInptValues.rateAfterRec || ''}
                    />
                </Grid>

                {/* Accounting Frequency Field */}
                <Grid size={6}>
                    <TextFieldWrapper
                        inputProps={{ tabIndex: '19' }}
                        label="Accounting Frequency"
                        onChange={handleInputChange}
                        name="accounting"
                        type="text"
                        fullWidth
                        InputProps={{ readOnly: isDisabled }}
                        value={rhDataModel.mainDetailsInptValues.accounting || ''}
                    />
                </Grid>

                {/* Rate During Recoupment Field */}
                <Grid size={6}>
                    <TextFieldWrapper
                        inputProps={{ tabIndex: '16' }}
                        label="Rate (during recoupment)"
                        onChange={handleInputChange}
                        name="rateDuringRec"
                        type="text"
                        fullWidth
                        InputProps={{ readOnly: isDisabled }}
                        value={rhDataModel.mainDetailsInptValues.rateDuringRec || ''}
                    />
                </Grid>

                {/* Post Term Field */}
                <Grid size={6}>
                    <TextFieldWrapper
                        inputProps={{ tabIndex: '18' }}
                        label="Post Term"
                        onChange={handleInputChange}
                        name="postTerm"
                        type="text"
                        fullWidth
                        InputProps={{ readOnly: isDisabled }}
                        value={rhDataModel.mainDetailsInptValues.postTerm || ''}
                    />
                </Grid>

                {/* Currency Field */}
                <Grid size={6}>
                    <CommonDropDownWithInput
                        menuItemsList={currencyMenuItems}
                        labelName="Currency"
                        preSelectedValue={rhDataModel.mainDetailsInptValues.currency}
                        isEditing={isDisabled}
                        isDisabled={isDisabled}
                        fullWidth
                        inputProps={{ tabIndex: '4' }}
                        onValueChangeCb={handleCurrencyValueChange}
                    />
                </Grid>
            </Grid>

            <div
                className="flex-horizontal"
                style={{
                    width: '100%',
                    justifyContent: 'space-between',
                    marginBottom: '13.5px',
                }}
            >
                <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
                    Deals
                </Typography>
                <Button variant="outlined" onClick={handleAddDeal}>
                    <AddIcon style={{ marginRight: '10px' }} />
                    ADD DEAL
                </Button>
            </div>

            <TerritoriesModal
                entityType="client"
                showTerritoryModal={showTerritoryModal}
                setShowTerritoryModal={setShowTerritoryModal}
                territories={territories}
                setTerritories={setTerritories}
                groupedTerritories={territoriesModel.groupedTerritories}
                clientId={props.match.params.id}
                isEditing={false}
            />
            <ClientDealsTable />
        </>
    );
};

const withUrlClientDetailsContent = withRouter(ClientDetailsContent);
export default connect((r) => r)(withUrlClientDetailsContent);
