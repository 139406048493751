import React, { useState, useEffect } from 'react';

import { changeStateByNestedKey } from '@actions/fugaActions';
import TextFieldWrapper from '@common/MuiWrappers/TextFieldWrapper';
import { FormControl } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { format, parseISO } from 'date-fns';
import { connect, useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';

const PerformerBioContent = (props) => {
    const dispatch = useDispatch();
    const performersModel = useSelector((state) => state.fugaReducers.performers);
    const { byClietnId } = performersModel;
    const [dateOfBirth, handleDateOfBirthChange] = useState(null);
    const [isDisabled, setIsDisabled] = useState(true);

    useEffect(() => {
        if (performersModel.isInEditMode || performersModel.isInSaveNewMode) {
            setIsDisabled(false);
        } else {
            setIsDisabled(true);
        }
    }, [performersModel.isInEditMode, performersModel.isInSaveNewMode]);

    useEffect(() => {
        if (props.match.params.id === '-1') {
            setIsDisabled(false);
        }
    }, []);

    useEffect(() => {
        if (byClietnId.dateOfBirth) {
            const date = parseISO(byClietnId.dateOfBirth);
            handleDateOfBirthChange(format(date, 'yyyy-MM-dd'));
        }
        dispatch(
            changeStateByNestedKey('performers', 'mainDetailsInptValues', {
                ...performersModel.mainDetailsInptValues,
                dateOfBirth:
                    performersModel.isInSaveNewMode && performersModel.selectedIndex < 0
                        ? null
                        : dateOfBirth,
                placeOfBirth: byClietnId.placeOfBirth,
                nationality: byClietnId.nationality,
                cor: byClietnId.countriesOfResidence,
                cob: byClietnId.countryOfBirth,
            }),
        );
    }, [performersModel.byClietnId]);

    useEffect(() => {
        dispatch(
            changeStateByNestedKey('performers', 'mainDetailsInptValues', {
                ...performersModel.mainDetailsInptValues,
                dateOfBirth: dateOfBirth,
            }),
        );
    }, [dateOfBirth]);

    const handleDateChange = (date) => {
        handleDateOfBirthChange(date ? format(date, 'yyyy-MM-dd') : null);
    };

    const handleInputChange = (event) => {
        dispatch(
            changeStateByNestedKey('performers', 'mainDetailsInptValues', {
                ...performersModel.mainDetailsInptValues,
                [event.target.name]: event.currentTarget.value,
            }),
        );
    };

    return (
        <>
            <div className="topContainer flex-left">
                <Grid columnSpacing={4} rowSpacing={1} container>
                    <Grid size={6}>
                        <DatePicker
                            label="Date of birth"
                            value={dateOfBirth ? parseISO(dateOfBirth) : null}
                            onChange={handleDateChange}
                            format="dd/MM/yyyy"
                            sx={{ width: '100%' }}
                            disabled={isDisabled}
                            renderInput={(params) => (
                                <TextFieldWrapper
                                    {...params}
                                    fullWidth
                                    inputProps={{ tabIndex: '11' }}
                                />
                            )}
                        />
                    </Grid>
                    <Grid size={6}>
                        <TextFieldWrapper
                            inputProps={{ tabIndex: '12' }}
                            label="Nationality"
                            onChange={handleInputChange}
                            name="nationality"
                            fullWidth
                            type="text"
                            InputProps={{
                                readOnly: isDisabled,
                            }}
                            value={performersModel.mainDetailsInptValues.nationality || ''}
                        />
                    </Grid>
                    <Grid size={6}>
                        <TextFieldWrapper
                            inputProps={{ tabIndex: '13' }}
                            label="Place of birth"
                            onChange={handleInputChange}
                            name="placeOfBirth"
                            type="text"
                            fullWidth
                            InputProps={{
                                readOnly: isDisabled,
                            }}
                            value={performersModel.mainDetailsInptValues.placeOfBirth || ''}
                        />
                    </Grid>
                    <Grid size={6}>
                        <TextFieldWrapper
                            inputProps={{ tabIndex: '14' }}
                            label="Country of birth"
                            onChange={handleInputChange}
                            name="cob"
                            type="text"
                            fullWidth
                            InputProps={{
                                readOnly: isDisabled,
                            }}
                            value={performersModel.mainDetailsInptValues.cob || ''}
                        />
                    </Grid>
                    <Grid size={6}>
                        <TextFieldWrapper
                            inputProps={{ tabIndex: '15' }}
                            label="Countries of residence"
                            onChange={handleInputChange}
                            name="cor"
                            type="text"
                            fullWidth
                            InputProps={{
                                readOnly: isDisabled,
                            }}
                            value={performersModel.mainDetailsInptValues.cor || ''}
                        />
                    </Grid>
                </Grid>
            </div>
        </>
    );
};

const withUrlPerformerBioContent = withRouter(PerformerBioContent);
export default connect((r) => r)(withUrlPerformerBioContent);
