import React, { useState } from 'react';

import { Button, Checkbox, CircularProgress, FormControlLabel } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Modal } from 'antd';
import classNames from 'classnames';

const useStyles = makeStyles((theme) => ({
    exportBtn: {
        marginLeft: '5px',
        position: 'relative',
    },
    title: { fontWeight: 'bold' },
    buttonProgress: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
        color: 'white',
    },
    exportBtnLoading: {
        height: '34px',
        color: theme.palette.grey.content,
    },
}));

export function ExportModal({ open, onCancel, onClick, title }) {
    const [checked, setIsChecked] = useState(false);
    const [loading, setLoading] = useState(false);

    const classes = useStyles();

    const handleClick = async () => {
        setLoading(true);
        await onClick(checked);
        setIsChecked(false);
        setLoading(false);
    };

    const handleClose = () => {
        onCancel();
        setIsChecked(false);
    };

    return (
        <Modal
            title={<span className={classes.title}>{title}</span>}
            open={open}
            okText="Yes"
            onCancel={handleClose}
            footer={[
                <Button variant="outlined" onClick={handleClose} disabled={loading}>
                    Cancel
                </Button>,
                <Button
                    variant="contained"
                    color="primary"
                    className={classNames(classes.exportBtn, {
                        [classes.exportBtnLoading]: loading,
                    })}
                    onClick={handleClick}
                    disabled={loading}
                >
                    {!loading && 'Export'}
                    {loading && <CircularProgress className={classes.buttonProgress} size={24} />}
                </Button>,
            ]}
        >
            Would you like to change the statuses of these assets to Exported?
            <FormControlLabel
                control={
                    <Checkbox
                        color="primary"
                        checked={checked}
                        onChange={() => setIsChecked(!checked)}
                        name="gilad"
                    />
                }
                label="Change status to exported"
            />
        </Modal>
    );
}
