import React, { useEffect, useState } from 'react';

import {
    createCommentByAssetsClaimId,
    getCommentByAssetClaimIdAPI,
} from '@api/assetsClaimEventController';
import { isSymbolsCountValid } from '@common/modals/common';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormHelperText,
    Tooltip,
    Typography,
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import TextFieldWrapper from '@MuiWrappers/TextFieldWrapper';
import { useSelector } from 'react-redux';

export function AssetsRegistrationCommentsModal({ isOpen, setOpen, pageModelName, getAssetData }) {
    const [commentInput, setCommentInput] = useState('');
    const [commentSymCount, setCommentSymCount] = useState(0);
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const dataItem = useSelector((state) => state.fugaReducers[pageModelName].selectedRowDataItem);

    const handleTooltipClose = () => {
        setTooltipOpen(false);
    };

    const handleTooltipOpen = () => {
        setTooltipOpen(true);
    };

    const getComments = async () => {
        const comment = await getCommentByAssetClaimIdAPI(dataItem.assetClaimId);
        setCommentInput(comment?.data?.commentMsg || '');
    };

    useEffect(() => {
        getComments();
    }, []);

    const handleClose = () => {
        setOpen(false);
    };

    const handleSave = async () => {
        await createCommentByAssetsClaimId({
            commentMsg: commentInput,
            assetClaimId: dataItem.assetClaimId,
        });
        getAssetData();
        handleClose();
    };

    const handleInputChange = (e) => {
        const value = e.currentTarget.value;
        if (isSymbolsCountValid(value, handleTooltipOpen, handleTooltipClose, setCommentSymCount)) {
            setCommentInput(value);
        }
    };

    return (
        <Dialog onClose={handleClose} open={isOpen} maxWidth="sm" fullWidth>
            <DialogTitle sx={{ padding: '19px 16px' }}>
                <Typography variant="h6">Edit Comment</Typography>
            </DialogTitle>
            <DialogContent sx={{ padding: '10px 16px' }}>
                <Tooltip
                    title="Input text should be less than 5000 characters"
                    disableHoverListener
                    open={tooltipOpen}
                >
                    <TextFieldWrapper
                        label="Comment"
                        onChange={handleInputChange}
                        name="comment"
                        type="text"
                        multiline
                        maxRows={10}
                        value={commentInput || ''}
                        fullWidth
                        sx={{
                            marginBottom: '10px',
                        }}
                        onBlur={() => setTooltipOpen(false)}
                    />
                </Tooltip>
                <Grid
                    container
                    sx={{
                        justifyContent: 'flex-end',
                    }}
                >
                    <FormHelperText>{commentSymCount}/5000</FormHelperText>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} variant="outlined" color="primary" disableElevation>
                    Cancel
                </Button>
                <Button onClick={handleSave} variant="contained" color="primary" disableElevation>
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
}
