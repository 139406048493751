import React, { useEffect } from 'react';

import { loadAllCmos } from '@actions/fugaActions';
import { useDispatch, useSelector } from 'react-redux';

import CommonFiltersContainer from './CommonFiltersContainer';
import TableFiltersWithDropDownCheckboxes from './TableFiltersWithDropDownCheckboxes';

export default function StatusSummaryFilters({
    isPerformer,
    onTableReloadCb,
    onClearAllCb,
    customFilterFieldName,
}) {
    const filtersModel = useSelector((state) => state.fugaReducers.filters);
    const cmosModel = useSelector((state) => state.fugaReducers.cmos);

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(loadAllCmos(isPerformer));
    }, []);

    const chackboxItemsListCmo = cmosModel.dataFromBE.map((cmo) => ({
        title: `${cmo.cmoName} ${cmo.hometerritoryFullname}`,
        id: cmo.cmoId,
        name: cmo.cmoName,
    }));

    const chackboxItemsListStatuses = [
        {
            id: 1,
            title: 'To be registered',
            name: 'TO_BE_REGISTERED',
        },
        {
            id: 2,
            title: 'Submitted',
            name: 'SUBMITTED',
        },
        {
            id: 3,
            title: 'Not available',
            name: 'NOT_AVAILABLE',
        },
        {
            id: 4,
            title: 'Registered',
            name: 'REGISTERED',
        },
        {
            id: 5,
            title: 'Exclusive license',
            name: 'EXCLUSIVE_LICENSE_DEAL',
        },
    ];

    const chackboxItemsListAlerts = [
        {
            id: 1,
            title: 'Internal',
            name: 'INTERNAL',
        },
        {
            id: 2,
            title: 'Client',
            name: 'CLIENT',
        },
        {
            id: 3,
            title: 'Cmo',
            name: 'CMO',
        },
    ];

    const onTableReload = (additionalFilters) => {
        dispatch(onTableReloadCb(additionalFilters));
    };

    const onClearAll = () => {
        dispatch(onTableReloadCb(onClearAllCb));
    };

    const menuItems = [
        {
            label: 'CMO name',
            filterField: customFilterFieldName + 'CMO',
            value: filtersModel[customFilterFieldName + 'CMO']?.valuesToShow || [],
            chackboxItemsList: chackboxItemsListCmo,
            filterNameInRequest: 'cmo',
        },
        {
            label: 'Statuses',
            filterField: customFilterFieldName + 'Status', // same as field read for value below
            value: filtersModel[customFilterFieldName + 'Status']?.valuesToShow || [],
            chackboxItemsList: chackboxItemsListStatuses,
            filterNameInRequest: 'statuses',
        },
        {
            label: 'Alerts',
            filterField: customFilterFieldName + 'alerts', // same as field read for value below
            value: filtersModel[customFilterFieldName + 'alerts']?.valuesToShow || [],
            chackboxItemsList: chackboxItemsListAlerts,
            filterNameInRequest: 'alerts',
        },
    ];

    return (
        <CommonFiltersContainer
            innerFiltersComponent={
                <TableFiltersWithDropDownCheckboxes
                    tableReloadCb={onTableReload}
                    onClearAll={onClearAll}
                    menuItems={menuItems}
                    itemFieldName="name"
                />
            }
        />
    );
}
