import React, { useState } from 'react';

import { ArrowDropDown } from '@mui/icons-material';
import { Button as MuiButton, Menu, MenuItem, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useClasses = makeStyles({
    menuItem: {
        padding: '0px 16px',
        height: '48px',
    },
    centerIcon: {
        display: 'flex',
    },
});

export default function CommonDropDownButton({ btnTitle, menuItemsList, customStyles }) {
    const [anchorEl, setAnchorEl] = useState(null);
    const classes = useClasses();

    const handleOpen = ({ currentTarget }) => {
        setAnchorEl(currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const onItemClick = (item) => {
        handleClose();
        if (item.onClickClb) item.onClickClb();
    };

    return (
        <>
            <MuiButton
                variant={customStyles?.variant ? customStyles.variant : 'text'}
                onClick={(e) => handleOpen(e)}
                color={customStyles?.color ? customStyles.color : 'default'}
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    fontWeight: 700,
                }}
            >
                {btnTitle}
                <ArrowDropDown style={{ marginRight: '0' }} />
            </MuiButton>
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                getContentAnchorEl={null}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                transformOrigin={{ vertical: 'top', horizontal: 'left' }}
            >
                {menuItemsList &&
                    menuItemsList.map((item, ind) => (
                        <MenuItem
                            key={`${ind}-${item.title}`}
                            onClick={() => onItemClick(item)}
                            className={classes.menuItem}
                        >
                            {item.icon ? item.icon : <></>}
                            <Typography variant="body1">{item.title}</Typography>
                        </MenuItem>
                    ))}
            </Menu>
        </>
    );
}
