import {
    getAlertsByAssetClaimIdAPI,
    getAssetClaimByAssetClaimIdAPI,
    resolveAlertMsgByAlertIdAPI,
    updateAlertMessageAPI,
} from '@api/assetsClaimEventController';
import { changeBulkStatusForAPI } from '@api/assetsRegistrationBulkActions';
import { bulkUpdateAlerts } from '@utils/bulkUpdateAlerts';
import { toast } from 'react-toastify';

import { changeStateByNestedKey } from './fugaActions';

// GET

export function getAssetClaimByAssetClaimId(assetClaimId, storeName = 'performers') {
    return async function (dispatch) {
        const response = await getAssetClaimByAssetClaimIdAPI(assetClaimId);

        if (response?.data) {
            dispatch(
                changeStateByNestedKey(storeName, 'registrationHistory', response?.data || []),
            );
        }
    };
}

export function getAlertsByAssetClaimId(assetClaimId, pageModelName) {
    return async function (dispatch) {
        const response = await getAlertsByAssetClaimIdAPI(assetClaimId);

        if (response?.data) {
            dispatch(
                changeStateByNestedKey(
                    pageModelName,
                    'alertMsgs',
                    response?.data?.assetClaimAlerts || [],
                ),
            );
        }
    };
}

// POST

export async function changeBulkStatusFor(
    urlPath,
    bodyParams = {
        includeAssetIds: [],
        excludeAssetIds: [],
        searchString: '',
        isrc: [],
        status: '',
    },
    queryParams = {},
) {
    const { data, toastId } = await changeBulkStatusForAPI(urlPath, bodyParams, queryParams);

    if (data && bodyParams.updateStatuses) {
        const successMessage = bulkUpdateAlerts(data);

        toast.update(toastId, {
            render: successMessage,
            type: 'success',
            isLoading: false,
            autoClose: 5000,
        });
    }

    return data;
}

export const changeBulkStatusForRightsHolder = (bodyParams, queryParams) =>
    changeBulkStatusFor('rh', bodyParams, queryParams);

export const changeBulkStatusForPerformer = (bodyParams, queryParams) =>
    changeBulkStatusFor('performer', bodyParams, queryParams);

export function resolveAlertMsgByAlertId(alertId, assetClaimId, pageModelName) {
    return async function (dispatch) {
        const response = await resolveAlertMsgByAlertIdAPI(alertId);

        if (response.data) {
            dispatch(getAlertsByAssetClaimId(assetClaimId, pageModelName));
        }
    };
}

// PUT
export function updateAlertMessage(content, assetClaimId, pageModelName) {
    return async function (dispatch) {
        const response = await updateAlertMessageAPI(content);

        if (response?.data) {
            dispatch(getAlertsByAssetClaimId(assetClaimId, pageModelName));
        }
    };
}
