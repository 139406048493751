import React, { useState } from 'react';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
    Checkbox,
    FormControlLabel,
    IconButton,
    List,
    ListItem,
    ListItemText,
    Typography,
} from '@mui/material';
import Grid from '@mui/material/Grid2';

const RegionItem = ({
    territorySelection,
    territories,
    setTerritories,
    region,
    classes,
    isDisabled,
}) => {
    const [collapsed, setCollapsed] = useState(true);

    const countSelected = () => {
        if (territories.length === 0 && Object.keys(territories).some((key) => key.includes('0')))
            return 0;
        return territorySelection.filter((territory) => {
            return (territories[region.id] || []).includes(territory.id);
        }).length;
    };

    const handleUpdateTerritories = (territoryCode) => {
        if ((territories[region.id] || []).includes(territoryCode)) {
            setTerritories({
                ...territories,
                [region.id]: (territories[region.id] || []).filter(
                    (territory) => territory !== territoryCode,
                ),
            });
        } else {
            setTerritories({
                ...territories,
                [region.id]: (territories[region.id] || []).concat(territoryCode),
            });
        }
    };

    const selectAllTerritories = (event) => {
        if (event.target.checked) {
            const allForRegion = territorySelection.map((territory) => territory.id);
            setTerritories({ ...territories, [region.id]: allForRegion });
        } else {
            setTerritories({ ...territories, [region.id]: [] });
        }
    };

    return (
        <List key={region.id} component="div" disablePadding>
            <ListItem disableGutters className={classes.listItem}>
                <Checkbox
                    className={classes.checkboxes}
                    color="primary"
                    checked={territorySelection.every((territory) =>
                        (territories[region.id] || []).includes(territory.id),
                    )}
                    indeterminate={
                        territorySelection.some((territory) =>
                            (territories[region.id] || []).includes(territory.id),
                        ) && (territories[region.id] || []).length !== territorySelection.length
                    }
                    onClick={selectAllTerritories}
                    disabled={isDisabled}
                />
                <ListItemText
                    primary={
                        <Typography variant="subtitle2">
                            {`${region.name} (${territorySelection.length} items, ${countSelected()} selected)`}
                        </Typography>
                    }
                />
                <IconButton
                    onClick={() => setCollapsed(!collapsed)}
                    className={collapsed ? classes.expanded : classes.collapsed}
                    aria-expanded={!collapsed}
                    size="large"
                >
                    <ExpandMoreIcon />
                </IconButton>
            </ListItem>
            <Grid container>
                {!collapsed &&
                    territorySelection.map((territory) => (
                        <Grid key={territory.id} size={3}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={(territories[region.id] || []).includes(
                                            territory.id,
                                        )}
                                        onChange={() => handleUpdateTerritories(territory.id)}
                                        name={territory.name}
                                        color="primary"
                                        disabled={isDisabled}
                                    />
                                }
                                label={territory.name}
                            />
                        </Grid>
                    ))}
            </Grid>
        </List>
    );
};

export default RegionItem;
