import React, { useState, useEffect } from 'react';

import { Button, DialogTitle, Popover, ClickAwayListener, Box, Checkbox } from '@mui/material';
import helper from '@utils/helper';

export function AssetsCmoFilterMenuDialogWithCheckboxes({
    isOpen,
    closeCb,
    title,
    dataItem,
    onConfirm,
    anchorEl,
    itemsList = [],
    itemFieldName = 'id',
    filtersModel,
}) {
    const [open, setOpen] = useState(isOpen);
    const [selectedValues, setSelectedValues] = useState({ valuesToSend: [], valuesToShow: [] });
    const filtersFromModel = filtersModel[dataItem.filterField] || [];

    const getValuesToShow = (listOfSelected) => {
        return listOfSelected.map((selectedValue) => {
            const foundItem =
                itemsList.find((menuItem) => menuItem[itemFieldName] === selectedValue) || {};
            return foundItem.title;
        });
    };

    useEffect(() => {
        if (filtersFromModel?.valuesToSend?.length > 0) {
            const itemsListToShow = getValuesToShow(filtersFromModel.valuesToSend);
            setSelectedValues({
                valuesToSend: [...filtersFromModel.valuesToSend],
                valuesToShow: [...itemsListToShow],
            });
        } else {
            setSelectedValues({ valuesToSend: [], valuesToShow: [] });
        }
    }, [isOpen]);

    useEffect(() => {
        if (filtersModel[dataItem.filterField] === '') {
            setSelectedValues({ valuesToSend: [], valuesToShow: [] });
        }
    }, [filtersModel[dataItem.filterField]]);

    useEffect(() => {
        setOpen(isOpen);
    }, [isOpen]);

    const handleClose = () => {
        setOpen(false);
        closeCb(false);
    };

    const handleConfirm = () => {
        if (onConfirm) {
            onConfirm(selectedValues);
        }
        handleClose();
    };

    const handleCheckboxClick = (clickedItem) => {
        const selectedId = selectedValues.valuesToSend.findIndex(
            (item) => item === clickedItem[itemFieldName],
        );
        const updatedSelectedList = helper.updateList(
            selectedValues.valuesToSend,
            clickedItem[itemFieldName],
            selectedId,
        );
        const updatedItemsToShow = getValuesToShow(updatedSelectedList);
        setSelectedValues({
            valuesToSend: updatedSelectedList,
            valuesToShow: [...updatedItemsToShow],
        });
    };

    const isCheckboxSelected = (currentSelecedValue) => {
        return selectedValues.valuesToSend.includes(currentSelecedValue);
    };

    return (
        <Popover
            open={open}
            anchorEl={anchorEl}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
            sx={{
                maxHeight: '550px', // replaces checkboxesContainer class
            }}
        >
            <ClickAwayListener onClickAway={handleClose}>
                <Box
                    sx={{
                        padding: '8px 0 8px 5px',
                        width: '335px',
                        minHeight: '147px',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        position: 'relative',
                    }}
                >
                    {title && (
                        <DialogTitle
                            onClose={handleClose}
                            sx={{
                                padding: '19px 16px', // replaces titile class
                            }}
                        >
                            {title}
                        </DialogTitle>
                    )}
                    <Box
                        sx={{
                            overflow: 'auto', // replaces itemsListContainer class
                        }}
                    >
                        {itemsList.map((item) => (
                            <Box
                                key={item.id}
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center', // replaces checkboxRow class
                                }}
                            >
                                <Checkbox
                                    color="primary"
                                    disableRipple
                                    onClick={() => handleCheckboxClick(item)}
                                    checked={isCheckboxSelected(item[itemFieldName])}
                                />{' '}
                                {item.title}
                            </Box>
                        ))}
                    </Box>
                    <Box
                        sx={{
                            marginTop: '13px',
                            display: 'flex',
                            justifyContent: 'flex-end',
                            background: '#FFF',
                            width: '94%',
                            marginBottom: '8px', // replaces actoinBtns class
                            '& > *': {
                                marginLeft: '24px',
                            },
                        }}
                    >
                        <Button
                            onClick={handleClose}
                            variant="text"
                            color="primary"
                            disableElevation
                        >
                            cancel
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            disableElevation
                            onClick={handleConfirm}
                            disabled={selectedValues.valuesToSend.length <= 0}
                            sx={{ marginRight: '8px' }}
                        >
                            apply
                        </Button>
                    </Box>
                </Box>
            </ClickAwayListener>
        </Popover>
    );
}
