import React, { useState } from 'react';

import { changeStateByNestedKey } from '@actions/fugaActions';
import { getAllReportsAPI } from '@api/assetImportController';
import { items } from '@data/constants';
import LogoutSvg from '@images/svgComponents/LogoutSvg';
import { NotificationsNoneOutlined, AccountCircle } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { makeStyles } from '@mui/styles';
import cerberusUtils from '@utils/cerberusUtils';
import utils from '@utils/utils';
import { useDispatch, useSelector } from 'react-redux';

import CommonIconDropDownButton from './CommonIconDropDownButton';
import Notification from './modals/NotificationsModal';
const { deleteCerberusToken, deleteClientId, deleteUserRole } = cerberusUtils;

const useStyles = makeStyles({
    panelIcon: {
        width: '24px',
        height: '24px',
        color: '#FFFFFF',
    },
    iconBtn: {
        padding: '0',
        margin: '0 20px 0 0',
    },
    itemIcon: {
        height: '24px',
        width: '24px',
        marginRight: '16px',
    },
});

export default function HeaderIconsPanel() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [isNotificationMdlOpen, setIsNotificationMdlOpen] = useState(false);
    const reportsModel = useSelector((state) => state.fugaReducers.reports);

    const getAllReports = async () => {
        const resp = await getAllReportsAPI();

        dispatch(changeStateByNestedKey('reports', 'dataFromBE', resp.data));
        setIsNotificationMdlOpen(true);
    };

    const handleNotificationClick = () => {
        getAllReports();
    };

    const NotificationIconBtn = () => {
        return (
            <IconButton
                className={`${classes.iconBtn}`}
                onClick={handleNotificationClick}
                size="large"
            >
                <NotificationsNoneOutlined className={`${classes.panelIcon}`} />
            </IconButton>
        );
    };

    const doLogout = () => {
        deleteUserRole();
        deleteCerberusToken();
        deleteClientId();
        window.location.assign('/login');
    };

    const menuItemsList = [
        {
            title: 'Log out',
            onClickClb: doLogout,
            icon: <LogoutSvg styles={classes.itemIcon} />,
        },
    ];
    return (
        <div style={{ marginLeft: 'auto' }}>
            {utils.checkItemRolesAndPermissions(items.btns.notificationsHerader) && (
                <NotificationIconBtn />
            )}
            <IconButton className={`${classes.iconBtn}`} size="large">
                <CommonIconDropDownButton
                    iconProp={<AccountCircle className={`${classes.panelIcon}`} />}
                    menuItemsList={menuItemsList}
                />
            </IconButton>
            <Notification
                onCloseProp={setIsNotificationMdlOpen}
                open={isNotificationMdlOpen}
                contentData={reportsModel.dataFromBE}
            />
        </div>
    );
}
