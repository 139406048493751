import React from 'react';

import { makeStyles } from '@mui/styles';

export const STATUS_SUMMARY_LIST = {
    EXCLUSIVE_LICENSE_DEAL: {
        backgroundColor: '#00A542',
        color: '#FFFFFF',
        title: 'Exclusive license',
    },
    REGISTERED: { backgroundColor: '#00A542', color: '#FFFFFF', title: 'Registered' },
    SUBMITTED: { backgroundColor: '#3D8FC9', color: '#FFFFFF', title: 'Submitted' },
    NOT_AVAILABLE: {
        backgroundColor: 'rgba(0, 0, 0, 0.38)',
        color: '#FFFFFF',
        title: 'Not available',
    },
    TO_BE_REGISTERED: { backgroundColor: '#FCC326', color: '#000000DE', title: 'To be registered' },
    EXCLUSIVE_LICENSE_DEAL_REGISTERED: { backgroundColor: '#00A542', color: '#FFFFFF', title: '' },
};

export const SUMMARY_BAR_ORDER = [
    'REGISTERED',
    'EXCLUSIVE_LICENSE_DEAL',
    'EXCLUSIVE_LICENSE_DEAL_REGISTERED',
    'SUBMITTED',
    'TO_BE_REGISTERED',
    'NOT_AVAILABLE',
];

const useStyles = makeStyles(() => ({
    chartElement: {
        borderRadius: '2px',
        height: '12px',
        minWidth: '8px',
    },
    container: {
        display: 'grid',
        '& > *:not(last-child)': {
            marginRight: '1px',
        },
        width: '130px',
    },
}));

export default function RegStatusBarChart({ dataItem, onHoverActions }) {
    const classes = useStyles();
    const statusesList = dataItem?.statusSummary || [];
    const hasElandReg = statusesList.some(
        (el) => el.registrationStatusName === 'EXCLUSIVE_LICENSE_DEAL_REGISTERED',
    );
    const gridItems = hasElandReg
        ? statusesList.filter(
              (el) =>
                  hasElandReg &&
                  el?.registrationStatusName !== 'REGISTERED' &&
                  el?.registrationStatusName !== 'EXCLUSIVE_LICENSE_DEAL',
          )
        : statusesList;

    const handleHoverIn = (e) => {
        if (onHoverActions?.onMouseEnterCb) onHoverActions.onMouseEnterCb(e);
    };

    const handleHoverOut = (e) => {
        if (onHoverActions?.onMouseLeaveCb) onHoverActions.onMouseLeaveCb(e);
    };

    return (
        <div
            className={classes.container}
            style={{
                gridTemplateColumns: gridItems.map((el) => el.percentage).join('fr ') + 'fr',
            }}
            onMouseEnter={(e) => handleHoverIn(e)}
            onMouseLeave={(e) => handleHoverOut(e)}
        >
            {gridItems.map((el, ind) => {
                const currentColor =
                    STATUS_SUMMARY_LIST[el?.registrationStatusName]?.backgroundColor;
                return (
                    <div
                        key={`${el.percentage}-${ind}`}
                        className={classes.chartElement}
                        style={{
                            backgroundColor: currentColor,
                            ...(el.registrationStatusName !== 'NOT_AVAILABLE' && {
                                border: `1px solid ${currentColor}`,
                            }),
                        }}
                    ></div>
                );
            })}
            {!dataItem?.statusSummary && (
                <div
                    className={classes.chartElement}
                    style={{ border: '0.5px solid #DEDEE0' }}
                ></div>
            )}
        </div>
    );
}
