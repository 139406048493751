import { getCmoIssuedIdsByClientIdAPI } from '@api/clientController';

import { changeStateByNestedKey } from './fugaActions';

function getCmoIssuedIdsByClientId(clientId) {
    return async function (dispatch) {
        const resp = await getCmoIssuedIdsByClientIdAPI(clientId);

        dispatch(changeStateByNestedKey('performers', 'cmoIssuedIds', resp.data || []));
    };
}

export { getCmoIssuedIdsByClientId };
