import React, { useCallback, useState } from 'react';

import { deleteProductByIdAPI } from '@api/metadataController';
import { NewProductModal } from '@common/AssetMetaDataEdit/NewProductModal';
import CommonConfirmationDialog from '@common/CommonConfirmationDialog';
import CommonTableGrid from '@common/CommonTable/CommonTableGrid';
import OverflowTip from '@common/OverflowTip';
import { DeleteOutline, EditOutlined } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import { Typography, Button } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
    actionBtn: {
        '&:hover': {
            cursor: 'pointer',
        },
        width: '18px',
        height: '18px',
        color: '#5F5F60',
    },
    inputItemContainer: {
        display: 'flex',
        width: '100%',
        marginBottom: '16px',
    },
    firsInputInRow: {
        marginRight: '32px',
    },
    textField: {
        '& .MuiFormLabel-root': {
            fontSize: '12px',
            fontWeight: '400',
            letterSpacing: '0.4px',
        },
    },
    sectionTitle: {
        marginBottom: '16px',
    },
    featuredLineUpContainer: {
        marginLeft: '32px',
    },
}));

const headers = [
    {
        title: 'Product title - version / artist',
        dataIndex: ['productTitle', 'displayArtist'],
    },
    {
        title: 'UPC',
        dataIndex: 'upc',
    },
    {
        title: 'Record label',
        dataIndex: 'recordLabel',
    },
    {
        title: 'Release date',
        dataIndex: 'releaseDate',
    },
    {
        title: '',
        dataIndex: '',
    },
];

export default function AssetMetaDataProductsSection({
    dataItem,
    productsDataFromBE,
    tableReloadOnSuccess,
}) {
    const classes = useStyles();
    const [showProductModal, setShowProductModal] = useState(false);
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [editMode, setEditMode] = useState(null);

    const openModal = () => {
        setShowProductModal(true);
    };

    const closeModal = () => {
        setShowProductModal(false);
        setEditMode(false);
    };

    const handleDeleteProduct = async () => {
        await deleteProductByIdAPI(selectedProduct.productId);
        await tableReloadOnSuccess();
    };

    const getSpecificData = useCallback((headerItem, dataItem) => {
        const columnTitle = headerItem.title.toLowerCase();
        if (columnTitle.includes('title')) {
            return (
                <>
                    <Typography variant="body2">
                        <OverflowTip>{dataItem[headerItem.dataIndex[0]]}</OverflowTip>
                    </Typography>
                    <Typography variant="caption">
                        <OverflowTip>{dataItem[headerItem.dataIndex[1]]}</OverflowTip>
                    </Typography>
                </>
            );
        } else if (columnTitle === '') {
            const handleEditClick = () => {
                setSelectedProduct(dataItem);
                setEditMode(true);
                setShowProductModal(true);
            };

            const handleDeleteClick = () => {
                setSelectedProduct(dataItem);
                setIsDeleteDialogOpen(true);
            };

            return (
                <div style={{ display: 'flex' }}>
                    <div onClick={handleEditClick}>
                        <EditOutlined
                            className={classes.actionBtn}
                            style={{ marginRight: '7px' }}
                        />
                    </div>
                    <div onClick={handleDeleteClick}>
                        <DeleteOutline className={classes.actionBtn} />
                    </div>
                </div>
            );
        } else {
            return <OverflowTip>{dataItem[headerItem.dataIndex] || ''}</OverflowTip>;
        }
    });

    return (
        <>
            <Typography variant="h6" className={classes.sectionTitle}>
                Products
            </Typography>
            <div className={classes.inputItemContainer}>
                {/* {contributionSaveResult.isSuccessful && (
                <Alert
                    style={{ marginBottom: '16px' }}
                    variant="outlined"
                    severity={contributionSaveResult.hasWarning ? 'warning' : 'success'}
                >
                    {`${
                        contributionSaveResult?.isDeleted
                            ? 'Contributor successfully deleted.'
                            : 'Contributor added and saved to the asset.'
                    }${
                        contributionSaveResult.hasWarning
                            ? ' This asset needs to be re-registered with one or more CMOs'
                            : ''
                    }`}
                </Alert>
            )} */}
            </div>
            <CommonTableGrid
                dataList={productsDataFromBE}
                headerList={headers}
                preferences={{
                    loadDataByPageCb: {
                        // no need to pass params here
                    },
                }}
                selection={{
                    isSelectable: false,
                }}
                cellDataCb={getSpecificData}
                isInSubModal={true}
                rowsPerPageOff={true}
                tablePaginationOff={true}
                footerAddition={{
                    itemComponent: (
                        <Button
                            variant="outlined"
                            color="primary"
                            disableElevation
                            disableRipple
                            onClick={openModal}
                        >
                            <AddIcon style={{ margin: '0 10px 0 0' }} />
                            Add Product
                        </Button>
                    ),
                }}
            />
            {showProductModal && (
                <NewProductModal
                    handleClose={closeModal}
                    showModal={showProductModal}
                    dataItem={dataItem}
                    editMode={editMode}
                    selectedProduct={selectedProduct}
                />
            )}
            {isDeleteDialogOpen && (
                <CommonConfirmationDialog
                    isOpen={isDeleteDialogOpen}
                    closeCb={setIsDeleteDialogOpen}
                    title="Confirm delete contributor"
                    description={`Would you like to delete '${selectedProduct?.productTitle || ''}' ?`}
                    onConfirmCb={handleDeleteProduct}
                    customConfirmText="Delete"
                />
            )}
        </>
    );
}
