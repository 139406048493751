import React, { useState } from 'react';

import { Typography, Box, ButtonGroup, Button, Link } from '@mui/material';
import Grid from '@mui/material/Grid2';

import PerformerBillingContent from './PerformerBillingContent';
import PerformerBioContent from './PerformerBioContent';
import PerformerCommentsContent from './PerformerCommentsContent';
import PerformerContactsAndUsersContent from './PerformerContactsAndUsersContent';
import PerformerDealTermsContent from './PerformerDealTermsContent';
import PerformerDetailsContent from './PerformerDetailsContent';
import PerformerIdentifiersContent from './PerformerIdentifiersContent';

const panes = {
    general: {
        label: 'General',
        id: 'general-client-info',
    },
    identifiers: {
        label: 'Identifiers',
        id: 'identifiers-client-info',
    },
    bio: {
        label: 'Bio',
        id: 'bio-client-info',
    },
    contactsAndUsers: {
        label: 'Contacts & Users',
        id: 'contacts-and-users',
    },
    dealTerms: {
        label: 'Deal Terms',
        id: 'deal_terms-client-info',
    },
    contacts: {
        label: 'Contacts',
        id: 'contacts-client-info',
    },
    billing: {
        label: 'Billing',
        id: 'billing-client-info',
    },
    comments: {
        label: 'Comments',
        id: 'comments-client-info',
    },
};

function PerformerMainDetailsNavigation() {
    const [active, setActive] = useState('General');

    const handleTabBtnClick = (tabName) => {
        setActive(tabName.label);
    };

    return (
        <Box xs={{ flexGrow: 1 }}>
            <Grid spacing={4} container>
                <Grid
                    size={{
                        xs: 2,
                        lg: 3,
                        xl: 2,
                    }}
                >
                    <ButtonGroup orientation="vertical" sx={{ width: '100%', paddingTop: '32px' }}>
                        {Object.keys(panes).map((tabName) => (
                            <Button
                                variant="text"
                                key={panes[tabName].id}
                                className="tabBtn"
                                onClick={() => handleTabBtnClick(panes[tabName])}
                                sx={[
                                    {
                                        textTransform: 'unset',
                                        textAlign: 'left',
                                        width: '100%',
                                    },
                                    active === panes[tabName].label
                                        ? {
                                              backgroundColor: '#DEDEE0',
                                          }
                                        : {
                                              backgroundColor: 'transparent',
                                          },
                                    active === panes[tabName].label
                                        ? {
                                              '&:hover': {
                                                  backgroundColor: '#DEDEE0',
                                              },
                                          }
                                        : {
                                              '&:hover': {
                                                  backgroundColor: 'rgba(0, 0, 0, 0.04)',
                                              },
                                          },
                                ]}
                            >
                                <Link
                                    underline="none"
                                    href={`#${panes[tabName].id}`}
                                    sx={{ width: '100%' }}
                                >
                                    <Typography variant="body1">{panes[tabName].label}</Typography>
                                </Link>
                            </Button>
                        ))}
                    </ButtonGroup>
                </Grid>
                <Grid
                    size={{
                        xs: 10,
                        lg: 9,
                        xl: 10,
                    }}
                >
                    <div style={{ padding: '32px 0 0' }}>
                        <General />
                        <Identifiers />
                        <Bio />
                        <ContactsAndUsers />
                        <DealTerms />
                        <Contacts />
                        <Billing />
                        <Comments />
                    </div>
                </Grid>
            </Grid>
        </Box>
    );
}

function General() {
    return (
        <div
            id={panes.general.id}
            style={{
                marginBottom: '32px',
            }}
        >
            <Typography variant="h6" style={{ fontWeight: 'bold', margin: '0 0 24px 0' }}>
                General
            </Typography>
            <PerformerDetailsContent />
        </div>
    );
}

function Identifiers() {
    return (
        <div
            id={panes.identifiers.id}
            style={{
                marginBottom: '32px',
            }}
        >
            <Typography variant="h6" style={{ fontWeight: 'bold', margin: '0 0 24px 0' }}>
                Identifiers
            </Typography>
            <PerformerIdentifiersContent />
        </div>
    );
}

function Bio() {
    return (
        <div
            id={panes.bio.id}
            style={{
                marginBottom: '32px',
            }}
        >
            <Typography variant="h6" style={{ fontWeight: 'bold', margin: '0 0 24px 0' }}>
                Bio
            </Typography>
            <PerformerBioContent />
        </div>
    );
}

function ContactsAndUsers() {
    return (
        <div
            id={panes.contactsAndUsers.id}
            style={{
                marginBottom: '32px',
            }}
        >
            <Typography variant="h6" style={{ fontWeight: 'bold', margin: '0 0 24px 0' }}>
                Contacts & Users
            </Typography>
            <PerformerContactsAndUsersContent />
        </div>
    );
}

function DealTerms() {
    return (
        <div
            id={panes.dealTerms.id}
            style={{
                marginBottom: '32px',
            }}
        >
            <Typography variant="h6" style={{ fontWeight: 'bold', margin: '0 0 24px 0' }}>
                Deal Terms
            </Typography>
            <PerformerDealTermsContent />
        </div>
    );
}

function Contacts() {
    return <div id={panes.contacts.id}></div>;
}

function Billing() {
    return (
        <div
            id={panes.billing.id}
            style={{
                marginBottom: '32px',
            }}
        >
            <Typography variant="h6" style={{ fontWeight: 'bold', margin: '0 0 24px 0' }}>
                Billing
            </Typography>
            <PerformerBillingContent />
        </div>
    );
}

function Comments() {
    return (
        <div id={panes.comments.id}>
            <Typography variant="h6" style={{ fontWeight: 'bold', margin: '0 0 24px 0' }}>
                Comments
            </Typography>
            <PerformerCommentsContent />
        </div>
    );
}

export default PerformerMainDetailsNavigation;
