import React, { useState } from 'react';

import { ArrowDropDown } from '@mui/icons-material';
import { Menu, MenuItem, Chip, Alert, styled } from '@mui/material';

// Define chip styles directly using the styled API for MUI v6
const ChipBtn = styled(Chip)(({ theme }) => ({
    height: '26px',
    borderRadius: '4px',
    borderWidth: '1px',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '10px',
    lineHeight: '14px',
    textAlign: 'center',
    letterSpacing: '1.5px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row-reverse',
    boxSizing: 'border-box',
    '&.Mui-disabled': {
        backgroundColor: '#ececee',
    },
    '&:hover': {
        backgroundColor: 'rgba(0,0,0,0.07) !important',
    },
    '&:active': {
        boxShadow: 'none',
        backgroundColor: 'rgba(0,0,0,0.12) !important',
    },
    '.MuiChip-icon': {
        width: '18px',
        height: '18px',
        margin: 0,
    },
    '.MuiChip-label': {
        paddingLeft: '7px',
        paddingRight: '4px',
    },
}));

export const ChipLabel = styled(Chip)(({ theme }) => ({
    height: '26px',
    borderRadius: '4px',
    borderWidth: '1px',
    padding: '4px 8px',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '10px',
    lineHeight: '14px',
    textAlign: 'center',
    letterSpacing: '1.5px',
    justifyContent: 'normal',
    '&.Mui-disabled': {
        backgroundColor: '#ececee',
    },
    '&:hover': {
        backgroundColor: 'rgba(0,0,0,0.07) !important',
    },
    '&:focus': {
        backgroundColor: 'rgba(0,0,0,0.12) !important',
    },
    '&:active': {
        boxShadow: 'none',
        backgroundColor: 'rgba(0,0,0,0.12)',
    },
}));

const RestrictedAlert = styled(Alert)(({ theme }) => ({
    maxWidth: '255px',
    backgroundColor: '#FFF',
    border: '1px solid #5F5F60',
    color: '#5F5F60',
    padding: '12px 16px',
    '& .MuiAlert-icon': {
        color: '#5F5F60',
        marginRight: '14px',
    },
    '& .MuiAlert-message': {
        width: '100%',
        maxWidth: '223px',
        textAlign: 'initial',
        padding: 0,
        fontWeight: 400,
        lineHeight: '18px',
        whiteSpace: 'normal',
        wordBreak: 'break-word',
        overflow: 'hidden',
    },
}));

const menuItemStyles = {
    padding: '8px 16px',
};

// Define themes for chips
export const chipThemes = {
    redChip: {
        color: '#B7191F',
        border: '1px solid #EE9A9B',
        backgroundColor: '#FFEBEE',
    },
    greenChip: {
        color: '#006218',
        border: '1px solid #56CC82',
        backgroundColor: '#E3F6E9',
    },
    blueChip: {
        color: '#295E95',
        border: '1px solid #74C7ED',
        backgroundColor: '#E5F6FC',
    },
    orangeChip: {
        color: '#EA4B04',
        border: '1px solid #FFB44C',
        backgroundColor: '#FFF3E0',
    },
    greyChip: {
        color: 'rgba(0, 0, 0, 0.6)',
        border: '1px solid rgba(0, 0, 0, 0.54)',
        backgroundColor: 'rgba(0, 0, 0, 0.12)',
    },
    purpleChip: {
        color: '#371678',
        border: '1px solid #9270BA',
        backgroundColor: '#ECE6F3',
    },
};

// Define the different status lists
export const STATUSES_LIST = {
    EXCLUSIVE_LICENSE_DEAL: { color: chipThemes.greenChip },
    DATA_UPDATED: { color: chipThemes.orangeChip },
    REGISTERED: { color: chipThemes.greenChip },
    SUBMITTED: { color: chipThemes.blueChip },
    NOT_AVAILABLE: { color: chipThemes.greyChip },
    TO_BE_REGISTERED: { color: chipThemes.orangeChip },
};

export const ASSET_STATUSES_LIST = {
    EXCLUSIVE_LICENSE_DEAL: { color: chipThemes.greenChip },
    TO_BE_REGISTERED: { color: chipThemes.orangeChip },
    EXPORTED: { color: chipThemes.blueChip },
    REGISTERED: { color: chipThemes.greenChip },
    UNAVAILABLE: { color: chipThemes.greyChip },
    NOT_APPLICABLE: { color: chipThemes.greyChip },
    RELINQUISHED: { color: chipThemes.purpleChip },
    DATA_UPDATED: { color: chipThemes.orangeChip },
    SUBMITTED: { color: chipThemes.blueChip },
};

const FULL_STATUSES_LIST = { ...ASSET_STATUSES_LIST, ...STATUSES_LIST };

export default function CommonDropDownChips({ chipTitle, menuItemsList, customStyles, hasAlerts }) {
    const [anchorEl, setAnchorEl] = useState(null);

    const handleOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const onItemClick = (item) => {
        handleClose();
        if (item.onClickClb) item.onClickClb(...item.CbArgs);
    };

    const getChipBtnStyle = () => FULL_STATUSES_LIST[chipTitle.split(' ').join('_')]?.color || {};

    const generateContentFromMenuItemsList = () =>
        menuItemsList &&
        menuItemsList.map((item, ind) => (
            <MenuItem sx={menuItemStyles} key={`${ind}-${item.title}`}>
                <ChipLabel
                    onClick={() => onItemClick(item)}
                    label={item.title}
                    style={item.color}
                    disableRipple
                />
            </MenuItem>
        ));

    return (
        <>
            <ChipBtn
                variant={customStyles?.variant || 'text'}
                onClick={handleOpen}
                color="primary"
                label={chipTitle}
                icon={<ArrowDropDown />}
                style={getChipBtnStyle()}
                disableRipple
            />
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                transformOrigin={{ vertical: 'top', horizontal: 'left' }}
            >
                {hasAlerts ? (
                    <MenuItem sx={menuItemStyles}>
                        <RestrictedAlert>
                            To change the status, first resolve all alerts.
                        </RestrictedAlert>
                    </MenuItem>
                ) : (
                    generateContentFromMenuItemsList()
                )}
            </Menu>
        </>
    );
}
