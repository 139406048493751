import React from 'react';

import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

import PrivacyPolicyModalContent from './PrivacyPolicyModalContent';

const useStyles = makeStyles((theme) => ({
    title: {
        padding: '19px 16px',
    },
    content: {
        padding: '10px 16px',
    },
}));

export default function PrivacyPolicyModal({ isOpen, setOpen }) {
    const classes = useStyles();

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Dialog onClose={handleClose} open={isOpen} maxWidth="md">
            <DialogTitle onClose={handleClose} className={`${classes.title}`}>
                <Typography variant="h6">Privacy Policy</Typography>
            </DialogTitle>
            <DialogContent className={`${classes.content}`}>
                <PrivacyPolicyModalContent />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} variant="contained" color="primary" disableElevation>
                    CLOSE
                </Button>
            </DialogActions>
        </Dialog>
    );
}
