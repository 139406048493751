import React, { useEffect, useState } from 'react';

import { changeStateByKey, changeStateByNestedKey } from '@actions/fugaActions';
import { Tabs, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useDispatch, useSelector } from 'react-redux';

import FilterChip from './Filters/FilterChip';
import FilterMenuDialogWithCheckboxes from './Filters/FilterMenuDialogWithCheckboxes';

const useStyles = makeStyles(() => ({
    titile: {
        padding: '19px 16px',
    },
    content: {
        padding: '0 8px',
        width: '91%',
        height: 'auto',
    },
    flexHor: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
    },
    labels: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
    },
    clearAll: {
        '&:hover': {
            cursor: 'pointer',
        },
    },
    rootTabs: {
        '&.MuiTabs-root': {
            position: 'relative',
            width: '100%',
            justifyContent: 'space-between',
            alignItems: 'center',
        },
        '& > .MuiTabs-scroller.MuiTabs-scrollable': {
            position: 'absolute',
            left: 0,
            right: 0,
        },
        '& .MuiTabScrollButton-root': {
            opacity: 'unset',
        },
        '& .MuiTabs-scrollButtons': {
            position: 'absolute',
            width: '32px',
            height: '32px',
            background: '#ffffff',
            borderRadius: '50%',
            boxShadow: '0px 3px 5px -1px',
        },
        '& .MuiTabs-scrollButtons:first-child': {
            left: '8px',
            zIndex: '1',
        },
        '& .MuiTabs-scrollButtons:nth-child(4)': {
            right: '8px',
        },
    },
}));

function TableFiltersWithDropDownCheckboxes({
    tableReloadCb,
    onClearAll,
    menuItems,
    itemFieldName,
}) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [value, setValue] = useState(0);
    const [isFilterMenuOpen, setIsFilterMenuOpen] = useState(false);
    const [anchor, setAnchor] = useState(true);
    const [dataItemForMenu, setDataItemForMenu] = useState({});
    const filtersModel = useSelector((state) => state.fugaReducers.filters);

    useEffect(() => {
        dispatch(changeStateByKey('filters', {}));
    }, []);

    const getDataReadytoBeSent = (fieldNameToExclude = '') => {
        return menuItems.reduce((filtersObj, filterItem) => {
            return {
                ...filtersObj,
                ...(filterItem.value.length > 0 &&
                    filterItem.filterNameInRequest !== fieldNameToExclude && {
                        [filterItem.filterNameInRequest]:
                            filtersModel[filterItem.filterField].valuesToSend,
                    }),
            };
        }, {});
    };

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleFilterClick = (event, dataItem) => {
        setAnchor(event.currentTarget);
        setDataItemForMenu(dataItem);
        setIsFilterMenuOpen(true);
    };

    const handleFilterCancel = (dataItem) => {
        setDataItemForMenu({}); // this line is optional
        const localFiltersModel = getDataReadytoBeSent(dataItem.filterNameInRequest);
        delete localFiltersModel[dataItem.filterField];
        if (tableReloadCb) tableReloadCb(localFiltersModel);
        dispatch(changeStateByNestedKey('filters', dataItem.filterField, {}));
        setIsFilterMenuOpen(false);
    };

    const onFilterConfirm = (value) => {
        dispatch(changeStateByNestedKey('filters', dataItemForMenu.filterField, value));
        if (tableReloadCb) {
            const filtersToSend = getDataReadytoBeSent();
            const currentFieldFilterName = menuItems.find(
                (menuItem) => menuItem.filterField === dataItemForMenu.filterField,
            ).filterNameInRequest;
            filtersToSend[currentFieldFilterName] = value.valuesToSend;
            tableReloadCb(filtersToSend);
        }
    };

    const handleClearAll = () => {
        const filtersModelCopy = { ...filtersModel };
        for (let key of Object.keys(filtersModel)) {
            filtersModelCopy[key] = '';
        }
        dispatch(changeStateByKey('filters', filtersModelCopy));
        if (onClearAll) onClearAll();
    };

    // NOTE: the data format should be followed in order to set it in redux store correctly
    return (
        <div className={`${classes.content} ${classes.flexHor}`}>
            <Tabs
                className={`${classes.rootTabs}`}
                value={value}
                onChange={handleChange}
                variant="scrollable"
                TabIndicatorProps={{
                    style: {
                        display: 'none',
                    },
                }}
            >
                {menuItems.map((filterItem) => {
                    return (
                        <FilterChip
                            key={`filter-item-${filterItem.filterField}`}
                            onClick={(e) => handleFilterClick(e, filterItem)}
                            dataItem={filterItem}
                            handleFilterCancelCb={() => handleFilterCancel(filterItem)}
                        />
                    );
                })}
                <Typography
                    color="primary"
                    variant="body2"
                    className={`${classes.labels} ${classes.clearAll}`}
                    onClick={handleClearAll}
                >
                    Clear all
                </Typography>
            </Tabs>
            <FilterMenuDialogWithCheckboxes
                isOpen={isFilterMenuOpen}
                closeCb={setIsFilterMenuOpen}
                anchorEl={anchor}
                dataItem={dataItemForMenu}
                onConfirmCb={onFilterConfirm}
                itemsList={dataItemForMenu.chackboxItemsList}
                itemFieldName={itemFieldName}
                filtersModel={filtersModel}
            />
        </div>
    );
}

export default TableFiltersWithDropDownCheckboxes;
