import React, { useEffect, useState } from 'react';

import { Box, Tabs, Tab } from '@mui/material';
import { makeStyles } from '@mui/styles';
import AssetsCmoPage from '@pages/AssetsCmoPage/AssetsCmoPage';
import { withRouter } from 'react-router-dom';

import CmoPageTitle from './CmoPageTitle';

const useStyles = makeStyles((theme) => ({
    divideElement: {
        margin: '0',
        padding: '0',
        border: '0',
        borderTop: '1px solid #e0e0e0',
    },
    tabsBox: {
        position: 'relative',
    },
    tabContainer: {
        width: '100%',
        marginTop: '24px',
    },
}));

const panes = {
    mainDetails: {
        label: 'MAIN DETAILS',
        id: 0,
        type: 'MAIN_DETAILS',
        isDisabled: true,
    },
    clientRegistrations: {
        label: 'CLIENT REGISTRATIONS',
        id: 1,
        type: 'CLIENT_REGISTRATIONS',
        isDisabled: true,
    },
    assetRegistrations: {
        label: 'ASSET REGISTRATIONS',
        id: 2,
        type: 'ASSET_REGISTRATIONS',
        isDisabled: false,
    },
};

const CmoTabs = (propsCmo) => {
    const [value, setValue] = useState(0);
    const classes = useStyles();

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    useEffect(() => {
        // Will be used when tabs "MAIN_DETAILS" and "CLIENT REGISTRATIONS" are implemented and not disables
        // const defaultSelected = Object.values(panes).find(
        //     (elem) => elem.type === propsCmo.match.params.type
        // );
        const defaultSelected = 2;
        setValue(defaultSelected);
    }, []);

    function TabPanel(props) {
        const { children, value, index, ...other } = props;
        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
                className={classes.tabContainer}
            >
                {value === index && <div>{children}</div>}
            </div>
        );
    }

    return (
        <div>
            <Box xs={{ borderBottom: 1, width: '100%' }} className={classes.tabsBox}>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    indicatorColor="primary"
                    variant="scrollable"
                >
                    {Object.keys(panes).map((tabName) => {
                        return (
                            <Tab
                                key={panes[tabName].id}
                                label={panes[tabName].label}
                                disabled={panes[tabName].isDisabled}
                            />
                        );
                    })}
                </Tabs>
                <hr className={classes.divideElement} />
            </Box>
            <TabPanel value={value} index={panes['mainDetails'].id}>
                {propsCmo.mainDetails}
            </TabPanel>
            <TabPanel value={value} index={panes['clientRegistrations'].id}>
                {propsCmo.clientRegistrations}
            </TabPanel>
            <TabPanel value={value} index={panes['assetRegistrations'].id}>
                {propsCmo.assetRegistrations}
            </TabPanel>
        </div>
    );
};

function CmoPage(props) {
    return (
        <div className="mainComponent">
            <div>
                <CmoPageTitle cmoType={props.match.params.type} />
                <CmoTabs
                    match={props.match}
                    mainDetails={<></>}
                    clientRegistrations={<></>}
                    assetRegistrations={<AssetsCmoPage cmoType={props.match.params.type} />}
                />
            </div>
        </div>
    );
}

export default withRouter(CmoPage);
