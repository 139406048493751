import { getAllRightsHoldersAPI } from '@api/rightsHolderClientController';
import { SUMMARY_BAR_ORDER } from '@common/RegStatusBarChart';

import { changeStateByNestedKey } from './fugaActions';

function getAllRightsHolders(queryParams = {}) {
    return async function (dispatch) {
        dispatch(changeStateByNestedKey('clients', 'tableIsLoading', true));

        const resp = await getAllRightsHoldersAPI(queryParams);

        const listWithSortedSummaries = (resp.data.content || []).map((el) => {
            const sortedData = { ...el };
            if (el.statusSummary) {
                sortedData.statusSummary.sort(
                    (firstItem, secondItem) =>
                        SUMMARY_BAR_ORDER.indexOf(firstItem.registrationStatusName) -
                        SUMMARY_BAR_ORDER.indexOf(secondItem.registrationStatusName),
                );
            }
            return sortedData;
        });
        dispatch(
            changeStateByNestedKey('clients', 'dataFromBE', {
                ...resp.data,
                content: listWithSortedSummaries,
            }),
        );
        dispatch(changeStateByNestedKey('clients', 'tableIsLoading', false));
    };
}

export { getAllRightsHolders };
