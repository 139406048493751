import React, { useState, useEffect, memo } from 'react';

import { Close as CancelIcon } from '@mui/icons-material';
import SearchIcon from '@mui/icons-material/Search';
import { TextField, Tooltip, FormControl, IconButton, InputAdornment } from '@mui/material';

let CommonSearchInput = function ({ onSearch, term, placeHolderText, onClearCb }) {
    const [value, setValue] = useState('');
    const [initialSearchTerm, setInitialSearchTerm] = useState('');
    const [isTooltipOpen, setIsTooltipOpen] = useState(false);

    const handleTooltipOpen = () => {
        if (term) {
            setIsTooltipOpen(true);
        }
    };

    const handleTooltipClose = () => {
        setIsTooltipOpen(false);
    };

    const onClearSearch = () => {
        if (!value) return;
        setValue('');
        if (onClearCb) onClearCb();
    };

    const showClearIcon =
        (value && value.length > 0) || (initialSearchTerm && initialSearchTerm.length > 0);

    useEffect(() => {
        const _term = term ? term : '';
        setInitialSearchTerm(_term);
        setValue(_term);
    }, [term]);

    const onClientSearch = () => {
        onSearch(value);
    };

    const handleChange = (e) => setValue(e.target.value);

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            onClientSearch(value);
        }
    };

    return (
        <FormControl key="form-search" sx={{ margin: 0, height: '35px', minHeight: '35px' }}>
            <Tooltip
                title={value}
                open={isTooltipOpen}
                onOpen={handleTooltipOpen}
                onClose={handleTooltipClose}
            >
                <TextField
                    value={value}
                    variant="outlined"
                    placeholder={placeHolderText}
                    disabled={!!term}
                    onChange={handleChange}
                    onKeyDown={handleKeyPress}
                    sx={{
                        marginRight: '10px',
                        height: '34px',
                        '& input': {
                            padding: '6px 8px',
                        },
                        '& .MuiOutlinedInput-root': {
                            '&.Mui-focused fieldset': {
                                borderColor: 'grey.400', // Update based on theme or exact color
                            },
                        },
                    }}
                    slotProps={{
                        input: {
                            endAdornment: (
                                <InputAdornment position="end">
                                    {showClearIcon && (
                                        <IconButton
                                            onClick={onClearSearch}
                                            aria-label="clear search"
                                            sx={{
                                                padding: 0,
                                                margin: 0,
                                                '&:hover': {
                                                    background: 'none',
                                                },
                                            }}
                                            size="large"
                                        >
                                            <CancelIcon
                                                sx={{
                                                    color: '#5F5F60',
                                                    width: '17.48px',
                                                    height: '17.48px',
                                                    margin: 0,
                                                }}
                                            />
                                        </IconButton>
                                    )}
                                </InputAdornment>
                            ),
                            startAdornment: (
                                <InputAdornment position="start" sx={{ margin: 0 }}>
                                    <IconButton
                                        disableRipple
                                        aria-label="search"
                                        sx={{
                                            padding: 0,
                                            margin: 0,
                                            '&:hover': {
                                                background: 'none',
                                            },
                                        }}
                                        size="large"
                                    >
                                        <SearchIcon
                                            sx={{ color: '#5F5F60', width: '21px', height: '21px' }}
                                        />
                                    </IconButton>
                                </InputAdornment>
                            ),
                        },
                    }}
                />
            </Tooltip>
        </FormControl>
    );
};

CommonSearchInput = memo(CommonSearchInput);

export default CommonSearchInput;
