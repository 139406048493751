import React from 'react';

import { getRouteFromUserRole } from '@actions/authActions';
import { items } from '@data/constants';
import logo from '@images/branding/DT_logo.png';
import { AppBar, Typography, Toolbar, Button, styled } from '@mui/material';
import Grid from '@mui/material/Grid2';
import utils from '@utils/utils';
import { Link } from 'react-router-dom';

import ClientsHeaderBtnDropDownButton from './ClientsHeaderBtnDropDownButton';
import HeaderIconsPanel from './HeaderIconsPanel';

const AppLogoName = styled(Typography)(({ theme }) => ({
    color: theme.palette.text.white,
}));

const LogoParent = styled(Grid)({
    paddingLeft: '4px',
    paddingRight: '10px',
});

const Logo = styled('img')({
    width: 'auto',
    height: '36px',
});

const AppBarStyled = styled(AppBar)({
    width: '100%',
    height: '48px',
    left: 0,
    top: 0,
});

const ButtonStyled = styled(Button)(({ theme }) => ({
    fontWeight: 700,
    display: 'flex',
    alignItems: 'center',
    height: '100%',
}));

function Header() {
    return (
        <AppBarStyled position="relative">
            <Toolbar variant="dense" disableGutters>
                <Link to={getRouteFromUserRole()}>
                    <LogoParent container alignItems="center">
                        <Grid item xs={3}>
                            <Logo src={logo} />
                        </Grid>
                        <Grid
                            item
                            container
                            xs={9}
                            direction="column"
                            sx={{
                                alignItems: 'flex-start',
                            }}
                        >
                            <Grid item>
                                <AppLogoName
                                    variant="subtitle2"
                                    sx={{ textTransform: 'uppercase' }}
                                    noWrap
                                >
                                    Neighbouring Rights
                                </AppLogoName>
                            </Grid>
                            <Grid item>
                                <AppLogoName variant="subtitle2" sx={{ fontSize: 10 }}>
                                    Powered by FUGA
                                </AppLogoName>
                            </Grid>
                        </Grid>
                    </LogoParent>
                </Link>
                <Grid
                    container
                    sx={{
                        alignItems: 'center',
                    }}
                >
                    {utils.checkItemRolesAndPermissions(items.btns.cmoListHeader) && (
                        <Link to="/cmo-list">
                            <ButtonStyled variant="text">CMOs</ButtonStyled>
                        </Link>
                    )}
                    {utils.checkItemRolesAndPermissions(items.btns.clientsListHeader) && (
                        <ClientsHeaderBtnDropDownButton />
                    )}
                    {utils.checkItemRolesAndPermissions(items.btns.userMgmgtHeader) && (
                        <Link to="/users">
                            <ButtonStyled variant="text">User Management</ButtonStyled>
                        </Link>
                    )}
                </Grid>
                <HeaderIconsPanel />
            </Toolbar>
        </AppBarStyled>
    );
}

export default Header;
